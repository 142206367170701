import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    Stack,
    TabPanel,
    Tab,
    Button,
    ButtonStack,
    PageTitle,
    PageContent,
    Auth
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'
import UserDetailsTab from './components/UserDetailsTab'
import UserEmailHistoryTab from './components/UserEmailHistoryTab'
import SuspendUserDrawer from './components/SuspendUserDrawer'

const UserView = (props) => {
    const [user, setUser] = useState(null)
    const [dataError, setDataError] = useState(false)
    const { getRequest } = useRequest()
    const [showSuspendDrawer, setShowSuspendDrawer] = useState(false)

    const getUser = (userId) => {
        return getRequest(`users/${userId}`)
            .then(setUser)
            .catch(() => setDataError(true))
    }

    useEffect(() => {
        if (user === null) {
            getUser(props.match.params.userId)
        }
    }, [])

    return (
        <LoadingScreen
            hasData={user}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title={`${user.firstName} ${user.lastName}`} description={user.email} status={user.status} slim>
                        <Auth permission="701">
                            <ButtonStack row>
                                {!!user.isSuspended && <Button variant="secondary" onClick={() => setShowSuspendDrawer(true)}>Unsuspend User</Button>}
                                {!user.isSuspended && <Button variant="destructive" onClick={() => setShowSuspendDrawer(true)}>Suspend User</Button>}
                            </ButtonStack>
                        </Auth>
                    </PageTitle>
                    <PageContent slim>
                        <Stack row responsive>
                            <TabPanel flex="1">
                                {<Tab icon="calendar" component={<UserDetailsTab user={user} props={props} />}>Details</Tab>}
                                {<Tab icon="calendar" component={<UserEmailHistoryTab userId={user.id} props={props} />}>Email History</Tab>}
                            </TabPanel>
                        </Stack>
                    </PageContent>
                    <SuspendUserDrawer userId={user.id} onClose={() => { setShowSuspendDrawer(false); getUser(props.match.params.userId) }} isOpen={showSuspendDrawer} suspended={!user.isSuspended} />
                </Fragment>
            )}
        />
    )
}

export default UserView