import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    ButtonStack,
    formatDateTime,
    PseudoElement,
    Sub,
    P,
    H4,
    Flex,
    Stack,
    Field,
    IconButton,
    StateIndicator,
    Avatar,
    Badge
} from 'paid-ui-lib'

const ContractInfoModal = ({selectedContract, onClose}) => {
    const getSignerStatusIcon = (s, index) => {
        let state = 0;
        if(s.signedOn) state = 1;
        else if(s.declinedOn) state = 2

        return (
            <StateIndicator key={index} state={state} size={32}>
                <Avatar name={s.name} variant={s.isSupplier ? "org" : "user"} />
            </StateIndicator>
        )
    }

    const getSignerStatus = (s, contract) => {
        if(s.signedOn) return `Signed Off`
        else if(s.declinedOn) return `Declined`
        else if(contract.status === 3) return "No Longer Required"
        else return "Awaiting"
    }

    const getSignerStatusDate = (s) => {
        if(s.signedOn) return <Sub lightText>{formatDateTime(s.signedOn)}</Sub>
        else if(s.declinedOn) return <Sub lightText>{formatDateTime(s.declinedOn)}</Sub>
        else return null
    }

    return (
        <Modal isOpen={!!selectedContract} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Contract Information</ModalHeader>
                {
                    selectedContract && (
                    <ModalBody>
                        <Stack spacing={16}>
                            <Field label="Contract Name" value={selectedContract.name}></Field>
                            <Field label="Created On" value={formatDateTime(selectedContract.created)}></Field>
                            <Field label="Last Actioned On" value={formatDateTime(selectedContract.updated)}></Field>
                            <Field label="Status">
                                <PseudoElement>
                                    {selectedContract.status === 1 && <Badge status="info">Unsigned</Badge>}
                                    {selectedContract.status === 2 && <Badge status="success">Signed</Badge>}
                                    {selectedContract.status === 3 && <Badge status="error">Declined</Badge>}
                                    {selectedContract.status === 4 && <Badge status="info">Voided</Badge>}
                                </PseudoElement>
                            </Field>
                            {
                                selectedContract.status === 3 &&
                                <Field label="Decline Reason">
                                    <Stack>
                                        {selectedContract.signers.filter(s => !!s.declineReason).map((s, index) =>
                                            <P key={index}>"{s.declineReason}" - {s.name}</P>
                                        )}                                    
                                    </Stack>
                                </Field>
                            }
                            <Field label="Signers">
                                <Stack spacing={2}>
                                    {selectedContract.signers.map((s, index) =>
                                        <Flex alignItems="center" key={index}>
                                            {getSignerStatusIcon(s, index)}
                                            <Flex flexDirection="column" ml={16}>
                                                <H4 maxWidth={["10rem", "18rem","18rem"]} overflow="hidden" textOverflow="ellipsis">{s.name}</H4>
                                                <Sub lightText>{getSignerStatus(s, selectedContract)}</Sub>
                                                {getSignerStatusDate(s)}
                                            </Flex>
                                            {
                                                s.signingLink &&
                                                <IconButton ml="auto" as="a" href={s.signingLink} title="View as signer" target="_blank" variant="secondary" icon="edit-alt-2" isSmall/>
                                            }
                                        </Flex>                                        
                                    )}
                                </Stack>
                            </Field>
                        </Stack>
                    </ModalBody>
                    )
                }
                <ModalFooter>
                    <ButtonStack row>
                        <Button variant="secondary" onClick={onClose}>
                            Close
                        </Button>
                        {
                            selectedContract &&
                            <Button as="a" href={selectedContract.signingLink} target="_blank">
                                View Contract
                            </Button>
                        }                        
                    </ButtonStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ContractInfoModal