import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import { useHistory } from 'react-router-dom'
import {
    HeaderSection,
    PageContent,
    PageTitle,
    useToast,
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import AddAuthenticator from './components/AddAuthenticator'
import RemoveAuthenticator from './components/RemoveAuthenticator'

const AuthenticatorSetup = () => {
    const toast = useToast()
    const history = useHistory()
    const { getRequest } = useRequest()
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const [dataError, setDataError] = useState(null)

    const getUser = (   ) => {
        return getRequest(`auth/account`)
            .then(res => {
                setUser(res)
            })
            .catch(() => {
                setDataError(true)
            })
    }

    const onClose = () => {
        history.push("/account")
    }

    useEffect(() => {
        if (user === null) {
            getUser()
            getRequest(`auth/authenticator/code`)
                .then((res) => setData(res))
                .catch(() => {
                    toast({
                        slim: true,
                        position: "top-right",
                        title: "Failed",
                        description: `Failed to retrieve Two Factor Authentication data. Please contact support`,
                        status: "error",
                    })
                })
        }
    }, [])

    return (
        <LoadingScreen
            hasData={user}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle
                        title="Account"
                        description={`${user.firstName} ${user.lastName}`}
                        status={user.status}
                        icon="user"
                        slim />
                    <PageContent slim>
                            {
                                !!user.authenticatorEnabled
                                    ? <RemoveAuthenticator user={user} data={data} onComplete={onClose} />
                                    : <AddAuthenticator user={user} data={data} onComplete={onClose} />
                            }
                    </PageContent>
                </Fragment>
            )} />
    )
}

export default AuthenticatorSetup