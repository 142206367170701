import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import ListAdministratorsView from './routes/list'
import DetailsView from './routes/view'

const AdministratorsRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/administrators" component={ListAdministratorsView} /> }
            { <Route exact path="/administrators/:administratorId" component={DetailsView} /> }

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default AdministratorsRoutes