import React, { useEffect, useState } from 'react'
import {
    Stack,
    useRequest,
    useToast,
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'
import Overview from './components/Overview'
import TransferPaymentOptions from './components/TransferPaymentOptions'

const PaymentsTab = ({ enterpriseId, work, ...rest }) => {

    const toast = useToast();
    const { getRequest, putRequest } = useRequest();
    const [changePaymentMethod, setChangePaymentMethod] = useState(true);
    const [currentPaymentDetails, setCurrentPaymentDetails] = useState(null);

    const refreshPaymentDetails = () => {
        return getRequest(`work/${work.id}/enterprises/${enterpriseId}/payment-method`)
            .then(setCurrentPaymentDetails)
            .catch(() => { })
    }

    const onTransferPaymentMethod = (newPaymentMethod) => {
        if (!newPaymentMethod) {
            toast({
                slim: true,
                position: "top-right",
                title: "Transfer Failed",
                description: "Failed to transfer payment method. No transfer data specified. Please contact support.",
                status: "error",
                isClosable: true
            });

            return new Promise((_, reject) => reject())
        }

        if (!!newPaymentMethod.feeFunded && !newPaymentMethod.id) {
            toast({
                slim: true,
                position: "top-right",
                title: "Transfer Failed",
                description: "Failed to transfer payment method. Fee is funded but no finance provider specified. Please contact support.",
                status: "error",
                isClosable: true
            });

            return new Promise((_, reject) => reject())
        }

        const payload = {
            paymentMethod: newPaymentMethod.paymentMethod,
            financeOption: !!newPaymentMethod.expressPayment ? { financeOptionId: newPaymentMethod.id, funded: newPaymentMethod.funded } : null
        };

        return putRequest(`work/${work.id}/enterprises/${enterpriseId}/payment-transfer`, payload)
            .then(() => refreshPaymentDetails())
            .catch(() => { })
    }

    useEffect(() => {
        !!enterpriseId && !!work && !!work.id && refreshPaymentDetails();
    }, [enterpriseId, work])

    return (
        <LoadingScreen
            hasData={!!currentPaymentDetails}
            hasError={false}
            render={() => (
                <Stack>
                    <Overview
                        paymentDetails={currentPaymentDetails}
                        onChangePaymentMethod={!changePaymentMethod ? () => setChangePaymentMethod(true) : null}
                        {...rest}
                    />
                    {!!changePaymentMethod && !!currentPaymentDetails && !!currentPaymentDetails.allowTransfer && <TransferPaymentOptions
                        enterpriseId={enterpriseId}
                        work={work}
                        paymentDetails={currentPaymentDetails}
                        onTransfer={onTransferPaymentMethod}
                        onCancel={() => setChangePaymentMethod(false)}
                        {...rest}
                    />}
                </Stack>
            )} />
    )
}

export default PaymentsTab