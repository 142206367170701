import React, { Fragment, useState, useEffect } from 'react'
import { formatDateTime, formatDate } from 'helpers/date-helper'
import {
    Avatar,
    StateIndicator,
    ButtonStack,
    Stack,
    Span,
    IconButton,
    Tooltip,
    H4,
    Sub,
    Flex,
    Badge,
    useToast,
    Table,
    HeaderSection,
    Spinner,
    Auth,
    List,
    LI,
    Tab,
    TabPanel,
    P
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"
//import { useConfigManager } from 'components/ConfigManager'
import { useRequest } from 'components/use-request'
import ConfirmModal from 'components/ConfirmModal'
import ContractInfoModal from './components/ContractInfoModal'
import SurveyInfoModal from 'components/SurveyInfoModal'
import SurveyTableSection from 'components/SurveyTableSection'

const surveyHeadings = [
    { "header": "Name", "accessor": "name", maxWidth: "35%", minWidth: "35%" },
    { "header": "Owner", "accessor": "owner", maxWidth: "20%", minWidth: "20%" },
    { "header": "Status", "accessor": "status", minWidth: "25%" },
    { "header": "", "accessor": "actions", maxWidth: "20%" }
]

const surveySections = {
    "buyer": { title: "Buyer to complete", buttonLabel: "Buyer", value: 1 },
    "supplier": { title: "Supplier to complete", buttonLabel: "Supplier", value: 2 },
    "thirdParty": { title: "Third Party Vendor to complete", buttonLabel: "Third Party", value: 3 },
}

const PageSize = 10

const DocumentsTab = ({work, ...rest}) => {
    const toast = useToast()
    // const { config } = useConfigManager()
    //const { postRequest, deleteRequest } = useRequest(config)
    const [selectedInfoContract, setSelectedInfoContract] = useState(null)
    const [selectedInfoSurvey, setSelectedInfoSurvey] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(null)
    const [resendContractId, setResendContract] = useState(null)
    const [surveySectionsData, setSurveySectionsData] = useState({})
    const [surveysLoaded, setSurveysLoaded] = useState(false)
    const [dataError, setDataError] = useState(null)

    const hasSurveys = Object.values(surveySectionsData).some(v => !!v.items && v.items.length > 0)

    const attachmentHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "", "accessor": "url" }
    ]

    const getInitialQuery = () => {
        const query = {
            categories: []
        }

        for (const surveyTarget in surveySections) {
            query.categories.push({
                surveyCategory: surveyTarget,
                page: 1,
                PageSize
            })
        }

        return query
    }

    // const getSurveyData = async (query) => {
    //     postRequest(`surveys/work/${work.id}/categorised`, query)
    //         .then(res => {
    //             const data = {...surveySectionsData}

    //             for (const surveyTarget in res.targets) {
    //                 data[surveyTarget] = res.targets[surveyTarget]
    //             }

    //             setSurveySectionsData(data)
    //             setSurveysLoaded(true)
    //         })
    //         .catch(() => {
    //             setDataError(true)
    //         })
    // }

    // const getWorkSurveys = (workId) => {
    //     getRequest(`surveys/work/${workId}`)
    //         .then(res => {
    //             setSurveys(res.items)
    //         })
    //         .catch(() => {
    //             setDataError(true)
    //         })
    // }
    
    // useEffect(() => {
    //     getSurveyData(getInitialQuery())
    //     // getWorkSurveys(work.id)
    // }, [work])

    const attachmentTransform = (data) => {
        return {
            id: data.id,
            url: (
                <ButtonStack compact row width="100%">
                    <IconButton title="Download Attachment" as="a" variant="secondary" isSmall target="_blank" href={data.url} icon="download" />
                </ButtonStack>
            ),
            name: data.name
        }
    }

    const agreementHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Version", "accessor": "version" },
        { "header": "", "accessor": "pdfLink" }
    ]

    const agreementTransform = (data) => {
        return {
            id: data.id,
            pdfLink: (
                <ButtonStack compact row width="100%">
                    <IconButton title="Download Attachment" as="a" variant="secondary" isSmall target="_blank" href={data.pdfLink || data.url} icon="download" />
                </ButtonStack>
            ),
            version: data.version,
            name: data.name
        }
    }

    const contractHeadings = [
        { "header": "Contract Type", "accessor": "type" },
        { "header": "Last Actioned", "accessor": "updated" },
        { "header": "Status", "accessor": "status" },
        { "header": "Sign Off", "accessor": "signers", "overflow": "visible" },
        { "header": "", "accessor": "actions" }
    ]

    const getSignerStatusIcon = (s, index) => {
        let state = 0;
        if(s.signedOn) state = 1;
        else if(s.declinedOn) state = 2

        return (
            <StateIndicator key={index} state={state} size="1.75rem">
                <Avatar name={s.name} variant={s.isSupplier ? "org" : "user"} />
            </StateIndicator>
        )
    }

    const getSignerStatus = (s, contract) => {
        if(s.signedOn) return `Signed Off`
        else if(s.declinedOn) return `Declined`
        else if(contract.status === 3) return "No Longer Required"
        else return "Awaiting"
    }

    const getSignerStatusDate = (s) => {
        if(s.signedOn) return <Sub lightText>{formatDateTime(s.signedOn)}</Sub>
        else if(s.declinedOn) return <Sub lightText>{formatDateTime(s.declinedOn)}</Sub>
        else return null
    }

    // const resendContract = () => {
    //     return postRequest(`work/${work.id}/contracts/${resendContractId}/reissue`, {})
    //     .then(() => {
    //         toast({
    //             slim: true,
    //             position: "top-right",
    //             title: "Contract Resent",
    //             description: "Contract has been resent to unsigned signers",
    //             status: "success",
    //             isClosable: true
    //         })
    //     })
    // }

    const closeResendContract = () => {
        setResendContract(null);
    }

    const contractTransform = (data) => {
        const showEvidence = !!work.requiredCompletionData.length && !!work.evidence[data.id]

        return {
            id: data.id,
            props: {
                3: { overflow: "inherit" }
            },
            type: <Span fontWeight="bold">{data.name}</Span>,
            updated: <Span>{formatDateTime(data.updated)}</Span>,
            status: (
                <Fragment>
                    {data.status === 1 && <Badge status="info">Unsigned</Badge>}
                    {data.status === 2 && <Badge status="success">Signed</Badge>}
                    {data.status === 3 && <Badge status="error">Declined</Badge>}
                    {data.status === 4 && <Badge status="info">Voided</Badge>}
                </Fragment>
            ),
            signers: (
                <Tooltip 
                    direction="bottom"
                    p={16}
                    content={() =>
                        <Stack spacing={16} minWidth={[undefined, "200px", "200px"]} maxWidth={[undefined, "250px", "250px"]}>
                            {data.signers.map((s, index) => 
                                <Flex alignItems="center" key={index}>
                                    {getSignerStatusIcon(s, index)}
                                    <Flex flexDirection="column" ml={16}>
                                        <H4 overflow="hidden" textOverflow="ellipsis" maxWidth="9.25rem">{s.name}</H4>
                                        <Sub lightText>{getSignerStatus(s, data)}</Sub>
                                        {getSignerStatusDate(s)}
                                    </Flex>
                                </Flex>
                            )}
                        </Stack>
                    }>
                    <Stack row spacing={8}>                        
                        { data.signers.map(getSignerStatusIcon) }
                    </Stack>
                </Tooltip>
            ),
            actions: (
                <Flex width="100%">
                    <ButtonStack compact ml="auto" row>
                        { data.status === 1 && <IconButton title="Resend Contract" variant="secondary" isSmall target="_blank" onClick={() => setSelectedInfoContract(data)} icon="mail" /> }
                        <IconButton title="Open Contract Info" variant="secondary" isSmall onClick={() => setSelectedInfoContract(data)} icon="search" />
                        <IconButton title="Open Contract" as="a" target="_blank" variant="secondary" isSmall href={showEvidence ? `work/${work.id}/contract/${data.id}` : data.signingLink} icon={data.status === 1 ? "edit-alt-2" : "external-link"} />
                        <IconButton title="Download Attachment" as="a" variant="secondary" isSmall target="_blank" href={data.pdfLink || data.url} icon="download" />
                    </ButtonStack>
                </Flex>
            )
        }
    }

    const supplierInvoiceHeadings = [
        { "header": "Reference", "accessor": "reference" },
        { "header": "Amount", "accessor": "amount" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Payment Scheduled", "accessor": "dueOn" },
        { "header": "Paid On", "accessor": "paidOn" },
        { "header": "Status", "accessor": "status" },
        { "header": "", "accessor": "pdfLink" }
    ]

    const getInvoiceStatus = (invoice) => {
        if(!!invoice.paidOn) return <Badge status="success">Paid</Badge>
        if(!invoice.pdfLink) return <Badge status="info">Generating Invoice</Badge>

        return !!invoice.dueOn 
            ? <Badge status="info">Payment Scheduled</Badge>
            : <Badge status="warning">Details Required</Badge>;
    }

    const supplierInvoiceTransform = (data) => {
        return {
            id: data.id,
            pdfLink:
            (
                <Flex width="100%">
                    <ButtonStack compact ml="auto" row>
                        {!!data.pdfLink && <IconButton title="Open Invoice" as="a" variant="secondary" isSmall target="_blank" href={`${data.pdfLink || data.url}&open=true`} icon="external-link" />}
                        {!!data.pdfLink && <IconButton title="Download Invoice" as="a" variant="secondary" isSmall target="_blank" href={data.pdfLink || data.url} icon="download" />}
                    </ButtonStack>
                </Flex>
            ),
            reference: data.reference,
            amount: `${work.currencySymbol || ""}${data.amount.toFixed(2)}`,
            createdOn: formatDate(data.createdOn),
            dueOn: formatDate(data.dueOn),
            paidOn: formatDate(data.paidOn),
            status: getInvoiceStatus(data),
        }
    }

    const invoiceHeadings = [
        { "header": "Reference", "accessor": "reference" },
        { "header": "Amount", "accessor": "amount" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Due On", "accessor": "dueOn" },
        { "header": "Status", "accessor": "status" },
        { "header": "", "accessor": "pdfLink" }
    ]

    const invoiceTransform = (data) => {
        return {
            id: data.id,
            pdfLink:
            (
                <Flex width="100%">
                    <ButtonStack compact ml="auto" row>
                        {!!data.pdfLink && <IconButton title="Open Invoice" as="a" variant="secondary" isSmall target="_blank" href={`${data.pdfLink || data.url}&open=true`} icon="external-link" />}
                        {!!data.pdfLink && <IconButton title="Download Invoice" as="a" variant="secondary" isSmall target="_blank" href={data.pdfLink || data.url} icon="download" />}
                    </ButtonStack>
                </Flex>
            ),
            amount: `${work.currencySymbol || ""}${data.amount.toFixed(2)}`,
            reference: data.reference,
            createdOn: formatDate(data.createdOn),
            dueOn: formatDate(data.dueOn),
            status: <Badge status="warning">Awaiting Payment</Badge>,
        }
    }

    const getTargetName = (target) => {
        switch(target) {
            case 1: return "Buyer"
            case 2: return "Supplier"
            default: return "None"
        }
    }

    const removeSurvey = () => {
        let surveyToken = showConfirmModal.token === null ? "" : showConfirmModal.token

        return deleteRequest(`suppliers/${work.supplier.id}/surveys/${showConfirmModal.id}/tokens/${surveyToken}`)
            .then(() => {
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Survey Removed",
                    description: "Survey successfully removed",
                    status: "success",
                    isClosable: true
                })
                getSurveyData(getInitialQuery())
            })
            .catch(() => { })
    }

    const closeConfirm = (agreed) => {
        setShowConfirmModal(false)
        if (agreed) {
            getSurveyData(getInitialQuery())
        }
    }

    const surveyTransform = (data) => {
        return {
            id: data.id,
            status: data.responded ? <Badge status="success">Complete</Badge> : <Badge status="warning">Awaiting Completion</Badge>,
            created: data.created,
            owner: getTargetName(data.target),
            name: data.name.length <= 30 ? data.name : (data.name.substring(0, 30) + "…"),
            actions: (
                <Flex width="100%">
                    <ButtonStack ml="auto" compact row spacing={8}>
                        <IconButton variant="secondary" onClick={() => setSelectedInfoSurvey(data)} isSmall icon="info-outline" />
                        <Auth permission="0">
                            <IconButton title="Delete Survey" isSmall onClick={() => setShowConfirmModal(data)} icon="trash-b"/>
                        </Auth>
                        {data.surveyLink && <IconButton title="Open Survey" as="a" variant="secondary" target="_blank" href={`${data.surveyLink}`} isSmall icon="edit" />}
                        {data.documentLink && <IconButton title="Open Survey Document" as="a" variant="secondary" target="_blank" href={`${data.documentLink}`} isSmall icon="external-link" />}
                    </ButtonStack>
                </Flex>
            )
        }
    }

    const renderTabContent = (categories) => (
        <Stack mt={24}>
            {categories
                .filter(c => categories.length === 1 || (c in surveySectionsData && surveySectionsData[c].items.length > 0))
                .map(c => (
                    <SurveyTableSection key={`survey-section-${c}`} headings={surveyHeadings} title={surveySections[c].title} data={surveySectionsData[c]} getData={(pageData) => {
                        getSurveyData({ categories: [{
                            ...pageData,
                            surveyCategory: surveySections[c].value
                        }] })
                    }} surveyTransform={surveyTransform} />
                ))}
        </Stack>
    )

    const renderTabs = () => {
        const tabs = [
            <Tab key={`survey-section-tab-all`} component={renderTabContent(Object.keys(surveySections))} activityCount={Object.values(surveySectionsData).reduce((sum, section) => sum + section.items.length, 0)}>All</Tab>
        ]

        for (const category of Object.keys(surveySections)) {
            const section = surveySections[category]
            tabs.push(<Tab key={`survey-section-tab-${section.value}`} component={renderTabContent([category])} activityCount={surveySectionsData[category].items.length}>{section.buttonLabel}</Tab>)
        }
        
        return tabs
    }

    return (
        <Fragment>
            <ContractInfoModal selectedContract={selectedInfoContract} onClose={() => setSelectedInfoContract(null)} />
            <ConfirmModal
                isOpen={!!resendContractId}
                buttonSpan="Renotify"
                message="Are you sure you want to renotify all pending signers?"
                //onConfirm={resendContract}
                onConfirm={() => {}}
                onClose={closeResendContract} />
            <ConfirmModal
                isOpen={!!showConfirmModal}
                title="Please Confirm"
                buttonText="Remove Survey"
                message={
                    <Stack>
                        <Sub>Are you sure you want to remove the following survey from this supplier?</Sub>
                        <List fontSize="sm">
                            <LI>{showConfirmModal && showConfirmModal.name}</LI>
                        </List>
                    </Stack>
                }
                submitVariant="destructive"
                onConfirm={removeSurvey}
                onClose={closeConfirm} />
            <Stack {...rest}>
                {
                    work.attachments.length > 0 &&
                    <HeaderSection title="Contract Attachments" headingMb={!!work.attachments.length ? 1 : null}>
                        <Table columns={attachmentHeadings} data={work.attachments} transform={attachmentTransform} noData={<NoDataScreen />}/>
                    </HeaderSection>
                }
                {
                    /*!!config.work.allowInternalAttachments &&*/ Array.isArray(work.internalAttachments) && work.internalAttachments.length > 0 &&
                    <HeaderSection title="Internal Attachments" headingMb={!!work.internalAttachments.length ? 1 : null}>
                        <Table columns={attachmentHeadings} data={work.internalAttachments} transform={attachmentTransform} noData={<NoDataScreen />}/>
                    </HeaderSection>
                }
                {
                    work.agreements.length > 0 &&
                    <HeaderSection title="Agreements" headingMb={!!work.agreements.length ? 1 : null}>
                        <Table columns={agreementHeadings} data={work.agreements} transform={agreementTransform} noData={<NoDataScreen />}/>
                    </HeaderSection>
                }
                {
                    (surveysLoaded && hasSurveys) &&
                    <Fragment>
                        <SurveyInfoModal selectedSurvey={selectedInfoSurvey} onClose={() => setSelectedInfoSurvey(null)} />
                        <HeaderSection 
                            title="Surveys"
                            headingMb={hasSurveys ? 1 : null}
                            {...rest}>
                            {/* {
                                (!!surveys && !dataError)
                                ? (
                                    surveys.length === 0
                                    ? <Span>No Surveys Found</Span>
                                    : <Table columns={surveyHeadings} data={surveys} transform={surveyTransform} noData={<NoDataScreen />}/>
                                )
                                : (
                                    <Spinner m="auto" size="lg" />
                                )
                            } */}
                            {
                                (!!surveysLoaded && !dataError)
                                    ? (
                                        !hasSurveys
                                            ? <P>No Surveys Found</P>
                                            : (
                                                <Stack>
                                                    <TabPanel tabStyle="button" selectedTab={0}>                                            
                                                        {renderTabs()}
                                                    </TabPanel>
                                                </Stack>
                                            )
                                    )
                                    : (
                                        <Spinner m="auto" size={32} />
                                    )
                            }
                        </HeaderSection>
                    </Fragment>
                }
                {
                    work.contracts.length > 0 &&
                    <HeaderSection title="Contracts" headingMb={!!work.contracts.length ? 1 : null}>
                        <Table columns={contractHeadings} data={work.contracts} transform={contractTransform} noData={<NoDataScreen />}/>
                    </HeaderSection>
                }
                {
                    (work.invoices.length > 0 || work.transactionInvoices.length > 0) &&
                    <HeaderSection title="Invoices" headingMb={!!work.invoices.length ? 1 : null}>
                        <Stack>
                            {
                                work.transactionInvoices.length > 0 &&
                                <Flex flexDirection="column">
                                    <H4 ml={24} mb={20}>Transaction invoices</H4>
                                    <Table mt={12} columns={invoiceHeadings} data={work.transactionInvoices} transform={invoiceTransform} noData={<NoDataScreen />}/>
                                </Flex>
                            }
                            {
                                work.invoices.length > 0 &&
                                <Flex flexDirection="column">
                                    <H4 ml={24} mb={20}>Supplier invoices</H4>
                                    <Table columns={supplierInvoiceHeadings} data={work.invoices} transform={supplierInvoiceTransform} noData={<NoDataScreen />}/>
                                </Flex>
                            }
                        </Stack>
                    </HeaderSection>
                }
            </Stack>
        </Fragment>
    )
}

export default DocumentsTab