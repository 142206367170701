import { I18NProvider } from 'paid-ui-lib'
import React, { useRef } from 'react'
import SplashScreen from 'components/SplashScreen'
import {
    useRequest,
} from 'paid-ui-lib'

const I18nProvider = ({ ...rest }) => {
    const { getRequest } = useRequest()
    const stateRef = useRef(null)

    const getSupportedCurrencies = async (state) => {
        const res = await getRequest("i18n/currencies")

        return {
            supportedCurrencies: res.supportedCurrencies,
            defaultCurrency: res.defaultCurrency,
            reportingCurrency: res.reportingCurrency
        }
    }

    const getSupportedCountries = async (state) => {
        const res = await getRequest("i18n/locales")
        const supportedCountries = res.supportedLocales

        return {
            supportedCountries,
            defaultCountry: res.defaultLocale,
            hasMultipleCountries: true
        }
    }

    const onGetInitialState = (state) => ({
        ...state,
        defaultCurrency: 826,
        reportingCurrency: 826,
        supportedCurrencies: [],
        supportedCountries: [],
        defaultCountry: 826
    })

    const onInit = async (state) => ({
        ...state,
        ...await getSupportedCurrencies(state),
        ...await getSupportedCountries(state)
    })

    return (
        <I18NProvider
            onInit={onInit}
            onGetInitialState={onGetInitialState}
            readOnlyStateRef={stateRef}
            loadingRender={() => <SplashScreen />}
            {...rest}
        />
    )
}

export default I18nProvider