import React, { Fragment, useEffect, useState } from 'react'
import {
    Box,
    ButtonStack,
    Button,
    Field,
    Flex,
    HeaderSection,
    Icon,
    IconButton,
    PseudoElement,
    Select,
    Span,
    Spinner,
    Stack,
    Strong,
    Tooltip,
    useI18N,
    formatDate,
    useRequest,
} from 'paid-ui-lib'

import ConfirmModal from 'components/ConfirmModal'
import NoDataScreen from "components/NoDataScreen"
import LoadingScreen from 'components/LoadingScreen'
import PaymentOptions from './components/PaymentOptions'

const TransferPaymentOptions = ({ enterpriseId, work, paymentDetails, onCancel, onTransfer, ...rest }) => {

    const { formatCurrency } = useI18N();
    const { postRequest } = useRequest();
    const [loading, setLoading] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState(null);
    const [transferPaymentOption, setTransferPaymentOption] = useState(null);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const [expressPaymentFunded, setExpressPaymentFunded] = useState(!!work && !!work.expressPaymentFunded ? '1' : '0');

    const headings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Description", "accessor": "description" },
        { "header": "Payment Date", "accessor": "paymentDate" },
        { "header": "Terms", "accessor": "paymentTerms" },
        { "header": "Total", "accessor": "total" },
        { "header": "", "accessor": "indicators" },
    ]

    const transform = (data) => {
        return {
            id: data.id,
            name: data.name,
            description: data.description,
            paymentDate: !!data.paymentDate ? formatDate(data.paymentDate) : "",
            paymentTerms: `${data.paymentTermDays} Days`,
            overflow: "visible",
            total: <PseudoElement>
                <Tooltip
                    cursor="pointer"
                    flex="1"
                    direction="right"
                    variant="small"
                    //position="absolute"
                    text={<Stack spacing={8} responsive>
                        <Strong>Payment Breakdown</Strong>
                        <Field row flex="1" label="Fee" value={`${formatCurrency(data.currencyCode, data.fee)}${!!data.feeFunded ? "(Fee Funded)" : ""}`} />
                        <Field row flex="1" label="Rate" value={data.feeRate} />
                        <Field row flex="1" label="Allow Carbon Offset" value={!!data.allowCarbonOffsetting ? "Yes" : "No"} />
                    </Stack>}>
                    <Stack flex="1" row spacing={4} alignItems="center">
                        <Span>{formatCurrency(data.currencyCode, data.total)}</Span>
                        <Icon name="info" />
                    </Stack>
                </Tooltip>
            </PseudoElement>,
            indicators: <Stack row spacing={4}>
                {
                    !!data.selected && <Tooltip
                        cursor="pointer"
                        flex="1"
                        direction="right"
                        variant="small"
                        position="absolute"
                        width="fit-content"
                        height="fit-content"
                        text="Current Payment Method">
                        <Stack row spacing={4}>
                            <Icon name="info" />
                        </Stack>
                    </Tooltip>
                }
                {
                    !!data.preferred && <Tooltip
                        cursor="pointer"
                        flex="1"
                        direction="right"
                        variant="small"
                        position="absolute"
                        width="fit-content"
                        height="fit-content"
                        text="Preferred Payment Method">
                        <Stack row spacing={4}>
                            <Icon name="info" />
                        </Stack>
                    </Tooltip>
                }
                <IconButton isSmall icon="check" onClick={() => setTransferPaymentOption(data)} />
            </Stack>
        }
    }

    useEffect(() => {
        !!enterpriseId && !!work && !!work.id && postRequest(`work/${work.id}/enterprises/${enterpriseId}/payment-options`, { funded: expressPaymentFunded == '1' })
            .then(p => setPaymentOptions((p || { paymentOptions: [] }).paymentOptions || []))
            .catch(() => { })
    }, [enterpriseId, work, paymentDetails, expressPaymentFunded])

    const onChangeExpressFunded = (funded) => {
        setPaymentOptions([])
        setSelectedPaymentOption(null);
        setExpressPaymentFunded(funded);
    }

    const transferPayment = (o) => {
        setLoading(true)
        !!onTransfer && onTransfer(o)
            .catch(() => { })
            .finally(() => setLoading(false));
        setTransferPaymentOption(null);
        setSelectedPaymentOption(null);
    }

    return (
        <LoadingScreen
            hasData={!!paymentOptions && !!paymentOptions.length}
            hasError={false}
            render={() => (
                <HeaderSection title="Change Payment Method" {...rest}>
                    <ConfirmModal
                        isOpen={!!transferPaymentOption}
                        buttonText="Transfer"
                        buttonVariant="primary"
                        message="You are about to transfer."
                        onConfirm={() => { transferPayment({ ...transferPaymentOption, funded: expressPaymentFunded == '1' }) }}
                        onClose={() => setTransferPaymentOption(null)} />
                    {/* {
                        !!transferPaymentOptions && transferPaymentOptions.length > 0
                            ? <Table columns={headings} data={transferPaymentOptions} transform={transform} noData={<NoDataScreen />} />
                            : <Box><P>Changing payment method is unavailable</P></Box>
                    } */}
                    <Box
                        isOpen
                        footer={
                            <ButtonStack row responsiveReverse>
                                {!!onCancel && <Button disabled={!!loading} variant="secondary" onClick={onCancel}>Cancel</Button>}
                                {!!onCancel && <Button isLoading={!!loading} disabled={!!loading || !selectedPaymentOption} onClick={() => transferPayment({ ...selectedPaymentOption, funded: expressPaymentFunded == '1' })}>Submit</Button>}
                            </ButtonStack>
                        }>
                        {
                            !!loading
                                ? <Flex height="100%" {...rest}>
                                    <Stack flex="1" alignItems="center" py={48}>
                                        <Spinner m="auto" size={32} />
                                        <Span>Updating Payment Method. Please wait...</Span>
                                    </Stack>
                                </Flex>
                                : <Fragment>
                                    <Stack>
                                        <Field flex={["1", "1", "0.3"]} isRequired label="Fee Funded">
                                            <Select flex="1" value={expressPaymentFunded} onChange={e => onChangeExpressFunded(e.target.value)}>
                                                {[{ id: '0', name: "No" }, { id: '1', name: "Yes" }].map(e => (<option value={e.id}>{e.name}</option>))}
                                            </Select>
                                        </Field>
                                        <PaymentOptions
                                            work={work}
                                            onSelect={setSelectedPaymentOption}
                                            enterpriseId={enterpriseId}
                                            enterpriseName={(!!work && !!work.legalEntity && !!work.legalEntity.name) ? work.legalEntity.name : null}
                                            selectedId={!!selectedPaymentOption ? selectedPaymentOption.id : undefined}
                                            paymentOptions={paymentOptions}
                                            label="Payment Terms"
                                            isRequired
                                        />
                                    </Stack>
                                </Fragment>
                        }
                    </Box>
                </HeaderSection>
            )} />
    )
}

export default TransferPaymentOptions