import { useToast } from 'paid-ui-lib';
import { 
    getRequest as getRequestFetch,
    postRequest as postRequestFetch,
    putRequest as putRequestFetch,
    deleteRequest as deleteRequestFetch
} from 'helpers/request-helper'

const useRequest = () => {
    const criticalError = { message: "Critical Error" }
    const toast = useToast();

    const showErrorToast = (message) => {
        toast({
            slim: true,
            position: "top-right",
            title: "Request Error",
            description: message,
            status: "error",
            isClosable: true
          })
    }

    const errorCheck = (response) => {
        if(response.ok) return response
        
        if(response.headers.get("content-length") === "0"){
            throw new Promise(resolve => {
                resolve(`${response.status} - ${response.statusText}`)
            })
        }

        throw response.json()
    }

    const handleRequestError = (errorPromise) => {
        return errorPromise.then((error) => {
            if(typeof(error) === "string"){
                showErrorToast(error)
                throw error
            }

            if(error && (error.detail || error.title)){
                const errorRes = { message: error.detail || error.title }
                showErrorToast(errorRes.message)
                throw errorRes
            }

            if(error && error.response) {           
                const responseData = error.response.data
                const errorRes = responseData.title 
                ? { message: responseData.title }                 
                : (responseData.error && responseData.error.message) || (responseData && { message: responseData })
                
                showErrorToast(errorRes.message || error.response.statusText)
                throw errorRes
            }
         
            showErrorToast(criticalError.message)
            throw criticalError
        })
    }

    const decodeResponse = (response) => {
        if(!response) return response;

        const contentType = response.headers.get("content-type")

        if(contentType){
            if(contentType.indexOf("application/json") !== -1) return response.json()
            if(contentType.indexOf("text/plain") !== -1) return response.text()
        }

        return response
    }

    const getRequest = (path, params) => {
        return getRequestFetch(path, params).then(errorCheck).catch(handleRequestError).then(decodeResponse)
    }

    const postRequest = (path, data, contentType) => {
        return postRequestFetch(path, data, contentType).then(errorCheck).catch(handleRequestError).then(decodeResponse)
    }

    const putRequest = (path, data, contentType) => {
        return putRequestFetch(path, data, contentType).then(errorCheck).catch(handleRequestError).then(decodeResponse)
    }

    const deleteRequest = (path) => {
        return deleteRequestFetch(path).then(errorCheck).catch(handleRequestError).then(decodeResponse)
    }

    return {
        getRequest: getRequest,
        postRequest: postRequest,
        putRequest: putRequest,
        deleteRequest: deleteRequest
    }
}

export { useRequest }