import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    MessageStack,
    Message,
    Stack,
} from 'paid-ui-lib'

const NoteViewerDrawer = ({notes, onClose, isOpen}) => {
    
    const getNotes = (notes) => {
        return !!notes ? notes.map(n => {
            return {
                createdOn: n.createdOn,
                createdBy: typeof(n.createdBy) === "string" ? n.createdBy : n.createdBy.name,
                text: n.text
            }
        }) : []
    }

    return (
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Notes</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <Stack>
                        <MessageStack mb={4}>
                            {
                                (getNotes(notes).map((m, i) => 
                                    <Message 
                                        key={`message-${i}-${m.createdOn}`} 
                                        name={m.createdBy}
                                        datetime={m.createdOn}
                                        viewedBy={m.viewedBy}
                                        messageType={m.messageType}
                                        displayAsOrganisation={m.displayAsOrganisation}>
                                        {m.text}
                                    </Message>))
                            }
                        </MessageStack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>  
    )
}

export default NoteViewerDrawer