import React, { Fragment, useEffect, useState } from 'react'
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Field,
    formatDate,
    H3,
    HR,
    Img,
    Span,
    Stack,
} from 'paid-ui-lib'

import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'

const ComplianceDocumentsDrawer = ({ organisationId, verificationId, isOpen, onClose }) => {
    const [dataError, setDataError] = useState(false)
    const [personalDetails, setPersonalDetails] = useState(null)
    const [addressDetails, setAddressDetails] = useState(null)
    const [documents, setDocuments] = useState(null)
    const { getRequest } = useRequest()

    async function downloadDocument(d) {
        const httpResponse = await getRequest(d.url);
        const blob = await httpResponse.blob();
        const blobObj = URL.createObjectURL(blob);
        return { ...d, data: blobObj };
    }

    async function downloadDocuments(docs) {
        const documentDownloads = (docs || []).filter(d => !!d.url).map(downloadDocument);
        const downloadedDocuments = await Promise.all(documentDownloads);
        setDocuments(downloadedDocuments)
    }

    const refreshDetails = () => {
        if (!!organisationId && !!verificationId) {
            getRequest(`organisations/${organisationId}/verifications/${verificationId}`)
                .then(r => {
                    !!r && !!r.personalDetails && setPersonalDetails(r.personalDetails);
                    !!r && !!r.address && setAddressDetails(r.address);
                })
                .catch(() => setDataError(true))
        }
    }

    const refreshDocuments = () => {
        if (!!organisationId && !!verificationId) {
            getRequest(`organisations/${organisationId}/compliance/${verificationId}/documents`)
                .then((res) => downloadDocuments(res || []))
                .catch(() => setDataError(true))
        }
    }

    const refreshView = () => {
        refreshDetails();
        refreshDocuments();
    }

    useEffect(() => {
        refreshView();
    }, [organisationId, verificationId])

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            placement="right"
            onClose={() => !!onClose && onClose()}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Compliance Profile</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <LoadingScreen
                        hasData={documents !== null}
                        hasError={dataError}
                        render={() => (
                            <Stack>
                                {
                                    !!personalDetails && <Stack>
                                        <H3>Personal Details</H3>
                                        <Box>
                                            <Stack>
                                                <Stack flex="1" row>
                                                    <Field flex="1" row label="Firstname" value={personalDetails.firstName || "-"} />
                                                    {!!personalDetails.middleNames && <Field flex="1" row label="Middlenames" value={!!personalDetails.middleNames ? (personalDetails.middleNames || []).join(' ') : "-"} />}
                                                    <Field flex="1" row label="Surname" value={personalDetails.lastName || "-"} />
                                                </Stack>
                                                <Stack flex="1" row>
                                                    <Field flex="1" row label="Date of Birth" value={!!personalDetails.dateOfBirth ? formatDate(personalDetails.dateOfBirth) : "-"} />
                                                    <Field flex="1" row label="Nationality" value={personalDetails.nationality || "-"} />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                }
                                {
                                    !!addressDetails && <Stack>
                                        <H3>Address</H3>
                                        <Box>
                                            <Stack>
                                                <Stack flex="1" row>
                                                    <Field flex="1" row label="Building No" value={addressDetails.buildingNumber || "-"} />
                                                    <Field flex="1" row label="Building Name" value={addressDetails.buildingName || "-"} />
                                                </Stack>
                                                <Field row label="Street" value={addressDetails.street || "-"} />z
                                                <Field row label="Post Code" value={addressDetails.postalCode || "-"} />
                                                <Field row label="City" value={addressDetails.city || "-"} />
                                                <Field row label="County" value={addressDetails.county || "-"} />
                                                <Field row label="District" value={addressDetails.district || "-"} />
                                                <Field row label="State" value={addressDetails.state || "-"} />
                                                <Field row label="Country" value={addressDetails.country || "-"} />
                                            </Stack>
                                        </Box>
                                    </Stack>
                                }
                                <Stack>
                                    <H3>Documents</H3>
                                    {
                                        (documents || []).length > 0
                                            ? documents.map((document, index) =>
                                                <Fragment>
                                                    {index !== 0 && <HR />}
                                                    <Box>
                                                        <Stack>
                                                            <Field row label="Id" value={document.id || "-"} />
                                                            <Field row label="Filename" value={document.filename || "-"} />
                                                            <Field row label="Session" value={document.sessionId || "-"} />
                                                            <Field row label="Content Type" value={document.contentType || "-"} />
                                                            <Field row label="Document Type" value={document.documentType || "-"} />
                                                            {!!document.data && <Img src={document.data} alt={`Document ${index}`} height="16rem" />}
                                                        </Stack>
                                                    </Box>
                                                </Fragment>)
                                            : <Span>No documents</Span>
                                    }
                                </Stack>
                            </Stack>
                        )} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ComplianceDocumentsDrawer