import React from 'react'
import {
    ButtonStack,
    Stack,
    Button,
    HeaderSection,
    Field,
    UploadedFile,
    Box,
    Flex,
    Span
} from 'paid-ui-lib'

const PurchaseOrderSection = ({ work, refreshWork, ...rest }) => {
    return (
        <HeaderSection title="Purchase Order" actions={
            <ButtonStack row ml="auto">
                {work.actions.canEdit && !work.pendingAmendment && <Button leftIcon="edit" isSmall variant="secondary" onClick={() => setShowAmendDrawer(true)}>Edit Amount</Button>}
            </ButtonStack>
        } {...rest}>
            <Box>
                <Stack>
                    <Stack row>
                        <Field flex="1" label="Purchase Order Number" value={work.purchaseOrder.purchaseOrderNumber} />
                        <Field flex="1" label="Purchase Order Amount" value={`${work.amountBreakdown.currencySymbol}${work.purchaseOrder.amount.toFixed(2)}`} />
                    </Stack>
                    {
                        (!!work.amountBreakdown.adjustmentTolerance) &&
                        <Stack row>
                            <Field flex="1" label="Adjustment Tolerance" value={`${work.amountBreakdown.adjustmentTolerance.toFixed(2)}%`} />
                            {
                                !!work.pendingAmendment
                                ? (
                                    <Field flex="1" label="Current Amount">
                                        <Flex>
                                            <Span textDecoration="line-through" mr={8}>{work.amountBreakdown.currencySymbol}{work.amountBreakdown.subTotal.toFixed(2)}</Span>
                                            <Span color="orange.500">{work.amountBreakdown.currencySymbol}{work.pendingAmendment.newAmount.toFixed(2)}</Span>
                                        </Flex>
                                    </Field>
                                )
                                : <Field flex="1" label="Spendable Amount" value={`${work.amountBreakdown.currencySymbol}${work.amountBreakdown.subTotal.toFixed(2)}`} />
                            }
                        </Stack>
                    }
                    <Field label="Purchase Order">
                        {work.purchaseOrder && <UploadedFile {...work.purchaseOrder} />}
                    </Field>
                </Stack>
            </Box>
        </HeaderSection>
    )
}

export default PurchaseOrderSection