import React, { Fragment, useState, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import {
    PageTitle,
    formatDateTime,
    Badge,
    ButtonStack,
    Flex,
    PageContent,
    FilterButton,
    Button,
    Link
} from 'paid-ui-lib'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'

const ListOrganisationsView = (props) => {
    const { postRequest } = useRequest()
    const [filterData, setFilterData] = useState({})
    const [dataError, setDataError] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [activeFilters, setActiveFilters] = useState()
    const [supplierDownload, setSupplierDownload] = useState(false)

    useEffect(() => {
        initFilteredDataTable({
            filterPath: "organisations/filters",
            dataPath: "organisations/search",
            location: props.location,
            setFilterData,
            setDataError
        }).then(showFilter => setShowFilter(showFilter))
    }, [])

    const columns = [
        { "header": "Name", "accessor": "name", "sortId": "2", maxWidth: "16rem" },
        { "header": "Created", "accessor": "createdOn", "sortId": "5" },
        { "header": "Last Login", "accessor": "lastLogin" },
        { "header": "Enterprises", "accessor": "enterprises", maxWidth: "12rem" },
        { "header": "Status", "accessor": "status" }
    ].filter(c => !!c)
    
    const transform = (data) => {
        return {
            id: data.id,
            email: data.email,
            name: <Link action={`/organisations/${data.id}`}>{data.name}</Link>,
            lastLogin: formatDateTime(data.lastLogin),
            createdOn: formatDateTime(data.createdOn),
            enterprises: data.enterpriseClients.map(c => c.name).join(", "),
            status: <Flex alignItems="center">
                <Badge status={data.suspended ? "error" : "success"}>
                    {data.suspended ? "Suspended" : "Active"}
                </Badge>
            </Flex>
        }
    }

    const getFilterParams = () => {
        return {
            filters: activeFilters.map(af => af.filter),
        }
    }

    const downloadFile = (path, fileName) => {
        const filters = getFilterParams()
        return postRequest(`${path}`, filters)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
    }

    const downloadOrganisations = () => {
        setSupplierDownload(true)
        downloadFile(`organisations/export`, `Organisations-${new Date()}.csv`)
            .finally(() => setSupplierDownload(false))
    }

    return (
        <LoadingScreen
            hasData={filterData.data}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Organisations" description="The organisation base" slim>
                        <ButtonStack row>
                            <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                            <Button onClick={() => downloadOrganisations()} variant="secondary" aria-label="Download" isLoading={supplierDownload} showChevron>{supplierDownload ? "Downloading..." : "Download"}</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <FilteredDataTable
                            initData={filterData}
                            showFilter={showFilter}
                            setDataError={setDataError}
                            setParentActiveFilters={setActiveFilters}
                            transform={transform}
                            columns={columns}
                            sortable={true} />
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ListOrganisationsView