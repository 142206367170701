import React from 'react'
import {
    Flex,
    Stack,
    H2,
    P,
    Icon
} from 'paid-ui-lib'

const ErrorScreen = ({...rest}) => (
    <Flex height={["50vh","100%","100%"]} {...rest}>
        <Stack m="auto" spacing={8} alignItems="center">
            <Icon name="alert-triangle" size="3rem" color="red.500"/>
            <H2>Data Request Error</H2>
            <P textAlign="center">Please refresh the page to try again.</P>
        </Stack>
    </Flex>
)

export default ErrorScreen