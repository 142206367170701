import React, { useState, useEffect, Fragment } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    A,
    Badge,
    ButtonStack,
    Flex,
    formatDateTime,
    Icon,
    IconButton,
    Panel,
    Span,
    Spinner,
    Strong,
    Stack,
    Table,
} from 'paid-ui-lib'

import ConfirmModal from 'components/ConfirmModal'
import NoDataScreen from 'components/NoDataScreen'
import ToggleBox from 'components/ToggleBox'

import CopyComplianceProfileModal from './components/CopyComplianceProfileModal'
import ComplianceTaskDrawer from './components/ComplianceTaskDrawer'
import DocumentsDrawer from './components/DocumentsDrawer'
import ComplianceDocumentsDrawer from './components/ComplianceDocumentsDrawer'

const CompliancePanel = ({ organisationId, organisation, verificationId }) => {
    const [dataError, setDataError] = useState(false)
    const [compliance, setCompliance] = useState(null)
    const [shareComplianceFlow, setShareComplianceFlow] = useState(null)
    const [showComplianceInvite, setShowComplianceInvite] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showComplianceDocuments, setShowComplianceDocuments] = useState(false)
    const [showCopyComplianceRecord, setShowCopyComplianceRecord] = useState(false)
    const [selectedTask, setSelectedTask] = useState(undefined)
    const [selectedAction, setSelectedAction] = useState(undefined)
    const { getRequest, postRequest, putRequest } = useRequest()
    const kycEnabled = !!organisation && !!organisation.kycEnabled;

    const refreshView = () => {
        if (!!organisationId && !!verificationId) {
            getRequest(`organisations/${organisationId}/compliance/${verificationId}`)
                .then(r => setCompliance(r || {}))
                .catch(() => setDataError(true));
        }
        else {
            setCompliance({});
        }
    }

    useEffect(() => {
        refreshView()
    }, [organisationId, verificationId])

    const actionTask = (taskId, result) => {
        return putRequest(`organisations/${organisationId}/compliance/${verificationId}/tasks/${taskId}`, { result: result })
            .then(() => refreshView())
            .catch(() => setDataError(true))
    }

    const createComplianceInvite = (o, e) => {
        return postRequest(`organisations/${o}/compliance/invite`, !!e ? { emailAddress: e } : {})
            .then(setShareComplianceFlow)
            .catch(() => setDataError(true))
            .finally(() => setShowComplianceInvite(false))
    }

    const getBadge = (status) => {
        if (status == "Pending" || status == "Warning" || status == "Optional") {
            return "info";
        }
        else if (status == "Success") {
            return "success";
        }
        else if (status == "Failed") {
            return "error";
        }
        else {
            return "info";
        }
    }

    const headings = [
        { "header": "Task", "accessor": "task", maxWidth: "16rem", style: { title: true } },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Status", "accessor": "status" },
        { "header": "", "accessor": "actions" },
    ]

    const transform = (data) => {
        const titleArray = !!data && !!data.type ? data.type.match(/[A-Z][a-z]+/g) : [];
        const title = titleArray.length > 0 ? titleArray.join(' ') : "";
        const titleSubstring = title.length > 40 ? `${title.substring(0, 40)}...` : title;
        return {
            task: <A cursor="pointer" onClick={() => setSelectedTask(data)}>{titleSubstring}</A>,
            provider: (data.checks || []).length > 0 && data.checks[0].providers.join(", "),
            createdOn: formatDateTime(data.createdOn),
            status: <Badge ml="auto" status={getBadge(data.result)}>{data.result}</Badge>,
            actions: <ButtonStack row>
                {/* {!!data && !!data.actions && !!data.actions.canReject && <Button isSmall variant="destructive" icon="x" onClick={() => setSelectedAction({ ...data, title: title, action: "Failed", buttonText: "Fail", buttonVariant: "destructive", value: "FAILED" })}>Reject</Button>}
                {!!data && !!data.actions && !!data.actions.canPending && <Button isSmall variant="secondary" icon="minus" onClick={() => setSelectedAction({ ...data, title: title, action: "Pending", buttonText: "Pending", buttonVariant: "secondary", value: "PENDING" })}>Awaiting</Button>}
                {!!data && !!data.actions && !!data.actions.canApprove && <Button isSmall variant="primary" icon="check" onClick={() => setSelectedAction({ ...data, title: title, action: "Success", buttonText: "Pass", buttonVariant: "primary", value: "SUCCESS" })}>Pass</Button>} */}
                {!!data && !!data.actions && !!data.actions.canReject && <IconButton isSmall variant="destructive" icon="x" onClick={() => setSelectedAction({ ...data, title: title, action: "Failed", buttonText: "Fail", buttonVariant: "destructive", value: "FAILED" })} />}
                {/* {!!data && !!data.actions && !!data.actions.canPending && <IconButton isSmall variant="secondary" icon="minus" onClick={() => setSelectedAction({ ...data, title: title, action: "Pending", buttonText: "Pending", buttonVariant: "secondary", value: "PENDING" })} />} */}
                {!!data && !!data.actions && !!data.actions.canApprove && <IconButton isSmall variant="primary" icon="check" onClick={() => setSelectedAction({ ...data, title: title, action: "Success", buttonText: "Pass", buttonVariant: "primary", value: "SUCCESS" })} />}
            </ButtonStack>
        }
    }

    const onCloseTaskDraw = (refresh) => {
        setSelectedTask(undefined);
        !!refresh && refreshView();
    }

    const openComplianceDocumentsDrawer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowComplianceDocuments(true);
    }

    return (
        <ToggleBox key={2} title="Compliance" {...(!!compliance ? (!!compliance.status ? { badgeText: compliance.status, badgeStatus: getBadge(compliance.status) } : { badgeText: !!kycEnabled ? "Pending" : "Optional", badgeStatus: getBadge(!!kycEnabled ? "Pending" : "Optional") }) : {})}>
            <LoadingScreen
                hasData={!!compliance}
                hasError={dataError}
                render={() => (
                    <Panel>
                        <ConfirmModal
                            title="Invite Generated"
                            isOpen={shareComplianceFlow}
                            buttonVariant="primary"
                            message={<Stack>
                                <Span>Click to open:</Span>
                                {!!shareComplianceFlow && !!shareComplianceFlow.url && <A href={shareComplianceFlow.url} target="_blank">{shareComplianceFlow.url}</A>}
                            </Stack>}
                            onClose={() => setShareComplianceFlow(null)} />
                        <ConfirmModal
                            isOpen={showComplianceInvite}
                            buttonText="Create"
                            buttonVariant="primary"
                            message="You are about to create a public verification link. Any user with this link will be able to submit verification data on behalf of this organisation."
                            onConfirm={() => createComplianceInvite(organisationId)}
                            onClose={() => setShowComplianceInvite(false)} />
                        <ConfirmModal
                            isOpen={selectedAction}
                            buttonText={!!selectedAction && !!selectedAction.buttonText ? selectedAction.buttonText : "Yes"}
                            buttonVariant={!!selectedAction && !!selectedAction.buttonVariant ? selectedAction.buttonVariant : "primary"}
                            message={`Are you sure you would like to mark the task '${!!selectedAction ? selectedAction.title : ""}' as '${!!selectedAction ? selectedAction.buttonText : ""}'?`}
                            onConfirm={() => actionTask(selectedAction.id, selectedAction.value)}
                            onClose={() => setSelectedAction(undefined)} />
                        <CopyComplianceProfileModal organisationId={organisationId} verificationId={verificationId} isOpen={showCopyComplianceRecord} organisation={organisation} onClose={(refresh) => { setShowCopyComplianceRecord(false); !!refresh && refreshView() }} />
                        <Stack>
                            <Stack flex="1" row responsive>
                                <A flex="1" cursor="pointer" target="_blank" onClick={() => setShowCopyComplianceRecord(true)}>Create new compliance profile</A>
                                <A cursor="pointer" target="_blank" onClick={() => setShowComplianceInvite(true)}>Create compliance link</A>
                            </Stack>
                            {
                                !!compliance && !!compliance.providerDetails &&
                                <Stack flex="1" row responsive>
                                    <Stack flex="1" row responsive>
                                        <Strong>Provider:</Strong>
                                        <Stack row spacing={8} alignItems="center">
                                            {
                                                !!compliance.providerDetails.link
                                                    ? <Stack cursor="pointer" row spacing={8}>
                                                        <A target="_blank" href={compliance.providerDetails.link}>{compliance.providerDetails.name}</A>
                                                        <Icon name="external-link" />
                                                    </Stack>
                                                    : <Stack cursor="pointer" row spacing={8}>
                                                        <A target="_blank" href="#" onClick={openComplianceDocumentsDrawer}>{compliance.providerDetails.name}</A>
                                                        <Icon name="external-link" />
                                                    </Stack>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Stack row alignItems="center" spacing={8}>
                                        <A cursor="pointer" onClick={() => setShowDocuments(true)}>Partnership Documents</A>
                                        <Icon name="external-link" />
                                    </Stack>
                                </Stack>
                            }
                            <Table columns={headings} data={!!compliance && [...compliance.tasks || [], ...(compliance.users || []).flatMap(u => u.tasks || [])]} transform={transform} noData={<NoDataScreen />} />
                            {!!selectedTask && <ComplianceTaskDrawer task={selectedTask} isOpen={!!selectedTask} onClose={onCloseTaskDraw} />}
                            {!!showComplianceDocuments && <ComplianceDocumentsDrawer organisationId={organisationId} verificationId={verificationId} isOpen={!!showComplianceDocuments} onClose={() => setShowComplianceDocuments(false)} />}
                            {!!showDocuments && <DocumentsDrawer organisationId={organisationId} isOpen={!!showDocuments} onClose={() => setShowDocuments(false)} />}
                        </Stack>
                    </Panel>
                )}
            />
        </ToggleBox>
    )
}

export default CompliancePanel 