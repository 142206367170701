import React from 'react'
import useFormal from "@kevinwolf/formal-web";
import * as yup from "yup";
import {
    Input,
    Stack,
    Sub,
    Button,
    Field,
    PseudoElement,
    Checkbox
} from 'paid-ui-lib'

const AuthenticatorTfa = (props) => {
    const formal = useFormal({
        authenticatorCode: ""
    }, {
        schema: yup.object().shape({
            authenticatorCode: yup.string().required("Verification Code is required"),
        }),
        onSubmit: values => props.onTfaSubmit(values.authenticatorCode, props.Remember)
    });

    return (
        <PseudoElement flex={1} as="form" {...formal.getFormProps()}>
            <Stack>
                <Field
                label="Authenticator Code"
                isRequired
                helpMessage="Please input the 6 digit code generated by your Authenticator app."
                errorMessage={formal.errors.authenticatorCode}>
                    <Input {...formal.getFieldProps("authenticatorCode")} type="tel" autocomplete="off" autoFocus/>
                </Field>
                <Stack spacing={12}>
                    { props.allowRemember && <Checkbox checked={props.Remember} onChange={(chk) => props.SetRemember(!!chk.target.checked)}><Sub lightText>Remember Authenticator until end of week</Sub></Checkbox> }
                    <Button type="submit" {...formal.getSubmitButtonProps()} disabled={formal.isSubmitting}>
                        Submit
                    </Button>
                    <Sub lightText>Unable to submit your code? Contact support</Sub>
                </Stack>
            </Stack>
        </PseudoElement>
    )
}

export default AuthenticatorTfa