import React from 'react'
import {
    PageDiv,
    CenteredDiv    
} from './index.styles'
import {
    H1
} from 'paid-ui-lib'

const NotFoundView = (props) => {
    return (
        <PageDiv>
            <CenteredDiv>
                <H1>Page Not Found</H1>
            </CenteredDiv>
        </PageDiv>
    )
}

export default NotFoundView