import React, { useState, useEffect, useMemo } from "react"
import {
    Flex,
    Input,
    Stack,
    Box,
    Button,
    Field,
    Tags,
    InputGroup,
    InputTypeahead,
    TextArea,
    DynamicHeightAdjuster,
    useMedia,
    DynamicInputField
} from 'paid-ui-lib'

const DeliverableEdit = ({
    value,
    currencySymbol,
    enableQuickAdd,
    getDeliverableTemplates,
    deliverableTemplates,
    allowFreeText,
    onCancel,
    onChange,
    onRemove,
    ...rest
}) => {
    const [errors, setErrors] = useState({})
    const [selectedTemplate, setSelectedTemplate] = useState(!!deliverableTemplates ? deliverableTemplates.find(dt => dt.id === value.workItemId) : null)
    const [editableItem, setEditableItem] = useState(value)
    const media = useMedia()

    const quickAddTemplates = useMemo(() => {
        return deliverableTemplates
            .filter(dt => dt.quickAdd)
            .map(dt => { return {...dt, name: dt.template}})
    }, [deliverableTemplates])

    useEffect(() => {
        setEditableItem(ei => { return {
            ...ei,
            description: !!selectedTemplate ? selectedTemplate.template.replace("{X}", ei.workItemValue) : (ei.description || ""),
        }})
    }, [editableItem.workItemValue, selectedTemplate])

    useEffect(() => {
        setSelectedTemplate(deliverableTemplates.find(dt => dt.id === value.workItemId))
    }, [deliverableTemplates])
    
    const changeField = (field, value) => {
        setEditableItem(ei => { return {
            ...ei,
            [field]: value
        }})
    }

    const onTypeaheadSelected = (item) => {        
        setEditableItem(ei => {
            return {
                ...ei,            
                workItemId: item.id
            }
        })
        setSelectedTemplate(item)
    }

    const onDescriptionChange = (input) => {
        setEditableItem(ei => {
            return {
                ...ei,
                workItemId: null,
                description: input
            }
        })
    }

    const changeAdditionalField = (id, value) => {
        const ind = editableItem.additionalFields.findIndex(af => af.id === id)

        if(ind > -1) {
            setEditableItem(ei => { 
                const temp = [...editableItem.additionalFields]
                const tempItem = temp[ind]
                temp.splice(ind, 1, {...tempItem, value: value})

                return {
                    ...ei,
                    additionalFields: temp
                }
            })
        } else {
            setEditableItem(ei => { 
                const additionalField = !!selectedTemplate ? selectedTemplate.additionalFields.find(af => af.id === id) : null

                return {
                    ...ei,
                    additionalFields: [
                        ...ei.additionalFields,
                        {
                            id: id,
                            name: !!additionalField ? additionalField.name : "",
                            value: value
                        }
                    ]
                }
            })
        }        
    }

    const saveChanges = () => {
        const additionalFieldIds = !!selectedTemplate ? selectedTemplate.additionalFields.map(af => af.id) : []
        const res = {
            ...editableItem,
            amount: parseFloat(editableItem.amount) || 0,
            deleteDisabled: false,
            additionalFields: editableItem.additionalFields.filter(af => additionalFieldIds.indexOf(af.id) > -1)
        }

        if(!editableItem.workItemId && !allowFreeText){
            setErrors(e => { return { ...e, description: "Deliverable must be selected from the drop down menu." }})
            return
        }

        onChange(value.id, res)
    }
    
    return (
        <Box {...rest}>
            <Stack>
                {
                    !!enableQuickAdd &&
                    <Field label="Suggested Deliverables" overwriteAccessibility>
                        <Tags value={quickAddTemplates} onTagClick={onTypeaheadSelected}/>                    
                    </Field>
                }
                <Stack spacing={2} row>                    
                    <Field label="Deliverable" flex="1" errorMessage={errors.description}>
                        <InputTypeahead
                            placeholder="Start typing deliverable name..."
                            value={editableItem.description}
                            selectedValue={editableItem.workItemId}                   
                            data={deliverableTemplates}
                            displayProperty="template"
                            onChange={onDescriptionChange}
                            onSelected={onTypeaheadSelected}
                            />
                    </Field>
                    <Field label="Amount" flex={[undefined, "0 1 80px","0 1 80px"]}>
                        <Input type="number" value={editableItem.workItemValue} onChange={(e) => changeField("workItemValue", e.target.value)}/>
                    </Field>
                    <Field label="Price (Ex Vat)" flex={[undefined, "0 1 120px","0 1 120px"]}>
                        <InputGroup>
                            {/* {currencySymbol && <InputGroupText>{currencySymbol}</InputGroupText>} */}
                            <Input type="number" roundedLeft="0" value={editableItem.amount} onChange={(e) => changeField("amount", e.target.value)}/>
                        </InputGroup>
                    </Field>
                </Stack>
                {
                    (!!selectedTemplate && selectedTemplate.additionalFields.length > 0) &&
                    (
                        <Stack flex="1">
                            {selectedTemplate.additionalFields.sort((a,b) => a.order - b.order).map(rcd => 
                                <DynamicInputField
                                    key={rcd.id}
                                    field={rcd}
                                    value={(editableItem.additionalFields.find(af => af.id === rcd.id) || {value:""}).value}
                                    onChange={(v) => changeAdditionalField(rcd.id, v)}
                                    onChanges={(v) => Object.keys(v).forEach(k => changeAdditionalField(k, v[k]))}
                                    errorMessage={null}
                                    />)}
                        </Stack>
                    )
                }
                <Field fieldFor={"deliverable_edit_description"} label="Additional Description" overwriteAccessibility>
                    <DynamicHeightAdjuster enabled={media !== "desk" && media !== "lap"}>
                        <TextArea id="deliverable_edit_description" value={editableItem.additionalDescription} onChange={(e) => changeField("additionalDescription", e.target.value)}></TextArea>
                    </DynamicHeightAdjuster>
                </Field>
                <Stack row spacing={8} justifyContent="flex-end" responsiveReverse>
                    <Flex flex="1">
                    {!editableItem.deleteDisabled && <Button flex={["1", "0 1 100px","0 1 100px"]} isSmall variant="destructive" onClick={() => onRemove(value.id)} leftIcon="trash-b">Remove</Button>}
                    </Flex>
                    <Button flex={[undefined, "0 1 100px","0 1 100px"]} isSmall variant="secondary" onClick={() => onCancel(value.id, value)} leftIcon="x-circle">Cancel</Button>
                    <Button flex={[undefined, "0 1 100px","0 1 100px"]} isSmall variant="secondary" onClick={() => saveChanges()} leftIcon="save">Save</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default DeliverableEdit