import React from 'react'
import img from '../../images/paid-logo.png'
//Dont import design system here, this is loaded first to get something on screen. the rest of the app is loaded in after

const SplashScreen = () => {
    return (
        <div style={{position:"absolute",display:"flex",top:0,left:0,right:0,bottom:0}}>
            <div style={{margin:"auto"}}>
                <img src={img} width="125" height="52" alt="Loading"/>
            </div>
        </div>
    )
}

export default SplashScreen