import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import UserView from './routes/view'

const UsersRoutes = () => {
    return (
        <Switch>
            <Route exact path="/users/:userId" component={UserView} />
            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default UsersRoutes