import React, { useState } from 'react'
import {
    H3,
    Badge,
    Box,
    Flex,
    Icon,
    Collapse,
} from 'paid-ui-lib'

const ToggleBox = ({title, badgeText, badgeLabel, badgeStatus, children, ...rest}) => {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <Box {...rest}>
            <Flex mb={16} alignItems="center" cursor={undefined} onClick={() => setIsOpen(e => !e)}>
                <H3>{title}</H3>
                {<Icon ml={16} size={16} name={isOpen ? "chevron-up" : "chevron-down"} />}
                { !!badgeStatus && <Badge ml="auto" status={badgeStatus}>{badgeText}</Badge>}
            </Flex>
            {
                <Collapse isOpen={isOpen}>
                    {children}
                </Collapse>
            }
        </Box>
    )
}

export default ToggleBox