import React, { useState } from 'react'
import {
    PseudoElement,
    Badge,
    Box,
    Flex,
    Span,
    Icon,
    Sub,
    Collapse,
} from 'paid-ui-lib'

const ToggleRow = ({label, badgeText, badgeLabel, badgeStatus, additionalText, ...rest}) => {
    const [isOpen, setIsOpen] = useState(true)

    const isArray = (arg) => {
        return !!Array.isArray ? Array.isArray(arg) : Object.prototype.toString.call(arg) === '[object Array]';
    }

    const getAdditionalText = () => {
        if (!additionalText)
            return [];
        return isArray(additionalText) ? additionalText : [ additionalText ];
    }

    return (
        <Box {...rest}>
            <Flex alignItems="center" cursor={!!additionalText ? "pointer" : undefined} onClick={() => setIsOpen(e => !e)}>
                <Span>{label}</Span>
                {!!additionalText && <Icon ml={8} size={16} name={isOpen ? "chevron-up" : "chevron-down"} />}
                <Badge ml="auto" status={badgeStatus}>{badgeText}</Badge>
            </Flex>
            {
                    <Collapse isOpen={isOpen}>
                        {
                            getAdditionalText().map((text, i) => {
                                return (
                                    <PseudoElement key={i} mt={12} px={12} py={8} border="1" borderRadius="default" backgroundColor="grey.100">
                                        <Sub>{text}</Sub>
                                    </PseudoElement>
                                );
                            })
                        }
                    </Collapse>
            }
        </Box>
    )
}

export default ToggleRow