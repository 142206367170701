import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './components/Route'
import AuthorisedLayoutPartial from './components/AuthorisedLayoutPartial'
import UnauthorisedLayoutPartial from './components/UnauthorisedLayoutPartial'
import LoginView from './routes/login'
import DashboardView from './routes/dashboard'
import OrganisationRoutes from './routes/organisations/routes'
import EnterpriseRoutes from './routes/enterprise/routes'
import UserRoutes from './routes/users/routes'
import WorkRoutes from './routes/work/routes'
import InvoicesRoutes from './routes/invoices/routes'
import AccountRoutes from './routes/account/routes'
import AdministratorRoutes from './routes/administrators/routes'
import SettingsRoutes from './routes/settings/routes'
import PaymentRoutes from './routes/payments/routes'
import EmailRoutes from './routes/emails/routes'

const PageRoutes = () => {    
    return (
        <Switch>           
            {/* Login */}
            { <Route exact path="/login" layout={UnauthorisedLayoutPartial} component={LoginView} loggedInRedirect /> }
            {/* Home  */}
            { <Route exact path="/" layout={AuthorisedLayoutPartial} component={DashboardView} privateRoute /> }
            {/* Suppliers */}
            { <Route path="/organisations" permission={100} layout={AuthorisedLayoutPartial} component={OrganisationRoutes} privateRoute /> }
            {/* Buyers */}
            { <Route path="/enterprise" permission={200} layout={AuthorisedLayoutPartial} component={EnterpriseRoutes} privateRoute /> }
            {/* Users */}
            { <Route path="/users" permissions={700} layout={AuthorisedLayoutPartial} component={UserRoutes} privateRoute /> }
            {/* Work */}
            { <Route path="/work" permission={400} layout={AuthorisedLayoutPartial} component={WorkRoutes} privateRoute /> }
            {/* Invoices */}
            { <Route path="/invoices" permission={500} layout={AuthorisedLayoutPartial} component={InvoicesRoutes} privateRoute /> }
            {/* Account */}
            { <Route path="/account" layout={AuthorisedLayoutPartial} component={AccountRoutes} privateRoute /> }
            {/* Administrators */}
            { <Route path="/administrators" permission={600} layout={AuthorisedLayoutPartial} component={AdministratorRoutes} privateRoute /> }
            {/* Roles */}
            { <Route path="/settings" layout={AuthorisedLayoutPartial} component={SettingsRoutes} privateRoute /> }
            {/* Payments */}
            { <Route path="/payments" layout={AuthorisedLayoutPartial} component={PaymentRoutes} privateRoute /> }
            {/* Emails */}
            { <Route path="/emails" layout={AuthorisedLayoutPartial} component={EmailRoutes} privateRoute /> }
        </Switch>
    )
}

export default PageRoutes