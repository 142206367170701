import React from 'react'
import {
    Icon,
    IconCircle,
} from "paid-ui-lib"
import PseudoElement from 'paid-ui-lib/dist/lib/core-components/Misc/PseudoElement';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export const getMonth = (date) => {
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date

    return dateObj ? months[dateObj.getMonth()] : '-';
}

const getDateString = (date, excludeDate) => {
    return `${excludeDate ? "" : (date.getDate() < 10 ? '0' : '') + date.getDate() + " "}${getMonth(date)} ${date.getFullYear()}`
}

const getTimeString = (date) => {
    const min = date.getMinutes()
    const hour = date.getHours()

    return `${(hour < 10 ? '0' : '') + hour}:${(min < 10 ? '0' : '') + min}`
}

export const formatMonth = (date) => {
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date

    return dateObj 
        ? getDateString(dateObj, true)
        : '-';
}

export const formatDate = (date) => {
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date

    return dateObj 
        ? getDateString(dateObj)
        : '-';
}

export const formatDateTime = (date, tick) => {
    if (date === null) return "-"
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date

    let value = `${getDateString(dateObj)} ${getTimeString(dateObj)}`

    if (tick) {
        value = <PseudoElement><Icon name="check-circle" color="green.500" size="1.2em" mr={8}/>{value}</PseudoElement>
    }
    return dateObj 
        ? value
        : '-';
}

export const formatJsonDateString = (date) => {
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date
    
    return dateObj 
    ? `${dateObj.getFullYear()}-${((dateObj.getMonth() + 1) < 10 ? '0' : '') + (dateObj.getMonth() + 1)}-${(dateObj.getDate() < 10 ? '0' : '') + dateObj.getDate()}`
    : ''
}

export const daysFromToday = (date) => {
    var today = new Date();
    const dateObj = date && typeof(date) === 'string' ? new Date(date) : date

    return Math.floor((today.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24)) 
}