import React, { Fragment } from 'react'
import {
    Badge,
    Box,
    Flex,
    H4,
    HR,
    Icon,
    Radio,
    Span,
    Stack,
    Strong,
    useI18N,
    formatDate,
} from 'paid-ui-lib'

const PaymentOption = ({ key, enterpriseName, selected, paymentTerms, vatRegistered, taxLabel, errorMessage, ...rest }) => {

    const { formatCurrency } = useI18N()
    const disabled = !!paymentTerms && !paymentTerms.available;

    const getIcon = () => {
        if (!paymentTerms) return <Fragment />;
        switch (paymentTerms.description) {
            case 'Standard Payment': return <Icon size={32} p={8} name="bank" />
            case 'Express Payment': return <Icon size={32} p={8} name="express-payment" />
            case 'Virtual Card Payment': return <Icon size={32} p={8} name="credit-card" />
            default: return <Fragment />
        }
    }

    return (
        <Box
            key={key}
            flex="1"
            userSelect="none"
            {...(
                !!disabled
                    ? { "backgroundColor": "grey.200", "borderColor": "grey.400" }
                    : !!errorMessage ? { "borderColor": "red.800" } : (!!selected ? { "borderColor": "blue.300", "backgroundColor": "blue.50" } : {})
            )}
            {...rest}>
            {
                <Stack flex="1" spacing={24}>
                    <Stack spacing={12}>
                        <Stack spacing={12} row alignItems="center">
                            <Flex backgroundColor="white" borderColor="grey.100" borderRadius="default" border="1">
                                {getIcon()}
                            </Flex>
                            <H4 flex="1">{paymentTerms.description}</H4>
                            <Radio cursor="pointer" key={`${paymentTerms.id}-i`} checked={selected} onClick={(e) => { }} onChange={() => { }} />
                        </Stack>
                        {/* {!!paymentTerms.preferred && <Stack row><Badge status="success">Preferred</Badge></Stack>} */}
                    </Stack>
                    <HR />
                    <Stack flex="1" spacing={12}>
                        <Stack row>
                            <Strong flex="1">Provider:</Strong>
                            <Strong textAlign="right">{paymentTerms.name}</Strong>
                        </Stack>
                        {
                            (!!vatRegistered || !!paymentTerms.vatTotal)
                                ? (
                                    <Fragment>
                                        <Stack row>
                                            <Span flex="1">Sub Total:</Span>
                                            <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.subTotal)}</Span>
                                        </Stack>
                                        <Stack row>
                                            <Span flex="1">{taxLabel} Amount:</Span>
                                            <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.vatTotal)}</Span>
                                        </Stack>
                                        {
                                            !!paymentTerms.carbonOffsetTotal &&
                                            <Stack row>
                                                <Span flex="1">Carbon Offsetting Fee:</Span>
                                                <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.carbonOffsetTotal)}</Span>
                                            </Stack>
                                        }
                                        <Stack row>
                                            <Span flex="1">Total Value:</Span>
                                            <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.total)}</Span>
                                        </Stack>
                                    </Fragment>
                                )
                                : (
                                    <Fragment>
                                        <Stack row>
                                            <Span flex="1">Sub Total:</Span>
                                            <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.subTotal)}</Span>
                                        </Stack>
                                        {
                                            !!paymentTerms.carbonOffsetTotal &&
                                            <Stack row>
                                                <Span flex="1">Carbon Offsetting Fee:</Span>
                                                <Span textAlign="right">{formatCurrency(paymentTerms.currencyCode, paymentTerms.carbonOffsetTotal)}</Span>
                                            </Stack>
                                        }
                                    </Fragment>
                                )
                        }
                        {
                            (paymentTerms.paymentDate || paymentTerms.paymentTermDays) && (
                                <Stack row>
                                    {
                                        !!paymentTerms.paymentDate
                                            ? <Span flex="1">Payment Date:</Span>
                                            : <Span flex="1">Time to payment:</Span>
                                    }
                                    {
                                        !!paymentTerms.paymentDate
                                            ? <Span textAlign="right">{formatDate(paymentTerms.paymentDate)}</Span>
                                            : <Span textAlign="right">Up to {paymentTerms.paymentTermDays} Days</Span>
                                    }
                                </Stack>
                            )
                        }
                        <Stack row>
                            <Span flex="1">Fee Funded:</Span>
                            <Span textAlign="right">{!!paymentTerms.feeFunded ? "Yes" : "No"}</Span>
                        </Stack>
                        {
                            !!paymentTerms.feeFunded
                                ? (<Fragment>
                                    <Stack row>
                                        <Span flex="1">Fee:</Span>
                                        <Flex direction="column" textAlign="right">
                                            <Span textDecoration="line-through">{formatCurrency(paymentTerms.currencyCode, paymentTerms.fee)} ({paymentTerms.feeRate}%)</Span>
                                            <Stack row alignItems="center">
                                                <Icon name="check-circle" color="green.700" />
                                                <Span>Fee funded</Span>
                                            </Stack>
                                        </Flex>
                                    </Stack>
                                    <HR />
                                    <Stack row responsive>
                                        <Strong flex="1">Supplier will receive:</Strong>
                                        <Span ml="auto" textAlign="auto" fontWeight="bold">{formatCurrency(paymentTerms.currencyCode, paymentTerms.total)}</Span>
                                    </Stack>
                                    <Box backgroundColor="green.50" borderColor="green.700" px={16} py={12}>
                                        <Stack row spacing={8} alignItems="center">
                                            <Icon color="green.700" name="check-circle" />
                                            <Span>Fee funded</Span>
                                        </Stack>
                                    </Box>
                                </Fragment>)
                                : (<Stack>
                                    <Stack row>
                                        <Span flex="1">Fee:</Span>
                                        <Span textAlign="auto">{formatCurrency(paymentTerms.currencyCode, paymentTerms.fee)} ({paymentTerms.feeRate}%)</Span>
                                    </Stack>
                                    <HR />
                                    <Stack row responsive>
                                        <Strong flex="1">Supplier will receive:</Strong>
                                        <Span textAlign="auto" fontWeight="bold">{formatCurrency(paymentTerms.currencyCode, paymentTerms.totalMinusFee)}</Span>
                                    </Stack>
                                </Stack>)
                        }
                        {
                            !!disabled && !!paymentTerms.reason && <Strong>{paymentTerms.reason}</Strong>
                        }
                    </Stack>
                </Stack>
            }
        </Box>
    )
}

export default PaymentOption