export const convertStatusState = (state) => {
    switch(state){
        case 1: return "success"
        case 2: return "warning"
        case 3: return "error"
        default: return "info"
    }
}

export const convertStatus = (state) => {
    switch(state){
        case 1: return "success"
        case 2: return "warning"
        case 3: return "error"
        default: return "info"
    }
}

export const getBadge = (status) => {
    if (status == "Pending" || status == "Warning" || status == "Processing") {
        return "info";
    }
    else if (status == "Success") {
        return "success";
    }
    else if (status == "Failed") {
        return "error";
    }
    else  {
        return "info";
    }
}

export const convertVerificationStatus = (status) => {
    switch(status){
        case 1: return "Pending"
        case 2: return "Success"
        case 3: return "Failed"
        case 4: return "Warning"
        case 5: return "Processing"
        default: return ""
    }
}