import React, { useState, useEffect, Fragment } from 'react'
import { useRequest } from 'components/use-request'
import {
    Field,
    Span,
    Stack,
} from 'paid-ui-lib'

import ConfirmModal from 'components/ConfirmModal'

const CopyComplianceProfileModal = ({ organisationId, verificationId, organisation, isOpen, onClose }) => {
    const [verificationData, setVerificationData] = useState(null)
    const [dataError, setDataError] = useState(false)
    const { getRequest, postRequest } = useRequest()

    const filterOutKeys = [
        "id", "status", "title", "createdByUserId", "createdOn"
    ]

    const createComplianceRecord = (o, v) => {
        return postRequest(`organisations/${o}/verifications/${v}/create`, {})
            .then(() => onClose(true))
            .catch(() => setDataError(true))
    }

    const getVerificationData = (o, v) => {
        if (!!o && !!v) {
            getRequest(`organisations/${o}/verifications/${v}`)
                .then(d => setVerificationData({ type: !!organisation && !!organisation.companyNumber ? "Company" : "Sole Trader", ...d }))
                .catch(() => setDataError(true))
        }
    }

    useEffect(() => {
        !!isOpen && !verificationData && getVerificationData(organisationId, verificationId);
    }, [isOpen])

    return <ConfirmModal
        isOpen={isOpen}
        buttonText="Create"
        buttonVariant="primary"
        message={<Fragment>
            {
                !!verificationData && <Stack>
                    <Span>{`You are about to create a new compliance profile using the details below.`}</Span>
                    <Stack maxHeight="400px" overflowY="scroll">
                        {
                            Object.keys(verificationData)
                                .filter(k => !filterOutKeys.includes(k) && verificationData[k] !== null)
                                .map(k => <Field row key={k} label={k} value={`${verificationData[k]}`} />)
                        }
                    </Stack>
                </Stack>
            }
        </Fragment>}
        onConfirm={() => createComplianceRecord(organisationId, verificationId)}
        onClose={() => onClose(false)} />
}

export default CopyComplianceProfileModal 