import React from 'react'
import {
    ButtonStack,
    Button,
    Icon,
    P,
    Box,
    HeaderSection,
    Table
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"

const Approvers = ({work, getData, setData, refreshWork, changeTab, ...rest}) => {
    const approverHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Creation", "accessor": "create" },
        { "header": "Amendments", "accessor": "amend" },
        { "header": "Completion", "accessor": "complete" }
    ]

    const approverTransform = (data) => {
        return {
            id: data.id,
            name: data.name,
            create: <Icon size={20} color={data.create ? "green.400" : "grey.400"} name={data.create ? "check-circle" : "circle"} />,
            amend: <Icon size={20} color={data.amend ? "green.400" : "grey.400"} name={data.create ? "check-circle" : "circle"} />,
            complete: <Icon size={20} color={data.complete ? "green.400" : "grey.400"} name={data.create ? "check-circle" : "circle"} />
        }
    }

    return (
        <HeaderSection title="Approvers" actions={
                <ButtonStack row ml="auto">
                    {(work.requiredApprover && work.actions.canChangeApprover) && <Button isSmall leftIcon="edit" onClick={() => setAddApproverModalOpen(true)} variant="secondary">Update Approver</Button>}
                </ButtonStack>                
            } {...rest}>
                {
                    !!work.approvers.length
                    ? <Table columns={approverHeadings} data={work.approvers} transform={approverTransform} noData={<NoDataScreen />}/>
                    : (
                        <Box>
                            <P>No Approvers Appointed</P>
                        </Box>
                    )
                }
        </HeaderSection>        
    )
}

export default Approvers