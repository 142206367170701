import React, { Fragment } from 'react'

import { isMobile as isMob, isTablet as isTab } from 'react-device-detect';

export const isMobile = () => {
    return !!isMob || !!isTab;
    // const media = useMedia()
    // return media !== "desk" && media !== "lap";
}

const MobileView = ({ children }) => {
    //const media = useMedia()
    // return <PseudoElement display={["block", "none", "none"]}>{children}</PseudoElement>;
    //return (media !== "desk" && media !== "lap") && !!children ? (<Fragment>{children}</Fragment>) : <Fragment></Fragment>;
    return !!isMobile() && !!children ? (<Fragment>{children}</Fragment>) : <Fragment></Fragment>;
}

export default MobileView