import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    formatDateTime,
    Link,
    Panel,
    Table,
} from 'paid-ui-lib'

import NoDataScreen from 'components/NoDataScreen'
import BankVerificationBlade from './components/BankVerificationBlade'

const BankVerificationPanel = ({ organisationId }) => {
    const [bankVerifications, setBankVerifications] = useState([])
    const [selectedBank, setSelectedBank] = useState(undefined)
    const { getRequest } = useRequest()

    const getBanks = () => {
        return getRequest(`organisations/${organisationId}/bank`)
            .then((res) => setBankVerifications(res))
    }

    useEffect(() => {
        getBanks();
    }, [])

    const refresh = (reload) => {
        setSelectedBank(null);
        !!reload && getBanks();
    }

    const headings = [
        { "header": "Account Name", "accessor": "accountName" },
        { "header": "Account Number", "accessor": "accountNumber" },
        { "header": "Sort Code", "accessor": "sortCode" },
        { "header": "Reference", "accessor": "reference" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Processed On", "accessor": "processedOn" },
        { "header": "Verified On", "accessor": "verifiedOn" }
    ]

    const transform = (data) => {
        return {
            accountName: <Link color="blue.500" onClick={() => setSelectedBank(data)}>{data.accountName}</Link>,
            accountNumber: data.accountNumber,
            sortCode: data.sortCode,
            reference: data.reference,
            createdOn: formatDateTime(data.createdOn),
            processedOn: formatDateTime(data.processedOn),
            verifiedOn: formatDateTime(data.verifiedOn),
            link: "",
        }
    }

    return (
        <Panel>
            <Table columns={headings} data={bankVerifications} transform={transform} noData={<NoDataScreen />} />
            {!!selectedBank && <BankVerificationBlade organisationId={organisationId} bank={selectedBank} isOpen={!!selectedBank} onClose={refresh} />}
        </Panel>
    )
}

export default BankVerificationPanel