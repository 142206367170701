import React, { useState} from 'react'
import {
    ButtonStack,
    Flex,
    Input,
    PseudoElement,
    Button,
    Stack,
    Box,
    InputGroupIcon,
    InputGroup,
    DatePicker
} from 'paid-ui-lib'

const DateTimeEditor = ({id, onClose, onSave, date, time, setDate, setTime, loading}) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    
    return ( 
        <Box backgroundColor="grey.200">
            <Stack spacing={16}>
                    <Flex>
                        <PseudoElement>
                            <InputGroup isSmall={false} isLeft={false}>
                                <Input placeholder={date.toLocaleDateString('en-GB')} flex="1"/>
                                <InputGroupIcon icon="calendar" onClick={() => setCalendarVisible(true)}/>
                            </InputGroup>
                        </PseudoElement>
                    </Flex>                    
                    {calendarVisible && <DatePicker id={id} startDateIn={date} inline onChange={e => { setDate(e.date); setCalendarVisible(false)}} />}                        
                    <Flex>
                        <Input  type="time" flex="1" value={time} onChange={(e) => setTime(e.target.value)}/>
                    </Flex>
                <ButtonStack row responsiveReverse>
                    <Button variant="secondary" onClick={onClose} isDisabled={loading}>Cancel</Button>
                    <Button type="submit" onClick={onSave} isLoading={loading}>Save</Button>
                </ButtonStack>
            </Stack>
        </Box>         
    )
}

export default DateTimeEditor