import React from 'react'
import {
    Span,
    Sub,
    A,
    Flex,
    PseudoElement
} from 'paid-ui-lib'

const SurveyInfoModalAnswer = ({ answerPart, ...rest }) => {
    return (
        <PseudoElement {...rest}>
            <Flex flexDirection="column" ml={!!answerPart.description ? "4" : undefined}>
                {answerPart.description && <Sub fontStyle="italic" mb={4}>{answerPart.description}</Sub>}
                {
                    answerPart.constraints.type === "File"
                        ? (
                            !!answerPart.answer && answerPart.answer.length > 0
                                ? answerPart.answer.map(f => !!f.url && <A href={f.url} target="_blank" fontSize="sm">[{f.filename}]</A>)
                                : <Sub minHeight="1rem">{"No Upload"}</Sub>
                        )
                        : (
                            <Span minHeight="1rem">{(answerPart.answer && answerPart.answer.value) || (!!answerPart.description ? "N/A" : "-")}</Span>
                        )
                }
            </Flex>
        </PseudoElement>
    )
}

export default SurveyInfoModalAnswer