import React, { useState, useEffect } from 'react'
import DeliverableEditor from '../../../../../../components/DeliverableEditor'
import {
    Stack,
    HeaderSection,
    Field
} from 'paid-ui-lib'

const DeliverablesSection = ({ work, refreshWork, ...rest }) => {
    const [deliverables, setDeliverables] = useState(work.deliverables)
    const [deliverableState, setDeliverableState] = useState(null)

    useEffect(() => {
        setDeliverables(work.deliverables)
        setDeliverableState(null)
    }, [work])

    const calculatePendingChanges = () => {
        return [
            ...work.pendingAmendment.addedDeliverables.map(d => { return { ...d, added: true } }),
            ...work.pendingAmendment.editedDeliverables.map(d => { return { ...d, edited: true } }),
            ...work.pendingAmendment.removedDeliverables.map(d => { return { ...d, removed: true } })
        ]
    }

    return (
        <HeaderSection title="Work Deliverables" {...rest}>
            <Stack>
                <DeliverableEditor
                    deliverables={deliverables}
                    milestones={work.milestones}
                    currencySymbol={work.amountBreakdown.currencySymbol}
                    onChange={()=>{}}
                    trackedDeliverableChanges={deliverableState}
                    onTrackedDeliverableChangesChanged={setDeliverableState}
                    allowFreeText={false}
                    readonly={true}
                />
                {
                    !!work.pendingAmendment &&
                    <Field label="Proposed Changes">
                        <DeliverableEditor
                            milestones={work.milestones}
                            deliverables={calculatePendingChanges()}
                            currencySymbol={work.amountBreakdown.currencySymbol}
                            allowFreeText={false}
                            readonly={true} />
                    </Field>
                }
            </Stack>
        </HeaderSection>
    )
}

export default DeliverablesSection