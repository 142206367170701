import React from 'react'
import {
    Box,
    Stack,
    HeaderSection,
    UploadedFile,
    Field,
    PseudoElement
} from 'paid-ui-lib'
import DeliverablesSection from './components/DeliverablesSection'
import PurchaseOrderSection from './components/PurchaseOrderSection'

const DeliverablesTab = ({ work, refreshWork, ...rest }) => {
    return (
        <Stack>
            <HeaderSection
                title="Deliverable Information"
                {...rest}
                >
                <Stack>
                    <Box>
                        <Stack>
                            <Stack flex="1" row>
                                <Field flex="1" label="Quote">
                                    {work.quote && <UploadedFile {...work.quote} />}
                                </Field>
                                <Field flex="1" label="Invoice">
                                    {work.invoice && <UploadedFile {...work.invoice} />}
                                </Field>
                            </Stack>
                            {
                                !work.purchaseOrder &&
                                <Stack flex="1">
                                    <Field label="Supplier Can Edit" value={work.supplierCanEdit ? "Yes" : "No"} />
                                    <PseudoElement flex="1" />
                                </Stack>
                            }
                        </Stack>
                    </Box>
                </Stack>
            </HeaderSection>
            {
                !!work.purchaseOrderRequired
                    ?
                    (
                        <Stack>
                            <DeliverablesSection work={work} refreshWork={refreshWork} />
                            <PurchaseOrderSection work={work} refreshWork={refreshWork} />
                        </Stack>
                    )
                    :
                    (
                        !!work.purchaseOrder
                            ? <PurchaseOrderSection work={work} refreshWork={refreshWork} />
                            : <DeliverablesSection work={work} refreshWork={refreshWork} />
                    )
            }
        </Stack>
    )
}

export default DeliverablesTab