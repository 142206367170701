import React, { useEffect, useState } from 'react'
import {
    useToast,
    renderTemplate,
    PageContent,
    PageTitle,
    PseudoElement,
    Stack,
    TabPanel,
    Tab,
    ButtonStack,
    Button,
    Icon,
    Sub,
    Flex,
    A,
    Box,
    Field,
    Input,
    InputCheckbox,
    Table,
    Span,
    Select,
    NoDataScreen,
    Badge,
    Tooltip,
    Link,
    Img,
    TagsInput,
    useRequest
} from 'paid-ui-lib'
import CodeEditor from '../../components/CodeEditor'
import LoadingScreen from 'components/LoadingScreen'
import ConfirmModal from 'components/ConfirmModal'
import { marked } from 'marked'
import { formatDateTime } from 'helpers/date-helper'

const availableLanguages = [
    { tag: "html", label: "HTML" },
    { tag: "markdown", label: "Markdown" }
]

const buildObjectPath = (obj, path, value="") => {
    let current = obj
    let parts = path.split(".")
    for (let i = 0; i < parts.length; i++) {
        const part = parts[i]

        if (!(part in current)) {
            current[part] = i == parts.length - 1 ? value : {}
        }

        current = current[part]
    }
}

const generatePlaceholder = (body, defaultValue="") => {
    const output = {}

    if (!!body) {
        const regex = /{{\s*([\w.]+)\s*}}/g
        const contentTags = [...body.matchAll(regex)]

        for (const tag of contentTags) {
            buildObjectPath(output, tag[1], defaultValue)
        }

        // generate a single array element for any loops
        const loopRegex = /{%\s*for\s*(\w+)\s*in\s*([\w.]+)\s*%}/g
        const loopTags = [...body.matchAll(loopRegex)]

        for (const loopTag of loopTags) {
            const key = loopTag[1]
            const obj = output[key]
            delete output[key]

            buildObjectPath(output, loopTag[2], [obj])        
        }
    }

    return output
}

const EditView = ({ match }) => {
    const toast = useToast()
    const { getRequest, postRequest } = useRequest()
    const { templateId } = match.params

    const [templateInfo, setTemplateInfo] = useState(null)
    const [templateData, setTemplateData] = useState(null)
    const [placeholderData, setPlaceholderData] = useState(null)
    const [editorData, setEditorData] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [awaitingValidation, setAwaitingValidation] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [enterprises, setEnterprises] = useState(false)

    const enterprisesById = !!enterprises ? enterprises.reduce((o, e) => Object.assign(o, {[e.id]: e}), {}) : null

    useEffect(() => {
        if (!templateId) {
            return
        }

        getRequest(`email-templates/${templateId}`)
        .then(res => {
            const obj = JSON.parse(res.placeholderValues || "{}")

            const templateInfo = res.info || {}
            if (!templateInfo.templateLanguage) {
                templateInfo.templateLanguage = "html"
            }

            setTemplateInfo(templateInfo)

            setEditorData(templateInfo.editorOptions || {})
            delete templateInfo.editorOptions

            setPlaceholderData(JSON.stringify(obj))

            setTemplateData(res.body || "")

            setLoaded(true)
        })

        getRequest("enterprise")
        .then(setEnterprises)
    }, [templateId])

    const onSubmitClicked = () => {
        setSubmitting(true)

        let placeholder = {
            ...JSON.parse(placeholderData),
            "_editor": {
                ...editorData
            }
        }

        const template = templateData      

        postRequest(`email-templates/${templateId}`, {
            body: template,
            placeholderValues: JSON.stringify(placeholder),
            info: {
                categories: templateInfo.categories,
                templateLanguage: templateInfo.templateLanguage,
                editorOptions: {...editorData},
                tags: [...(templateInfo.tags || [])],
                subject: templateInfo.subject,
                canBeMerged: templateInfo.canBeMerged,
                mergeExpiryMinutes: templateInfo.mergeExpiryMinutes
            }
        })
        .then(() => {
            toast({
                slim: true,
                position: "top-right",
                title: "Template Updated",
                description: `The template has been updated`,
                status: "success",
                isClosable: true
              })

            setTemplateInfo({...templateInfo, revision: (templateInfo.revision || 0) + 1})
        })
        .catch(err => console.error(err))
        .finally(() => setSubmitting(false))
    }

    const onSendTestEmailClicked = async () => {
        setSubmitting(true)

        const placeholderObj = {
            ...JSON.parse(placeholderData),
            "_editor": editorData
        }

        const template = templateData

        try {
            await postRequest(`email-templates/test`, {
                body: template,
                placeholderValues: JSON.stringify(placeholderObj),
                templateLanguage: templateInfo.templateLanguage,
                isEnterprise: templateInfo.isEnterprise,
                editorOptions: {...editorData}
            })
            
            toast({
                slim: true,
                position: "top-right",
                title: "Email sent",
                description: `A test email has been sent`,
                status: "success",
                isClosable: true
            })
        } catch (err) {
            console.error(err)
        }

        setSubmitting(false)
    }

    let placeholderObj = {}
    try {
        placeholderObj = JSON.parse(placeholderData)
    } catch (err) {}

    const actionDataEnabled = !!editorData && !!editorData.useDefaultHeaderFooter && !!editorData.actionData

    const overrideBadge = (!!templateInfo && templateInfo.hasOverride && !!enterprisesById) ? (
        <>
            <Badge state="warning" height={24} position="relative" top="-0.25rem">Override</Badge>
            {!!(enterprisesById[templateInfo.overrideId].logo) ? (
                <Img src={enterprisesById[templateInfo.overrideId].logo} height="1rem" ml={8} title={enterprisesById[templateInfo.overrideId].name} />
            ) : (
                <Span>{enterprisesById[templateInfo.overrideId].name}</Span>
            )}
        </>
    ) : null

    return (
        <LoadingScreen 
            hasData={loaded}
            render={() => (
                <Flex flexDirection="column" height="100vh">
                    <PageTitle title={<>Email Template: {templateInfo.name} {overrideBadge}</>} description={<Span>Email templates use the Liquid template language, <A href="https://shopify.github.io/liquid/">view the documentation</A> for more information</Span>}>
                        <ButtonStack row>
                            {!templateInfo.isSnippet && <Button variant="destructive" onClick={() => setConfirmModalOpen(true)} isLoading={submitting} disabled={awaitingValidation}>Test Email</Button>}
                            <Button success type="submit" onClick={onSubmitClicked} isLoading={submitting} disabled={awaitingValidation}>Update Template</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent flex="1">
                        <ConfirmModal
                            isOpen={confirmModalOpen}
                            title="Send Test Email"
                            buttonText="Send"
                            message={`This will send an email to the address attached to your account using this template and the data entered into the Placeholder Values section`}
                            submitVariant="destructive"
                            onConfirm={onSendTestEmailClicked}
                            onClose={() => setConfirmModalOpen(false)} />

                        <Stack height="100%">
                            <Stack responsive row maxWidth="100%" flexWrap="wrap" height="100%" style={{gap: "1rem"}}>              
                                <Box flex="1" minWidth="50rem" height="100%" maxHeight="100vh">
                                    <CodeEditor
                                        language={templateInfo.templateLanguage}
                                        value={templateData || ""}
                                        onChange={setTemplateData}
                                        onAwaitingValidation={setAwaitingValidation}
                                        hoverEnabled={false}
                                        minimap={false}
                                    />
                                </Box>
                                <TabPanel flex="1" height="100%" minWidth="30rem">
                                    <Tab containerProps={{height: "100%"}} component={(
                                        <DynamicTemplatePreview
                                            content={templateData} 
                                            placeholderData={placeholderObj} 
                                            prepend={editorData.useDefaultHeaderFooter ? `{% render '${(templateInfo.isEnterprise ? "ent-" : "")}header', LogoUrl: LogoUrl, Enterprise: Enterprise, Title: Title %}` : ""}
                                            append={editorData.useDefaultHeaderFooter ? "{% render 'footer' %}" : ""}
                                            templateLanguage={templateInfo.templateLanguage}
                                        />
                                    )}>Preview</Tab>
                                    <Tab component={(
                                        <Stack spacing={8}>
                                            <Sub lightText>Create a placeholder data model as a JSON object to have values inserted into the template in the Preview</Sub>
                                            <Box height="100%">
                                                <PlaceholderDataEditor
                                                    content={templateData}
                                                    placeholderData={placeholderData}
                                                    onChange={setPlaceholderData}
                                                />
                                            </Box>
                                        </Stack>
                                    )}>Placeholder values</Tab>
                                    {!templateInfo.isSnippet ? (
                                        <Tab component={(
                                            <Stack>
                                                <Field label="Template language">
                                                    <Select value={templateInfo.templateLanguage} onChange={e => setTemplateInfo({...templateInfo, templateLanguage: e.target.value})}>
                                                        {availableLanguages.map(l => (
                                                            <option value={l.tag}>{l.label}</option>
                                                        ))}
                                                    </Select>
                                                </Field>
                                                <Field helpMessage={`Wraps your template in {% render '${(templateInfo.isEnterprise ? "ent-" : "")}header' %} and {% render 'footer' %} tags`}>
                                                    <InputCheckbox onChange={e => setEditorData({...editorData, useDefaultHeaderFooter: e.target.checked})} checked={editorData.useDefaultHeaderFooter}>Use default header and footer templates</InputCheckbox>
                                                </Field>
                                                <Field label={`Action data${(!editorData.useDefaultHeaderFooter ? " (requires default header/footer to be enabled)" : "")}`} helpMessage={`Includes a Schema.org action tag in the template. These inputs accept Liquid template syntax`}>
                                                    <InputCheckbox mb={8} disabled={!editorData.useDefaultHeaderFooter} onChange={e => setEditorData({...editorData, actionData: e.target.checked ? {} : null})} checked={actionDataEnabled}>Enable action data</InputCheckbox>
                                                    <Box opacity={!!actionDataEnabled ? 1 : 0.3}>
                                                        <Field label="Target URL">
                                                            <Input disabled={!actionDataEnabled} value={!!editorData.actionData ? editorData.actionData.target : undefined} onChange={e => setEditorData({...editorData, actionData: {...(editorData.actionData || {}), target: e.target.value}})} />
                                                        </Field>
                                                        <Field label="Name">
                                                            <Input disabled={!actionDataEnabled} value={!!editorData.actionData ? editorData.actionData.name : undefined} onChange={e => setEditorData({...editorData, actionData: {...(editorData.actionData || {}), name: e.target.value}})} />
                                                        </Field>
                                                        <Field label="Description">
                                                            <Input disabled={!actionDataEnabled} value={!!editorData.actionData ? editorData.actionData.description : undefined} onChange={e => setEditorData({...editorData, actionData: {...(editorData.actionData || {}), description: e.target.value}})} />
                                                        </Field>
                                                    </Box>
                                                </Field>
                                                <Field label="Category" helpMessage="The SendGrid category this email will be associated with, defaults to the template name">
                                                    <Input placeholder={templateInfo.name} value={!!templateInfo.categories ? templateInfo.categories[0] : undefined} onChange={e => setTemplateInfo({...templateInfo, categories: [e.target.value]})} />
                                                </Field>
                                                <Field label="Tags" helpMessage="Tags are used to group templates so that only relevant templates show up in dropdowns">
                                                    <TagsInput value={templateInfo.tags || []} onChange={v => setTemplateInfo({...templateInfo, tags: v})} />
                                                </Field>
                                                <Field label="Subject" helpMessage="The subject used for emails with this template">
                                                    <Input placeholder={templateInfo.subject} value={templateInfo.subject} onChange={e => setTemplateInfo({...templateInfo, subject: e.target.value})} />
                                                </Field>
                                                <Field label="Merging" helpMessage="Options for merging emails">
                                                    <InputCheckbox mb={8} onChange={e => setTemplateInfo({...templateInfo, canBeMerged: e.target.checked})} checked={!!templateInfo.canBeMerged}>Can be merged</InputCheckbox>
                                                    <Box opacity={!!templateInfo.canBeMerged ? 1 : 0.3}>
                                                        <Field label="Merge Expiry Time (Minutes)" helpMessage="The duration (in minutes) to stall sending an email while waiting for other emails to merge">
                                                            <Input disabled={!templateInfo.canBeMerged} value={!!templateInfo.canBeMerged ? templateInfo.mergeExpiryMinutes : undefined} onChange={e => setTemplateInfo({...templateInfo, mergeExpiryMinutes: parseInt(e.target.value)})} />
                                                        </Field>
                                                    </Box>
                                                </Field>
                                            </Stack>
                                        )}>Options</Tab>
                                    ) : null}
                                    {!templateInfo.isSnippet ? (
                                        <Tab containerProps={{height: "100%"}} component={(
                                            !!templateInfo && <TemplateRevisionsEditor templateInfo={templateInfo} />
                                        )}>Revisions</Tab>
                                    ) : null}
                                </TabPanel>                            
                            </Stack>    
                            
                        </Stack>
                    </PageContent>
                </Flex>                
            )}
        />
    )
}

const DynamicTemplatePreview = ({ content, placeholderData, prepend, append, templateLanguage }) => {
    const [rendered, setRendered] = useState(null)
    const [snippets, setSnippets] = useState({})
    const [error, setError] = useState(null)

    const { getRequest } = useRequest()

    const onGetSnippet = async (snippetName) => {
        // todo can include override ID in querystring (if we allow overriding snippets)

        if (snippetName in snippets) {
            return snippets[snippetName]
        }

        try {
            const res = await getRequest(`email-templates/${snippetName}/by-name`)
            if (res.body === null) {
                setError(`Snippet '${snippetName}' not found`)
                return `[MISSING SNIPPET '${snippetName}']`
            }

            setSnippets({
                ...snippets,
                [snippetName]: res.body
            })

            return res.body
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // taken from: https://stackoverflow.com/a/34749873
    const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item))

    const mergeObjects = (target, ...sources) => {
        if (!sources.length) return target
        const source = sources.shift()

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} })
                    mergeObjects(target[key], source[key])
                } else if (Array.isArray(source[key]) && Array.isArray(target[key]) && source[key].length > 0 && target[key].length > 0) {
                    mergeObjects(target[key][0], source[key][0])
                } else if (source[key] !== "") {
                    Object.assign(target, { [key]: source[key] })
                }
            }
        }

        return mergeObjects(target, ...sources)
    }

    const fillDefaultModelValues = (template, model) => {
        const setNestedObjectValues = (obj, path=[]) => {
            for (const key in obj) {
                if (obj[key] === "") {
                    const defaultValue = `<code style='background-color: #fad65f; border-radius: 4px; box-shadow: 0 0 0 2px #fad65f;' title='Add a placeholder value for this variable in the \'Placeholder Values\' section'>{{${(path.length > 0 ? path.join(".") + "." : "")}${key}}}</code>`
                    obj[key] = defaultValue
                    continue
                }

                if (typeof obj[key] === "object") {
                    if (Array.isArray(obj[key])) {
                        if (obj[key].length > 0) {
                            for (let i = 0; i < obj[key].length; i++) {
                                const element = obj[key][i]
                                setNestedObjectValues(element, [...path, `${key}[${i}]`])
                            }
                        }
                    } else {
                        setNestedObjectValues(obj[key], [...path, key])
                    }
                }
            }
        }
        
        const modelSkeleton = generatePlaceholder(template)
        setNestedObjectValues(modelSkeleton)

        return mergeObjects(modelSkeleton, model)
    }

    useEffect(() => { 
        let template = content         

        if (templateLanguage == "markdown") {
            template = marked.parse(template)
            // replace characters that get used by liquidjs
            const replaceCharacters = [
                [/&quot;/g, '"'],
                [/&gt;/g, '>'],
                [/&lt;/g, '<'],
                [/&lbrace;/g, '{'],
                [/&rbrace;/g, '}']
            ]

            for (const [regex, replace] of replaceCharacters) {
                template = template.replace(regex, replace)
            }
        }       

        if (!!prepend) {
            template = prepend + template
        }

        if (!!append) {
            template += append
        }

        setError(null)

        let model = !!placeholderData ? {...placeholderData} : {}

        if ("_PlaceholderHeader" in model) {
            const placeholderHeader = model["_PlaceholderHeader"]
            delete model["_PlaceholderHeader"]
            template = placeholderHeader + template
        }

        if ("_PlaceholderFooter" in model) {
            const placeholderFooter = model["_PlaceholderFooter"]
            delete model["_PlaceholderFooter"]
            template = template + placeholderFooter
        }

        model = fillDefaultModelValues(template, model)

        renderTemplate(template, model, onGetSnippet)
        .then(res => {
            let output = res
            setRendered(output)  
        }).catch(err => {
            setError(err.message)
            setRendered(content)
        })
    }, [content, placeholderData])

    return (
        <Stack height="100%">
            {!!error && (
                <Flex alignItems="center" ml={12} mt={8}>
                    <Icon size={16} color="inputs.errorText" name="alert-triangle" />
                    <Sub color="inputs.errorText" ml={8}>Template error: {error}</Sub>
                </Flex>
            )}            
            <PseudoElement width="100%" height="100%">
                <LoadingScreen
                    hasData={!!rendered}
                    render={() => <iframe title="template" srcDoc={rendered} sandbox="" width="100%" height="100%" />}
                />
            </PseudoElement>
        </Stack>
    )
}

const PlaceholderDataEditor = ({ content, placeholderData, onChange }) => {
    if (!placeholderData || placeholderData.trim() == "{}") {
        const placeholderObj = generatePlaceholder(content)
        placeholderData = JSON.stringify(placeholderObj)
    }

    return (
        <CodeEditor
            language='json'
            value={placeholderData || "{}"}
            onChange={onChange}
            height="40rem"
            // onAwaitingValidation={setAwaitingValidation}
        />
    )
}  

const TemplateRevisionsEditor = ({ templateInfo }) => {
    const toast = useToast()
    const { getRequest, postRequest } = useRequest()

    const [revisions, setRevisions] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [revisionContext, setRevisionContext] = useState(null)

    useEffect(() => {
        loadRevisions()
    }, [templateInfo.revision])

    const loadRevisions = async () => {
        const res = await getRequest(`email-templates/${templateInfo.id}/revisions`)
        const revisions = [...res]

        if (!revisions.find(r => r.revision === 0)) {
            revisions.push({ revision: 0, blockActions: true })
        }

        revisions.sort((a, b) => b.revision - a.revision)

        setRevisions(revisions)
    }

    const stageRevision = async (revision) => {
        setIsUpdating(true)

        try {
            await postRequest(`email-templates/${templateInfo.id}/revisions/${revision}/stage`)
            await loadRevisions()

            toast({
                slim: true,
                position: "top-right",
                title: "Revision Staged",
                description: `Template revision ${revision} has been staged`,
                status: "success",
                isClosable: true
            })
        } catch (err) {
            console.error(err)
        }
        
        setIsUpdating(false)
    }

    const setRevisionActive = async (revision) => {
        setIsUpdating(true)

        try {
            await postRequest(`email-templates/${templateInfo.id}/revisions/${revision}/set-active`)
            await loadRevisions()

            toast({
                slim: true,
                position: "top-right",
                title: "Revision Published",
                description: `Template revision ${revision} is now live`,
                status: "success",
                isClosable: true
            })
        } catch (err) {
            console.error(err)
        }

        setIsUpdating(false)
    }

    if (!!revisions) {
        const stagedRevision = revisions.find(r => !!r.isStaged)
        let activeRevision = revisions.find(r => !!r.isActive)
        if (activeRevision == null) {
            activeRevision = revisions.find(r => r.revision === 0)
            activeRevision.isActive = true
        }
    
        for (const revision of revisions) {        
            if (stagedRevision && activeRevision) {            
                if (revision.revision < stagedRevision.revision && revision.revision > activeRevision.revision) {
                    revision.skipped = true
                }
            }
        }
    }

    const columns = [
        { "header": "Revision", "accessor": "revisionLabel" },     
        { "header": "", "accessor": "revisionType" },     
        { "header": "Created", "accessor": "revisionCreated" },
        { "header": "Author", "accessor": "revisionAuthorName" },
        { "header": "Status", "accessor": "status", "overflow": "visible" },     
        { "header": "", "accessor": "actions", "overflow": "visible" }
    ]

    const statusDef = {
        live: { state: "success", text: "Live" },
        skipped: { state: undefined, text: "Skipped", tooltip: "This revision will be skipped because a later revision is pending" },
        pending: { state: "warning", text: "Staged", tooltip: "This revision has been staged and will be made live on publish" },
        inactive: { state: undefined, text: "Inactive" }
    }

    const transform = (data) => {
        let status = null
        if (data.isActive) { // live
            status = statusDef.live
        } else if (!!data.isStaged) {
            status = statusDef.pending
        } else if (!!data.skipped) {
            status = statusDef.skipped
        }

        return {
            ...data,
            revisionCreated: !!data.revisionCreated && formatDateTime(data.revisionCreated),
            revisionType: data.revisionType === 1 ? <Badge>Imported</Badge> : null,
            revisionLabel: data.revision === 0 ? <Span lightText fontStyle="italic">(Original)</Span> : data.revision,
            revisionAuthorName: !!data.revisionAuthor && data.revisionAuthor.name || undefined,
            status: status != null && (
                <Tooltip variant="small" text={status.tooltip}>
                    <Badge state={status.state}>{status.text}</Badge>
                </Tooltip>
            ),
            actions: (
                <ButtonStack row>
                    {!(data.isStaged || data.isActive || data.blockActions) && (
                        <>
                            <Tooltip variant="small" text="Make the revision live immediately">
                                <Link disabled={isUpdating} onClick={() => {
                                    setRevisionContext(data.revision)
                                    setConfirmModalOpen(true)
                                }}>Publish</Link>
                            </Tooltip>
                            <Span>/</Span>
                            <Tooltip variant="small" text="Add the revision to staging so that it can be made live as part of a batch">
                                <Link disabled={isUpdating} onClick={() => stageRevision(data.revision)}>Stage</Link>
                            </Tooltip>
                        </>
                    )}
                </ButtonStack>
            )
        }
    }

    return !!revisions ? (
        <>
            <ConfirmModal
                isOpen={confirmModalOpen}
                title="Publish Revision"
                buttonText="Publish"
                message={`This will publish the revision and make it live immediately`}
                submitVariant="destructive"
                onConfirm={() => setRevisionActive(revisionContext)}
                onClose={() => setConfirmModalOpen(false)} 
            />
            <Table columns={columns} data={revisions} transform={transform} noData={<NoDataScreen />} />
        </>
    ) : null
}

export default EditView