import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import I18nProvider from 'components/I18nProvider';
import ConfigManager from './components/ConfigManager/components/ConfigManager'

import {
    ThemeProvider
} from 'paid-ui-lib'

import AuthWrapper from './components/AuthWrapper'
import PageRoutes from './routes'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
    return (
        <ConfigManager>
            <GoogleOAuthProvider clientId={window._googleClientId}>
                <I18nProvider>
                    <Fragment>
                        <ThemeProvider>
                            <BrowserRouter>
                                    <AuthWrapper>
                                        <PageRoutes />                            
                                    </AuthWrapper>
                            </BrowserRouter>
                        </ThemeProvider>
                    </Fragment>
                </I18nProvider>
            </GoogleOAuthProvider>
        </ConfigManager>
    );
}

export default App
