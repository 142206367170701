import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import {
    useToast,
    Table,
    PageContent,
    PageTitle,
    Stack,
    PaginationControl,
    Input,
    Badge,
    Span,
    Img,
    ButtonStack,
    Button,
    Link,
    Flex,
    IconButton,
    PseudoElement
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"
import LoadingScreen from 'components/LoadingScreen'
import ConfirmModal from 'components/ConfirmModal'
import { useRequest } from 'components/use-request'
import { formatDateTime } from 'helpers/date-helper'

const StagingView = () => {
    const toast = useToast()
    const { getRequest, postRequest } = useRequest()
    const history = useHistory()

    const [data, setData] = useState(null)
    const [dataError, setDataError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [enterprises, setEnterprises] = useState(null)

    const enterprisesById = !!enterprises ? enterprises.reduce((o, e) => Object.assign(o, {[e.id]: e}), {}) : null

    useEffect(() => {
        getRequest("enterprise")
            .then(setEnterprises)

        loadStagedRevisions()
    }, [])

    const loadStagedRevisions = () => {
        getRequest("email-templates/staged")
            .then(res => {
                setData(res)
            })
            .catch(() => {
                setDataError(true)
            })
    }

    const columns = [
        { "header": "Name", "accessor": "name" },
        { "header": "", "accessor": "overrideInfo" },
        { "header": "Revision", "accessor": "revision" },
        { "header": "Created", "accessor": "revisionCreated" },
        { "header": "Author", "accessor": "revisionAuthorName" },
        { "header": "", "accessor": "actions" }
    ]

    const transform = (data) => {
        const enterprise = !data.hasOverride ? null : enterprisesById[data.overrideId]

        const date = !!data.revisionCreated && formatDateTime(data.revisionCreated)

        return ({
            name: <Link onClick={() => history.push(`/settings/email-templates/${data.id}`)}>{data.name}</Link>,
            overrideInfo: !!enterprise && (
                <Flex alignItems="center">
                    <Badge ml={16} state="warning">Override</Badge>
                    {!!enterprise.logo ? (
                        <Img src={enterprise.logo} height="1rem" ml={8} title={enterprise.name} />
                    ) : (
                        <Span>{enterprise.name}</Span>
                    )}
                </Flex>
            ),
            revision: data.revision,
            revisionCreated: date != "31 Dec 0 23:58" ? date : undefined,
            revisionAuthorName: !!data.revisionAuthor && data.revisionAuthor.name || undefined,
            actions: (
                <ButtonStack>
                    <PseudoElement textAlign="right">
                        <IconButton icon="trash-b" isSmall onClick={() => onUnstage(data.id, data.revision)} />
                    </PseudoElement>
                </ButtonStack>
            )
        })
    }

    const onPublish = () => {
        if (!!loading || !data || data.length === 0) {
            return
        }

        setLoading(true)
        postRequest(`email-templates/publish`)
            .then(res => {
                setLoading(false)
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Published",
                    description: `The staged revisions have been published and are now live`,
                    status: "success",
                    isClosable: true
                })
                loadStagedRevisions()
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
    }

    const onUnstage = (templateId, revision) => {
        if (!!loading || !data || data.length === 0) {
            return
        }

        setLoading(true)
        postRequest(`email-templates/${templateId}/revisions/${revision}/unstage`)
            .then(res => {
                setLoading(false)
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Unstaged",
                    description: `The revision has been unstaged successfully`,
                    status: "success",
                    isClosable: true
                })
                loadStagedRevisions()
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
    }

    return (
        <LoadingScreen
            hasData={!!data && !!enterprisesById && !loading}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle slim title="Email Templates - Staging" description="All revisions ready to be published">
                        <ButtonStack row spacing="2" width="100%">
                            <Flex height="100%" alignItems="center">
                                <Link onClick={() => history.push('/settings/email-templates')}>Back</Link>
                            </Flex>
                            <Button variant="destructive" as="a" onClick={() => setConfirmModalOpen(true)} disabled={!!loading || !data || data.length === 0}>Publish</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <ConfirmModal
                            isOpen={confirmModalOpen}
                            title="Publish"
                            buttonText="Publish"
                            message={`This will publish all of the currently staged template revisions, making them all live`}
                            submitVariant="destructive"
                            onConfirm={onPublish}
                            onClose={() => setConfirmModalOpen(false)} />
                        <LocalPaginatedTable data={data} pageSize={10} columns={columns} transform={transform} />
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

const LocalPaginatedTable = ({data, pageSize, columns, transform, filterAccessor = "name", filterEnabled = false, externalFilter = null }) => {
    const [filteredData, setFilteredData] = useState(data)
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (!filter && !externalFilter) {
            setFilteredData(data)
            return
        }

        const normalised = (filterEnabled ? filter : externalFilter).toLowerCase()
        setFilteredData(data.filter(d => d[filterAccessor].toLowerCase().includes(normalised)))
    }, [filter, externalFilter])

    const [pageData, setPageData] = useState({
        page: 1,
        pageSize
    })

    const getPage = () => {
        if (!filteredData) return null

        const start = (pageData.page - 1) * pageData.pageSize
        return filteredData.slice(start, start + pageData.pageSize)
    }

    const getCount = () => {
        if (!filteredData) return null

        const start = (pageData.page - 1) * pageData.pageSize
        return Math.min(filteredData.length - start, pageData.pageSize)
    }

    return (
        <Stack>
            {!!filterEnabled && <Input placeholder="Filter..." onChange={e => setFilter(e.target.value)} />}
            <Table columns={columns} data={getPage()} transform={transform} noData={<NoDataScreen />}/>
            <PaginationControl pageData={pageData} updatePageData={setPageData} dataCount={getCount()} totalDataCount={filteredData.length} />
        </Stack>
    )
}

export default StagingView