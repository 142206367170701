import React from 'react'
import {
    Stack
} from 'paid-ui-lib'
import Overview from './components/Overview'
import Approvers from './components/Approvers'

const DetailsTab = ({work, getData, setData, refreshWork, ...rest}) => {
    return (
        <Stack>
            <Overview work={work} getData={getData} setData={setData} refreshWork={refreshWork} {...rest}/>
            <Approvers work={work} getData={getData} setData={setData} refreshWork={refreshWork} {...rest}/>
        </Stack>
    )
}

export default DetailsTab