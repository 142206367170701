import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import {
    Table,
    PageContent,
    PageTitle,
    Stack,
    Link,
    PaginationControl,
    H4,
    Sub,
    Input,
    Badge,
    Span,
    Flex,
    Select,
    PseudoElement,
    Img,
    ButtonStack,
    Button,
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'

const ListView = () => {
    const { getRequest, postRequest } = useRequest()
    const history = useHistory()

    const [data, setData] = useState(null)
    const [dataError, setDataError] = useState(false)
    const [enterprises, setEnterprises] = useState(null)
    const [staged, setStaged] = useState(null)
    const [selectedEnterpriseId, setSelectedEnterpriseId] = useState("")
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState("")

    const enterprisesById = !!enterprises ? enterprises.reduce((o, e) => Object.assign(o, { [e.id]: e }), {}) : null

    useEffect(() => {
        getRequest("enterprise?active=true")
            .then(setEnterprises)

        getRequest("email-templates/staged")
            .then(res => {
                setStaged(res)
            })
            .catch(() => {
                setDataError(true)
            })

        getRequest("email-templates")
            .then(res => {
                const data = []
                const templateByName = {}

                for (const template of res) {
                    let templateInfo

                    if (!(template.name in templateByName)) {
                        templateInfo = {
                            ...template,
                            overrides: []
                        }

                        delete templateInfo.id

                        templateByName[template.name] = templateInfo
                        data.push(templateInfo)
                    } else {
                        templateInfo = templateByName[template.name]
                    }

                    if (template.hasOverride) {
                        templateInfo.overrides.push(template)
                        templateInfo.hasOverride = true
                    } else {
                        templateInfo.id = template.id
                    }
                }

                setData(data)
            })
            .catch(() => {
                setDataError(true)
            })
    }, [])

    const columns = [
        { "header": "Name", "accessor": "name" },
        { "header": "", "accessor": "overrideLabels" },
        { "header": "", "accessor": "actions" }
    ]

    const onOverrideClicked = (templateId, organisationId) => {
        setLoading(true)
        postRequest(`email-templates/${templateId}/override?organisationId=${organisationId}`)
            .then(res => {
                setLoading(false)
                if (!!res && !!res.id) {
                    history.push(`/settings/email-templates/${res.id}`)
                }
            })
    }

    const transform = (data) => {
        const editUrl = `/settings/email-templates/${data.id}`
        const isGlobal = selectedEnterpriseId == ""

        const currentOverride = !data.hasOverride || isGlobal ? null : data.overrides.find(o => o.overrideId == selectedEnterpriseId)
        const hasOverride = !!data.hasOverride && (isGlobal || !!currentOverride)
        const overrideLabel = !!currentOverride ? "Edit Override" : "Override"
        const overrideUrl = !!currentOverride ? `/settings/email-templates/${currentOverride.id}` : ""
        const canOverride = (!!data.isEnterprise || !!data.isSnippet) && !isGlobal

        return ({
            name: (
                <Span>
                    {data.name}
                    {hasOverride && <Badge ml={16} state="warning">Overridden</Badge>}
                    {!!data.canBeMerged && <Badge ml={16} state="success" title="This template can be used for merged notifications">Merge</Badge>}
                </Span>
            ),
            isSnippet: data.isSnippet ? "Yes" : "No",
            isEnterprise: data.isEnterprise ? "Yes" : "No",
            hasOverride: data.hasOverride ? "Overridden" : "",
            actions: (
                <Stack row justifyContent="flex-end">
                    {canOverride && <Link {...(!!currentOverride ? { action: overrideUrl } : { onClick: () => onOverrideClicked(data.id, selectedEnterpriseId) })}>{overrideLabel}</Link>}
                    {!canOverride && <Link action={editUrl}>Edit</Link>}
                </Stack>
            ),
            overrideLabels: !isGlobal ? null : (
                <Stack row>
                    {data.overrides.map(o => (
                        !!enterprisesById[o.overrideId] && (
                            !!(enterprisesById[o.overrideId].logo) ? (
                                <Img src={enterprisesById[o.overrideId].logo} height="1rem" ml={8} title={enterprisesById[o.overrideId].name} />
                            ) : (
                                <Span>{enterprisesById[o.overrideId].name}</Span>
                            )
                        )
                    ))}
                </Stack>
            )
        })
    }

    return (
        <LoadingScreen
            hasData={!!data && !!enterprises && !loading}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle slim title="Email Templates" description="Edit and override existing email templates">
                        <Flex height="100%" justifyContent="flex-end">
                            {!!staged && (staged.length > 0 ? (
                                <Span lightText>There {(staged.length === 1 ? "is" : "are")} {staged.length} revision{(staged.length === 1 ? "" : "s")} staged, go to <Link onClick={() => history.push('/settings/email-templates/staging')}>Staging</Link> to publish them</Span>
                            ) : (
                                <Span lightText>No revisions staged</Span>
                            ))}
                        </Flex>
                    </PageTitle>
                    <PageContent slim>
                        <Stack>
                            <Input placeholder="Filter..." onChange={e => setFilter(e.target.value)} />
                            <Flex row alignItems="center">
                                <Stack spacing={4}>
                                    <H4>Enterprise templates</H4>
                                    <Sub lightText>Used for enterprise-specific functionality, can be overridden per-enterprise</Sub>
                                </Stack>
                                <PseudoElement ml="auto">
                                    <Select value={selectedEnterpriseId} onChange={e => setSelectedEnterpriseId(e.target.value)}>
                                        <option value="">(Global)</option>
                                        {enterprises.map(e => (
                                            <option value={e.id}>{e.name}</option>
                                        ))}
                                    </Select>
                                </PseudoElement>
                            </Flex>
                            <LocalPaginatedTable data={data.filter(r => !r.isSnippet && !!r.isEnterprise)} pageSize={10} columns={columns} transform={transform} externalFilter={filter} />

                            <Stack spacing={4}>
                                <H4>Core templates</H4>
                                <Sub lightText>Used for Paid core functionality, shared between all enterprises</Sub>
                            </Stack>
                            <LocalPaginatedTable data={data.filter(r => !r.isSnippet && !r.isEnterprise)} pageSize={10} columns={columns} transform={transform} externalFilter={filter} />

                            <Stack spacing={4}>
                                <H4>Snippets</H4>
                                <Sub lightText>Small templates that can be included in other templates. You can override these per-enterprise, but Core Templates will always use the root snippet</Sub>
                            </Stack>
                            <LocalPaginatedTable data={data.filter(r => !!r.isSnippet)} pageSize={10} columns={columns} transform={transform} externalFilter={filter} />
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

const LocalPaginatedTable = ({ data, pageSize, columns, transform, filterAccessor = "name", filterEnabled = false, externalFilter = null }) => {
    const [filteredData, setFilteredData] = useState(data)
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (!filter && !externalFilter) {
            setFilteredData(data)
            return
        }

        const normalised = (filterEnabled ? filter : externalFilter).toLowerCase()
        setFilteredData(data.filter(d => d[filterAccessor].toLowerCase().includes(normalised)))
    }, [filter, externalFilter])

    const [pageData, setPageData] = useState({
        page: 1,
        pageSize
    })

    const getPage = () => {
        if (!filteredData) return null

        const start = (pageData.page - 1) * pageData.pageSize
        return filteredData.slice(start, start + pageData.pageSize)
    }

    const getCount = () => {
        if (!filteredData) return null

        const start = (pageData.page - 1) * pageData.pageSize
        return Math.min(filteredData.length - start, pageData.pageSize)
    }

    return (
        <Stack>
            {!!filterEnabled && <Input placeholder="Filter..." onChange={e => setFilter(e.target.value)} />}
            <Table columns={columns} data={getPage()} transform={transform} noData={<NoDataScreen />} />
            <PaginationControl pageData={pageData} updatePageData={setPageData} dataCount={getCount()} totalDataCount={filteredData.length} />
        </Stack>
    )
}

export default ListView