import React, {Fragment} from 'react'
import FormatList from 'components/FormatList'
import {
    ButtonStack,
    Stack,
    Field,
    Auth,
    Box,
    HeaderSection,
    Button,
    Link
} from 'paid-ui-lib'

const SupplierTab = ({work, ...rest}) => {
    return (
        <Fragment>
            <Stack {...rest}>
                <HeaderSection
                actions={
                    <ButtonStack row ml="auto">
                        <Auth permission="0">
                            <Button leftIcon="file-text" isSmall as="a" variant="secondary" href={work.supplierWorkLink} target="_blank">Supplier View</Button>
                        </Auth> 
                    </ButtonStack>                
                }
                title="Supplier Details">
                    <Stack>
                        <Box>
                            <Stack row>                    
                                <Stack flex="1">
                                    <Field label="Name" >
                                        <Link action={`/organisations/${work.supplier.organisationId}`}>{work.supplier.name}</Link>
                                    </Field>
                                    <Field label="Phone" value={work.supplier.phoneNumber} />
                                    <Field label="Email" value={work.supplier.email} />               
                                </Stack>
                                <Field flex="1" label="Address">
                                    { work.supplier.address && <FormatList spacing={1} items={[
                                        work.supplier.address.addressLine1,
                                        work.supplier.address.addressLine2,
                                        work.supplier.address.city,
                                        work.supplier.address.county,
                                        work.supplier.address.postCode,
                                        work.supplier.address.country,
                                    ]} />}
                                </Field>
                            </Stack>
                        </Box>
                    </Stack>
                </HeaderSection>
                <HeaderSection title="Supplier Contact Details">
                    <Box>
                        <Stack row>                    
                            <Field flex="1" label="Name" value={work.supplierUser.name} />              
                            <Field flex="1" label="Email" value={work.supplierUser.email} />
                        </Stack>
                    </Box>
                </HeaderSection>
            </Stack>
        </Fragment>
    )
}

export default SupplierTab