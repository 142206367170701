import React, { Fragment, useEffect, useState } from 'react'
import {
    P,
    Badge,
    Button,
    formatDateTime,
    HeaderSection,
    IconButton,
    Span,
    Stack,
    Table,
    useRequest,
} from 'paid-ui-lib'

import NoDataScreen from "components/NoDataScreen"
import ConfirmModal from 'components/ConfirmModal'

const FinanceTab = ({ organisation }) => {

    const { getRequest, postRequest } = useRequest()
    const [showFinanceOptions, setShowFinanceOptions] = useState(false)
    const [financeProviders, setFinanceProviders] = useState()

    const flatternMetadata = (dict) => {
        if (!dict) return <Fragment />;
        return <Stack py={8} spacing={8}>{Object.keys(dict).map(k => <Span>{`${k}: ${dict[k]}`}</Span>)}</Stack>;
    }

    const headings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Status", "accessor": "status" },
        { "header": "Metadata", "accessor": "metadata" },
        { "header": "Enable/Disable", "accessor": "actions" }
    ]

    const getFinanceProviders = (organisationId) => {
        return getRequest(`finance/organisations/${organisationId}/finance-providers`)
            .then(p => setFinanceProviders(p.providers))
            .catch(() => { });
    }

    const updateFinanceOptions = (organisationId, financeProviderId, allowFinance) => {
        return postRequest(`finance/organisations/${organisationId}/finance-providers/${financeProviderId}`, { enabled: allowFinance })
            .then(() => getFinanceProviders(organisation.id))
            .catch(() => setDataError(true))
            .finally(() => setShowFinanceOptions(null));
    }

    const transform = (provider) => {
        return {
            name: provider.name,
            status: <Badge status={provider.disabled ? 3 : 1}>{provider.disabled ? "Disabled" : "Active"}</Badge>,
            metadata: flatternMetadata(provider.metadata),
            actions: <IconButton isSmall icon={provider.disabled ? "check" : "x"} variant={provider.disabled ? "primary" : "destructive"} onClick={() => setShowFinanceOptions(provider)} />
        }
    }

    useEffect(() => {
        !!organisation && !!organisation.id && getFinanceProviders(organisation.id);
    }, [organisation])

    return (
        <Fragment>
            <ConfirmModal
                isOpen={showFinanceOptions}
                buttonText={!!showFinanceOptions && !!showFinanceOptions.disabled ? "Enable" : "Disable"}
                buttonVariant={!!showFinanceOptions && !!showFinanceOptions.disabled ? "primary" : "destructive"}
                message={`Are you sure you would like to ${!!showFinanceOptions && !!showFinanceOptions.disabled ? "enable" : "disable"} express payment options for this supplier?`}
                onConfirm={() => updateFinanceOptions(organisation.id, showFinanceOptions.providerId, !!showFinanceOptions.disabled ? true : false)}
                onClose={() => setShowFinanceOptions(null)} />
            <HeaderSection title="Providers">
                {
                    (financeProviders || []).length === 0
                        ? <P>No Finance Providers Found</P>
                        : <Table columns={headings} data={financeProviders} transform={transform} noData={<NoDataScreen />} />
                }
            </HeaderSection>
        </Fragment>
    )
}

export default FinanceTab