import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    Stack,
    TabPanel,
    Tab,
    PageTitle,
    PageContent,
    ButtonStack,
    Button,
    Auth
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import UserDetailsTab from './components/UserDetailsTab'
import SuspendAdminDrawer from './components/SuspendAdminDrawer'

const ViewAdministratorView = (props) => {
    const [user, setUser] = useState(null)
    const [roles, setRoles] = useState(null)
    const [loading, setLoading] = useState(false)
    const { getRequest, putRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [ showSuspendDrawer, setShowSuspendDrawer ] = useState(false)

    const getUser = (userId) => {
        getRoles()

        return getRequest(`administrators/${userId}`)
            .then(res => {
                setUser(res)
            })
            .catch(() => {
                setDataError(true)
            })
    }

    const enableAdminAccount = () => {
        setLoading(true)
        putRequest(`administrators/${props.match.params.administratorId}/enable`)
        .then(() => {
            toast({
                slim: true,
                position: "top-right",
                title: `Admin Suspended`,
                description: `Admin has been successfully suspended`,
                status: "success",
                isClosable: true
                })
            setLoading(false)


        })
        .catch(() => {
            setLoading(false)
        })
        .finally(() => {
            getUser(props.match.params.administratorId)
        })
    }

    const getRoles = () => {
        return getRequest(`roles`)
            .then(res => {
                setRoles(res.items)
            }) 
    }

    useEffect(() => {
        getUser(props.match.params.administratorId)
    }, [])

    return (
        <LoadingScreen
            hasData={user}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title={`${user.firstName} ${user.lastName}`} description={user.email} status={user.status} slim>
                        <ButtonStack row>
                                {/*<Button variant="secondary" onClick={() => setShowNotes(true)}>View Notes</Button>*/}
                                <Auth permission="602">
                                    {!!user.suspended && <Button variant="primary" onClick={() => enableAdminAccount()} isLoading={loading}>Enable</Button>}
                                    {!user.suspended && <Button variant="destructive" onClick={() => setShowSuspendDrawer(true)}>Suspend</Button>}
                                </Auth>
                            </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <Stack row responsive>
                            <TabPanel flex="1">
                                {<Tab icon="calendar" component={<UserDetailsTab user={user} roles={roles} onUpdate={() => getUser(user.id)} />}>Details</Tab>}
                                <Tab></Tab>
                            </TabPanel>
                        </Stack>
                    </PageContent>
                    <SuspendAdminDrawer isOpen={showSuspendDrawer} userId={props.match.params.administratorId} onClose={() => {setShowSuspendDrawer(false); getUser(props.match.params.administratorId)}} />
                </Fragment>
            )}
        />
    )
}

export default ViewAdministratorView