import React, { useEffect, useState, forwardRef } from 'react'
import {
    Stack,
} from 'paid-ui-lib'
import Editor, { loader } from "@monaco-editor/react";
import ValidationFooter from './components/ValidationFooter'

// Webpack copies monaco plugin files to avoid using third party cdn for @monaco-editor/react plugin.
// Alternatively { vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.30.1/min/vs" } can be used.
loader.config({
    paths: {
        vs: "/vs"
    }
});

const invalidStateVal = 'invalid-code-editor-val'

/*
    - onChange(data) - Only used to return editor input, this then sends an event that triggers onValidate
                     - Validation event is not triggered when syntax is valid e.g. changing number from 1 to 2
    - onAwaitingValidation - Boolean indicating whether editor is awaiting validation from onValidate

    Note: If you simply use onChange without disabling your form submit using onAwaitingValidation, 
    the user is able to quickly change data within the editor and that won't be picked up as invalid. 
    This is because the onChange is fired first which sends an event that triggers onValidate, hence you need to wait
    for validation to complete before submitting.
*/
const CodeEditor = forwardRef(
    (
        {
            value,
            language = 'json',
            height = "100%",
            disabled = false,
            minimap = true,
            lineNumbers = true,
            showValidation = true,
            hoverEnabled = true,
            onChange,
            onAwaitingValidation,
            ...rest
        },
        ref,
    ) => {
        const [init, setInit] = useState(false)
        const [code, setCode] = useState()
        const [pendingValidation, setPendingValidation] = useState()
        const [showSuccessValidationMsg, setShowSuccessValidationMsg] = useState()
        const [isValid, setIsValid] = useState(true)
        const validationSpinnerDuration = 2000
        const successValidationMsgDuration = validationSpinnerDuration + 2000
        const editorOptions = {
            readOnly: disabled,
            minimap: { enabled: minimap },
            lineNumbers: lineNumbers,
            hover: { enabled: hoverEnabled }
        }

        const isStringified = (value) => {
            try {
                return JSON.parse(value);
            }
            catch (err) {
                return value
            }
        }

        const initEditor = () => {
            setPendingValidation(true)

            switch (language) {
                case 'json':
                    setCode(JSON.stringify(isStringified(value), null, 2))
                    break;
                case 'html':
                    setCode(value)
                    break;
                default:
                    setCode(value)
                    break;
            }

            setInit(true)
        }

        const validateMarkers = (markers) => {
            let isValid = true
            let errorMsgs = ''

            if (Array.isArray(markers) && markers.length > 0) {
                isValid = false
                let errors = markers.map(marker => marker.message).filter((value, index, self) => self.indexOf(value) === index)
                errorMsgs = Array.isArray(errors) ? errors.join('. ') : null
            }

            return { isValid, errorMsgs }
        }

        const onValidateHandler = (markers) => {
            var validationResult = validateMarkers(markers)

            // Pass back invalidStateVal so that form validation can pick up invalid data.
            // But, the code editor maintains the input value
            if (!!onChange && !validationResult.isValid) {
                onChange(invalidStateVal)
            }

            setIsValid(validationResult.isValid)
            setPendingValidation(false) //Clears spinner
        }

        const onChangeHandler = (data) => {
            // Skip invalid state and same string value
            if (data !== invalidStateVal && data !== code) {
                setCode(data)
                setPendingValidation(true)
                !!onChange && onChange(data)
            }
        }

        useEffect(() => {
            initEditor()
        }, [])

        useEffect(() => {
            if (!init) return
            else onChangeHandler(value)
        }, [value])

        // Validation spinner
        useEffect(() => {
            let timer = null

            if (!!onAwaitingValidation) {
                onAwaitingValidation(pendingValidation)
            }
            setShowSuccessValidationMsg(true)    //resets success msg opacity state

            if (pendingValidation) {
                timer = setTimeout(() => {
                    setPendingValidation(false)
                }, validationSpinnerDuration)
            }

            return function cleanup() {
                if (!!timer) clearTimeout(timer)
            }
        }, [pendingValidation])

        // Transition for success validation msg
        useEffect(() => {
            let timer = null

            if (showSuccessValidationMsg) {
                timer = setTimeout(() => {
                    setShowSuccessValidationMsg(false)
                }, successValidationMsgDuration)
            }

            return function cleanup() {
                if (!!timer) clearTimeout(timer)
            }
        }, [showSuccessValidationMsg])

        return (
            <Stack minHeight={height}>
                <Editor
                    ref={ref}
                    height={height}
                    language={language}
                    defaultValue=""
                    value={code}
                    options={editorOptions}
                    onValidate={onValidateHandler}
                    onChange={onChangeHandler}
                    {...rest}
                />
                {
                    showValidation &&
                    <ValidationFooter
                        pendingValidation={pendingValidation}
                        isValid={isValid}
                        showSuccessMsg={showSuccessValidationMsg}
                    />
                }
            </Stack>
        )
    }
)

export default CodeEditor
export { invalidStateVal }