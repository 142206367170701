import React, { Fragment } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    MessageStack,
    Flex,
    Message,
    H1,
    AvatarStack,
    Avatar,
    Span
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'

const MessageViewer = ({thread, hasThread, onClose, isOpen}) => {  
    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="lg"
            >
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader noWrap>
                    <Flex flexDirection="column">
                        <Flex flex="3 1" alignItems="center">
                            <Flex flexDirection="column">
                                <H1>Messages</H1>
                            </Flex>                                
                        </Flex>
                        <Flex flex="3 1" alignItems="center" mt={16}>
                            <Flex ml="auto" alignItems="center">
                            {
                                !!thread &&
                                <Fragment>
                                    <Span lightText mr={2}>{thread.participants.length} participants</Span>
                                    <AvatarStack borderColor="white.100" offset="-10px">
                                        {thread.participants.map((p, i) => <Avatar key={i} {...p} fontSize={32} />)}
                                    </AvatarStack>
                                </Fragment>
                            }
                            </Flex>
                        </Flex>
                    </Flex>
                </DrawerHeader>
                <DrawerBody overflowY="scroll">
                <LoadingScreen
                    hasData={!!thread || !hasThread}
                    hasError={false}
                    render={() => (
                        <MessageStack mb={4}>
                            {
                                (thread || {messages: []}).messages.map(m => 
                                    <Message
                                        key={`message-${m.id}`}
                                        name={m.participant.name || ""}
                                        datetime={m.createdOn}
                                        viewedBy={m.viewedBy}
                                        messageType={m.messageType}
                                        displayAsOrganisation={m.displayAsOrganisation}
                                    >
                                        {m.message}
                                    </Message>
                            )}
                        </MessageStack>                        
                    )}/>
                </DrawerBody>
            </DrawerContent>
        </Drawer>  
    )
}

export default MessageViewer