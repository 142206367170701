import React, { useState, useEffect, Fragment } from 'react'
import { useRequest } from 'components/use-request'
import {
    Stack,
    Field,
    PageContent,
    Box,
    PageTitle,
    HeaderSection
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import TFAOptions from './components/tfa-options'

const ViewAccountView = (props) => {
    const { getRequest } = useRequest()
    const [user, setUser] = useState(null)
    const [dataError, setDataError] = useState(false)

    const getUserAccount = () => {
        getRequest(`auth/account`)
        .then(res => {
            setUser(res)
        })
        .catch(() => {
            setDataError(true)
        })    
    }

    useEffect(() => {  
        getUserAccount()
    }, [])

    const onClose = (shouldRefresh) => {
        !!shouldRefresh && !!onRefresh && props.onRefresh()
    }
    
    return (
        <LoadingScreen
            hasData={user}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle slim
                        title="Manage Account"
                        description="Review your account information."
                        status={user.status} />
                    <PageContent slim>
                        <Stack>
                            <HeaderSection title="Account Overview">
                                <Stack row responsive>
                                    <Box flex="1">
                                        <Stack>
                                            <Stack row>
                                                <Field flex="1" label="First Name" value={user.firstName} />
                                                <Field flex="1" label="Last Name" value={user.lastName} />
                                            </Stack>
                                            <Stack row>
                                                <Field flex="1" label="Email" value={user.email} />
                                                <Field flex="1" label="Job Title" value={user.jobTitle} />
                                            </Stack>
                                            <Stack row>
                                                <Field flex="1" label="Role" value={user.role && user.role.name} />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Stack>                    
                            </HeaderSection>
                            
                            <TFAOptions onClose={onClose} user={user} />
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ViewAccountView