import React from 'react'
import {
    Flex,
    P,
    Stack,
    H2,
    Icon
} from 'paid-ui-lib'

const NoDataScreen = ({...rest}) => (
    <Flex height={["50vh","80%","80%"]} {...rest}>
        <Stack m="auto" spacing={8} alignItems="center">
            <Icon name="search" size={52} color="blue.600"/>
            <H2>Data Not Found</H2>
            <P textAlign="center">Items will appear here when created.</P>                
        </Stack>
    </Flex>
)

export default NoDataScreen