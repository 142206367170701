import React, { useState, useEffect } from 'react'
import {
    Stack,
    H4,
    Table,
    PaginationControl
} from 'paid-ui-lib'
import NoDataScreen from 'components/NoDataScreen'

const SurveyTableSection = ({ title, headings, data, getData, surveyTransform }) => {
    const [pageData, setPageData] = useState({
        page: 1,
        pageSize: 10
    })

    useEffect(() => {
        getData(pageData)
    }, [pageData])

    return (
        <Stack>
            <H4>{title}</H4>
            <Table columns={headings} data={data.items} transform={surveyTransform} noData={<NoDataScreen />} />
            <PaginationControl 
                pageData={pageData} 
                updatePageData={setPageData} 
                dataCount={!!data ? data.items.length : 0} 
                totalDataCount={!!data.totalResults ? data.totalResults : null} 
                hideIfOnlyOnePage={true} mt={2} 
            />
        </Stack>
    )
}

export default SurveyTableSection