import React from 'react'
import {
    Flex,
    Spinner
} from 'paid-ui-lib'
import ErrorScreen from 'components/ErrorScreen'

const LoadingScreen = ({
    hasData,
    hasError,
    render,
    children,
    spinnerSize = 32,
    ...rest
}) => {
    if(!!hasData) return children || render(rest)

    if(!!hasError) return <ErrorScreen />    

    return (
        <Flex height={["50vh","100%","100%"]} {...rest}>
            <Spinner m="auto" size={spinnerSize} />
        </Flex>
    )

}

export default LoadingScreen