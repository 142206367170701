import React, { Fragment } from 'react'
import styled from 'styled-components'
import img from '../../images/paid-logo.png'

const PageContainer = styled.div`
    color: #0B1E3E;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
`

const Logo = styled.img`
    width:64px;
    margin-top:16px;
    margin-left:16px;
    margin-bottom:32px;
`

const Message = styled.p`
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
`

const BrowserList = styled.ul`
    list-style-type: none;
    padding: 0;

    li {
        font-weight: bold;
        margin-bottom: 8px;
    }
`

const IncompatableBrowserScreen = ({render}) => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const incompatableBrowser = isIE

    return incompatableBrowser
    ? (
        <Fragment>
            <Logo src={img} width="64" alt="Paid Logo"/>
            <PageContainer>
                <h1>Incompatible Browser</h1>
                <div>
                    <Message>Sorry we do not currently support this brower, see the officially supported browers below:</Message>
                </div>
                <BrowserList>
                    <li>Chrome</li>
                    <li>Edge</li>
                    <li>Firefox</li>
                    <li>Safari</li>
                    <li>Opera</li>
                </BrowserList>
            </PageContainer>
        </Fragment>
    )
    : render()
}

export default IncompatableBrowserScreen