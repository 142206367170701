import React from 'react'
import {
    List,
    LI
} from 'paid-ui-lib'

const FormatList = (props) => {
    return <List as="ul" listStyle="none" pl="0" {...props}>
        { props.items.filter(i => i !== "" && i !== null).map((i, index) => <LI key={index} {...props}>{i}</LI>) }
    </List>
}

export default FormatList