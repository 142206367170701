import React, { useState, useEffect } from 'react'
import { 
    useRequest,
    InputSearch
} from 'paid-ui-lib'

const EmailTemplateSelector = ({ tags = [], value, onChange, ...rest }) => {
    const { postRequest } = useRequest()
    const [data, setData] = useState(null)

    useEffect(() => {
        if (typeof(value) !== "string") {
            return
        }

        getTemplates()
        .then(res => {
            const match = res.find(r => r.id === value)

            if (!!match) {
                onChange(match)
            }
        })
    }, [value])

    const getTemplates = async () => {
        if (!!data) {
            return data
        }

        const res = await postRequest(`email-templates/with-tags`, { items: tags })
        setData(res)
        return res
    }

    if (typeof(value) === "string") {        
        return null
    }

    return (
        <InputSearch 
            itemType="notification templates"
            getSearchData={getTemplates}
            getData={getTemplates}
            value={value}
            onChange={onChange}
            {...rest}/>
    )
}

export default EmailTemplateSelector