import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    NavLayout,
    NavBar,
    NavItem,
    NavItemGroup,
    NavHeader,
    NavCollapse,
    NavBody,
    NavSpacer,
    useAuthContext,
    hasPermission,
    getUserData,
    Flex,
    NavUser,
} from 'paid-ui-lib';
import { googleLogout } from '@react-oauth/google';
import logo from '../../images/paid-logo.png';
import InactivityTimeout from '../InactivityTimeout';
import { useRequest } from '../use-request'

const AuthorisedLayoutPartial = (props) => {
    const { getRequest } = useRequest();
    const history = useHistory();
    const location = useLocation();
    const { permissions, logout } = useAuthContext()
    const [userData, setUserData] = useState(null)
    const [paymentStats, setPaymentStats] = useState(null)

    const goTo = (action) => (e) => {
        e.preventDefault();
        history.push(action)
    }

    const getMenuItemProps = (action, exactAction) => {
        return {
            href: action,
            onClick: goTo(action),
            active: exactAction ? location.pathname === action : location.pathname.startsWith(action)
        }
    }

    useEffect(() => {
        setUserData(getUserData())
    }, [])

    useEffect(() => {
        getRequest(`payments/stats`).then(setPaymentStats);
    }, [])

    const logoutClicked = () => {
        googleLogout()
        logout()
    }

    return (
        <NavLayout>
            <NavBar>
                <NavCollapse>
                    <NavHeader logo={logo} />
                    {!!userData && <NavUser name={userData.fullname} onClick={goTo("/account")} />}
                    <NavBody>
                        {
                            <NavItem {...getMenuItemProps("/", true)} icon="grid">Dashboard</NavItem>
                        }

                        {
                            hasPermission(permissions, 100, /* Organisations */) &&
                            <NavItem {...getMenuItemProps("/organisations")} icon="truck">Organisations</NavItem>
                        }

                        {
                            hasPermission(permissions, 200, /* Enterprise */) &&
                            <NavItem {...getMenuItemProps("/enterprise")} icon="grid">Enterprise</NavItem>
                        }

                        {
                            hasPermission(permissions, 300, /* Payments */) &&
                            <NavItemGroup icon="credit-card" name="Payments" activityCount={!!paymentStats && !!paymentStats.heldPaymentsCount ? paymentStats.heldPaymentsCount : 0}>
                                <NavItem {...getMenuItemProps("/payments/outgoing", true)}>Outgoing Payments</NavItem>
                                <NavItem {...getMenuItemProps("/payments/outgoing-held", true)} activityCount={!!paymentStats && !!paymentStats.heldPaymentsCount ? paymentStats.heldPaymentsCount : 0}>Held Payments</NavItem>
                            </NavItemGroup>
                        }

                        {
                            hasPermission(permissions, 400, /* Work */) &&
                            <NavItem {...getMenuItemProps("/work")} icon="folder">Work</NavItem>
                        }

                        {
                            hasPermission(permissions, 500 /* Invoices */) &&
                            <NavItemGroup icon="credit-card" name="Invoices">
                                <NavItem {...getMenuItemProps("/invoices/supplier-invoices")}>Supplier Invoices</NavItem>
                                <NavItem {...getMenuItemProps("/invoices/transaction-invoices")}>Transaction Invoices</NavItem>
                            </NavItemGroup>
                        }

                        {
                            hasPermission(permissions, 600 /* Administrators */) &&
                            <NavItem {...getMenuItemProps("/administrators")} icon="book">Administrators</NavItem>
                        }

                        {
                            hasPermission(permissions, 0 /* Settings */) &&
                            <NavItemGroup icon="settings" name="Settings">
                                <NavItem {...getMenuItemProps("/settings/roles")}>Roles</NavItem>
                                <NavItem {...getMenuItemProps("/settings/email-templates")}>Email Templates</NavItem>
                                <NavItem {...getMenuItemProps("/settings/email-sequences")}>Email Sequences</NavItem>
                            </NavItemGroup>
                        }

                        <NavSpacer />
                        <NavItem icon="log-out" onClick={logoutClicked}>Log Out</NavItem>
                    </NavBody>
                </NavCollapse>
            </NavBar>
            <Flex flex="1" height={["100%", "100vh", "100vh", "100vh"]} flexDirection="column">
                <InactivityTimeout timeoutSeconds={900}>
                    {props.children}
                </InactivityTimeout>
            </Flex>
        </NavLayout>
    )
}

export default AuthorisedLayoutPartial