import styled from 'styled-components'

export const PageDiv = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 2rem);
`

export const CenteredDiv = styled.div`
    margin: 0 auto;
`