import React, { useState } from 'react'
import { useRequest } from 'components/use-request'
import useFormal from "@kevinwolf/formal-web";
import * as yup from "yup";
import {
    Box,
    Button,
    ButtonStack,
    Field,
    Input,
    PseudoElement,
    Stack,
    useToast,
    getUserData
} from 'paid-ui-lib'

const RemoveAuthenticator = ({ onComplete }) => {
    const toast = useToast()
    const { deleteRequest } = useRequest()
    const [isVerifying, setIsVerifying] = useState(false)

    const submitVerificationCode = (code) => {
        setIsVerifying(true)
        deleteRequest(`auth/authenticator/link`, {
            code: code,
        })
            .then((res) => {
                setIsVerifying(false)

                const userData = getUserData()
                localStorage.setItem("userData", JSON.stringify({...userData, tfaEnabled: false}))

                toast({
                    slim: true,
                    position: "top-right",
                    title: "Success",
                    description: `Two Factor Authentication disabled`,
                    status: "success",
                })
                onComplete()
            })
            .catch(() => {
                setIsVerifying(false)
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Failed",
                    description: `Two Factor Authentication failed`,
                    status: "error",
                })
            })
    }

    const formal = useFormal({
        code: "",
    }, {
        schema: yup.object().shape({
            code: yup
                .string()
                .required("Please provide an authenticator code"),
        }),
        onSubmit: values => {
            !!values && !!values.code && submitVerificationCode(values.code)
        }
    });

    return (
        <Stack>
            <PseudoElement as="form" {...formal.getFormProps()}>
                <Box
                    isOpen
                    title="Authenticator"
                    footer={
                        <ButtonStack flex="1" row ml="auto">
                            <Button variant="secondary" onClick={onComplete} disabled={!!isVerifying}>Cancel</Button>
                            <Button type="submit" variant="destructive" {...formal.getSubmitButtonProps()} isLoading={!!isVerifying} disabled={!!isVerifying}>Remove</Button>
                        </ButtonStack>
                    }
                >
                    <Stack>
                        <Field label="Enter your 6 digit generated code and click 'Remove' to disable authenticator" isRequired errorMessage={formal.errors.code}>
                            <Input autoFocus {...formal.getFieldProps("code")} maxWidth="400px" placeholder="Enter your code" />
                        </Field>
                    </Stack>
                </Box>
            </PseudoElement>
        </Stack>
    )
}

export default RemoveAuthenticator