import { getHeaderAccessToken } from 'paid-ui-lib';
import { createQueryString } from '../helpers/url-helper'

export const getRequest = (path, params) => {

    const url = path.startsWith("http") ? path : window._paidApiHost + "/" + path + createQueryString(params);

    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            "Authorization": getHeaderAccessToken()
        }),
    })
}

export const postRequest = (path, data, implicitContentType) => {
    let headers = { "Authorization": getHeaderAccessToken() }

    if(!implicitContentType) {
        headers["Content-type"] = "application/json; charset=UTF-8"
    }

    const url = path.startsWith("http") ? path : window._paidApiHost + "/" + path;

    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: new Headers(headers),
        body: implicitContentType ? data : JSON.stringify(data)
    })
}

export const putRequest = (path, data, implicitContentType) => {
    let headers = { "Authorization": getHeaderAccessToken() }

    if(!implicitContentType) {
        headers["Content-type"] = "application/json; charset=UTF-8"
    }

    const url = path.startsWith("http") ? path : window._paidApiHost + "/" + path;

    return fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: new Headers(headers),
        body: implicitContentType ? data : JSON.stringify(data)
    })
}

export const deleteRequest = (path) => {

    const url = path.startsWith("http") ? path : window._paidApiHost + "/" + path;

    return fetch(url, {
        mode: 'cors',
        headers: new Headers({
            "Authorization": getHeaderAccessToken()
        }),
        method: 'DELETE'
    })
}