import React, { Fragment, useEffect, useState } from 'react'
import {
    A,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Field,
    HR,
    Icon,
    Span,
    Stack,
} from 'paid-ui-lib'

import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'

const DocumentsDrawer = ({ organisationId, isOpen, onClose }) => {
    const [dataError, setDataError] = useState(false)
    const [documents, setDocuments] = useState(null)
    const { getRequest } = useRequest()

    async function downloadDocument(d) {
        const httpResponse = await getRequest(d.url);
        const blob = await httpResponse.blob();
        const blobObj = URL.createObjectURL(blob);
        return { ...d, data: blobObj };
    }

    async function downloadDocuments(docs) {
        const documentDownloads = (docs || []).filter(d => !!d.url).map(downloadDocument);
        const downloadedDocuments = await Promise.all(documentDownloads);
        setDocuments(downloadedDocuments)
    }

    const refreshView = () => {
        if (!!organisationId) {
            getRequest(`organisations/${organisationId}/partnership-documents`)
                .then((res) => setDocuments(res || []))
                .catch(() => setDataError(true))
        }
    }

    useEffect(() => {
        refreshView()
    }, [organisationId])

    return (
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={() => !!onClose && onClose()}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Documents</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <LoadingScreen
                        hasData={documents !== null}
                        hasError={dataError}
                        render={() => (
                            <Stack>
                                {
                                    (documents || []).length > 0
                                        ? documents.map((document, index) =>
                                            <Fragment>
                                                {index !== 0 && <HR />}
                                                <Stack>
                                                    <Field label="Id" value={document.id || "-"} />
                                                    <Field label="Filename" value={document.filename || "-"} />
                                                    <Field label="Session" value={document.sessionId || "-"} />
                                                    <Field label="Content Type" value={document.contentType || "-"} />
                                                    <Field label="Document Type" value={document.documentType || "-"} />
                                                    <Stack row alignItems="center" spacing={8}>
                                                        <A cursor="pointer" target="_blank" href={`${document.url}`}>View</A>
                                                        <Icon name="external-link" />
                                                    </Stack>
                                                    {/* {!!document.data && <Img src={document.data} alt={`Document ${index}`} height="16rem" />} */}
                                                </Stack>
                                            </Fragment>)
                                        : <Span>No documents</Span>
                                }
                            </Stack>
                        )} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default DocumentsDrawer