import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { useRequest } from 'components/use-request'
import {
    ButtonStack,
    Button,
    Stack,
    TabPanel,
    Tab,
    H2,
    H3,
    Label,
    Field,
    Span,
    Flex,
    Badge,
    Icon,
    PageTitle,
    PageContent,
    Box,
    LayoutStack,
    Panel,
    Collapse,
    Strong,
    IconCircle,
    BadgeText,
    checkKeyIsValid,
    Link,
} from 'paid-ui-lib'
import { getQueryVariable } from 'helpers/url-helper'
import NoteViewerDraw from 'components/NoteViewerDraw'
import MessageViewer from 'components/MessageViewer'
import PaymentsTab from './components/PaymentsTab'
import DetailsTab from './components/DetailsTab'
import DeliverablesTab from './components/DeliverablesTab'
import DocumentsTab from './components/DocumentsTab'
import SupplierTab from './components/SupplierTab'
import LoadingScreen from 'components/LoadingScreen'
import { formatDateTime } from 'helpers/date-helper'

const ViewWorkView = (props) => {
    const { getRequest } = useRequest()
    
    const getDeepLink = () => {
        const deeplink = getQueryVariable(props.location.search, 'dl')
        return !!deeplink ? parseInt(deeplink, 10) : null
    }

    const [showNotes, setShowNotes] = useState(false)
    const [showMessages, setShowMessages] = useState(getDeepLink() === 3)
    const [viewData, setViewData] = useReducer((state, action) => {
        return {
            ...state,
            [action.key]: action.value
        }
    }, {})
    const [work, setWork] = useState(null)
    const [dataError, setDataError] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [breakdownOpen, setBreakdownOpen] = useState(false)

    const getWork = (enterpriseId, workId) => {
        getRequest(`work/${enterpriseId}/${workId}`)
        .then(res => {
            setWork(res)
        })
        .catch(() => {
            setDataError(true)
        })
    }

    const getData = (key) => {
        return viewData[key];
    }

    const setData = (key, value) => {
        setViewData({
            key,
            value
        })
    }

    const refreshWork = () => getWork(work.id)
    
    useEffect(() => {
        getWork(props.match.params.enterpriseId, props.match.params.workId) 
    }, [props.match.params.workId, props.match.params.enterpriseId])

    return (
        <LoadingScreen
            hasData={work}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle 
                        title={work.title}
                        description={work.supplier.name}
                        status={work.status && {
                            state: work.status.state,
                            title: work.status.title,
                            text: work.status.text,
                            actions: !!work.openContract && <Button as="a" target="_blank" href={(!!work.requiredCompletionData.length && !!work.evidence[work.openContract.id]) ? `work/${work.id}/contract/${work.openContract.id}` : work.openContract.signingLink}>View and sign documentation</Button>,
                            date: work.status.date,
                            dateFormat: formatDateTime
                        }}
                        slim />
                    <PageContent slim flex="1">
                        <LayoutStack row responsiveReverse>
                            <TabPanel selectedTab={selectedTab} onSelectedTabChange={setSelectedTab} flex="1">
                                {[ 
                                    <Tab key="details" icon="folder" component={<DetailsTab work={work} getData={getData} setData={setData} refreshWork={refreshWork} />}>Details</Tab>,
                                    <Tab key="supplier" icon="truck" component={<SupplierTab work={work} />}>Supplier</Tab>,
                                    <Tab key="deliverables" icon="list" component={<DeliverablesTab work={work} refreshWork={refreshWork}/>}>Deliverables</Tab>,                            
                                    <Tab key="documents" icon="file-text" component={<DocumentsTab work={work} />}>Documents</Tab>,
                                    <Tab key="payments" icon="dollar" component={<PaymentsTab enterpriseId={props.match.params.enterpriseId} work={work} getData={getData} setData={setData} refreshWork={refreshWork} />}>Payments</Tab>,
                                ]}
                            </TabPanel>
                            <Stack spacing={16} width={["100%", "18.75rem", "18.75rem"]} >
                                <ButtonStack row joint>
                                    <Button flex="1" variant="secondary" onClick={() => setShowMessages(true)}>Messages</Button>
                                    <Button flex="1" variant="secondary" onClick={() => setShowNotes(true)}>Notes</Button>
                                </ButtonStack>
                                <Box flex="0 1 auto" accent="blue">
                                    <Stack>
                                        <H2>Work Details</H2>
                                        <Field 
                                            actions={
                                                work.expressPaymentFunded 
                                                ? <Flex ml="auto" alignItems="center" px={8} py={4} backgroundColor="grey.200" borderRadius="default"><Icon color="grey.600" size={16} mr={4} name="express-payment"/><BadgeText>Funded</BadgeText></Flex>
                                                : undefined
                                            } 
                                            label={`Sub Total ${work.amountBreakdown.hasVatBreakdown ? "(Ex VAT)" : ""}`}>
                                            {
                                                !!work.completedOn
                                                ? (
                                                    <Flex flexDirection="column">
                                                        <Flex alignItems="center" cursor="pointer"  onClick={() => setBreakdownOpen(bo => !bo)}>                                                            
                                                            <H3>{`${(work.currencySymbol || "")}${(work.amountBreakdown.subTotal || 0).toFixed(2)}`}</H3>
                                                            <IconCircle tabIndex="0" aria-pressed={breakdownOpen} role="button" aria-label="Toggle sub total breakdown" onKeyDown={(e) => checkKeyIsValid(e) && setBreakdownOpen(bo => !bo)} ml="auto" size={24} name={breakdownOpen ? "chevron-up" : "chevron-down"} />
                                                        </Flex>
                                                        <Collapse isOpen={breakdownOpen}>
                                                            <Panel p={12} round backgroundColor="grey.200" mt={16}>
                                                                <Stack spacing={8}>
                                                                    <Flex alignItems="center"><Label>Sub Total:</Label><Span ml="auto">{`${(work.currencySymbol || "")}${(work.amountBreakdown.subTotal || 0).toFixed(2)}`}</Span></Flex>
                                                                    {work.amountBreakdown.hasVatBreakdown && <Flex alignItems="center"><Label>VAT:</Label><Span ml="auto">{`${(work.currencySymbol || "")}${(work.amountBreakdown.vatTotal || 0).toFixed(2)}`}</Span></Flex>}
                                                                    {work.amountBreakdown.expressPaymentFunded && <Flex alignItems="center"><Label>Express Payment Fee:</Label><Span ml="auto">{`${(work.currencySymbol || "")}${(work.amountBreakdown.expressPaymentFundedValue || 0).toFixed(2)}`}</Span></Flex>}
                                                                    <Flex alignItems="center"><Label>Total:</Label><Strong ml="auto">{`${(work.currencySymbol || "")}${(work.amountBreakdown.total || 0).toFixed(2)}`}</Strong></Flex>
                                                                </Stack>
                                                            </Panel>
                                                        </Collapse>
                                                    </Flex>
                                                )
                                                : (!!work.pendingAmendment
                                                    ? (
                                                        <Flex>
                                                            <H3 mr={8} textDecoration="line-through">{`${(work.currencySymbol || "")}${(work.amountBreakdown.subTotal || 0).toFixed(2)}`}</H3>
                                                            <H3 color="orange.500" >{`${(work.currencySymbol || "")}${(work.pendingAmendment.newAmount || 0).toFixed(2)}`}</H3>
                                                        </Flex>
                                                    )
                                                    : (
                                                        <H3>{`${(work.currencySymbol || "")}${(work.amountBreakdown.subTotal || 0).toFixed(2)}`}</H3>
                                                    ))
                                            }
                                        </Field>
                                        <Field uppercase label="Reference">
                                            <Span wordBreak="break-word">{work.reference}</Span>
                                        </Field>
                                        <Field uppercase label="Procurement Owner">
                                            <Span wordBreak="break-word">{work.procurementOwner && work.procurementOwner.name}</Span>
                                        </Field>
                                        <Field uppercase label="Supplier">
                                            <Link wordBreak="break-word" action={`/suppliers/${work.supplier.id}`}>{work.supplier.name}</Link>
                                        </Field>
                                        <Field uppercase label="Supplier Contact">
                                            <Span wordBreak="break-word">{work.supplierUser.name}</Span>
                                        </Field>
                                        {
                                            !!work.invoices.length &&
                                            <Field uppercase label="Invoice">
                                                <Stack spacing="1">
                                                    <Flex>
                                                        <Span>{work.invoices[work.invoices.length - 1].reference}</Span>
                                                        {!!work.invoices[work.invoices.length - 1].paidOn ? <Badge ml="auto" status="success">Paid</Badge> : (!!work.invoices[work.invoices.length - 1].dueOn ? <Badge ml="auto" status="info">Payment Scheduled</Badge> : <Badge ml="auto" status="warning">Details Required</Badge>)}
                                                    </Flex>                                                    
                                                </Stack>
                                            </Field>
                                        }
                                        {
                                            !!work.linkFrom &&
                                            <Field uppercase label="Linked From">
                                                <Link action={`/work/${work.linkFrom.id}`}>{work.linkFrom.name}</Link>
                                            </Field>
                                        }
                                        {
                                            !!work.linkTo &&
                                            <Field uppercase label="Linked To">
                                                <Link action={`/work/${work.linkTo.id}`}>{work.linkTo.name}</Link>
                                            </Field>
                                        }
                                    </Stack>
                                </Box>
                            </Stack>
                        </LayoutStack>
                    </PageContent>
                    <NoteViewerDraw notes={work.notes} onClose={() => setShowNotes(false)} isOpen={showNotes} />
                    <MessageViewer 
                        thread={work.thread}
                        hasThread={!!work.thread}
                        onClose={() => setShowMessages(false)} 
                        isOpen={showMessages} 
                    />
                </Fragment>
            )}
        />
    )
}

export default ViewWorkView