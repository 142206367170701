import React from 'react'
import {
    Flex,
    Icon,
    P,
    Span,
    Spinner,
    PseudoElement,
} from 'paid-ui-lib'

const ValidationFooter = ({ pendingValidation, isValid, showSuccessMsg, ...rest }) => {

    const getWrapperProps = () => {
        let props = {
            alignItems: "center"
        }

        if (isValid && !pendingValidation) {
            props["opacity"] = showSuccessMsg ? "1" : "0"
            props["transition"] = "all .4s"
        }
        return props
    }

    const renderValidationMsg = () => {
        return (
            <Flex {...getWrapperProps()} {...rest}>
                <Icon size={"1rem"} name={isValid ? "check" : "alert-triangle"} color={isValid ? "green.500" : "red.500"} ml={24} mr={4} mt={4} />
                <P>{`${isValid ? "Passed" : "Failed"} syntax validation `}</P>
            </Flex>
        )
    }

    const renderValidationSpinner = () => {
        return (
            <Flex {...getWrapperProps()} {...rest}>
                <Spinner mt={4} ml={24} size={16} />
                <Span ml={8}>Validating code</Span>
            </Flex>
        )
    }

    return (
        <PseudoElement>
            {
                pendingValidation ? renderValidationSpinner() : renderValidationMsg()
            }
        </PseudoElement>
    )
}

export default ValidationFooter