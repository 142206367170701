import React from "react"
import {
    Stack,
    Button,
    Field,
    Flex,
} from 'paid-ui-lib'
import Deliverable from './components/Deliverable'

const DeliverableList = ({
    label,
    milestoneId,
    showBreak,
    deliverables,
    enableQuickAdd,
    currencySymbol,
    getDeliverableTemplates,
    deliverableTemplates,
    allowFreeText,
    appendOnly,
    readonly,
    onCancel,
    selectedDeliverable,
    onEditDeliverable,
    onChangeDeliverable,
    onCreateDeliverable,
    onRemoveDeliverable,
    ...rest
}) => {
    return (
        <Field label={label} {...(showBreak ? {borderTop: "1", paddingTop:"4"}: {})} {...rest}>
            <Stack spacing={12}>
                {
                    deliverables.map(r => 
                        <Deliverable
                            key={r.id}
                            value={r}
                            enableQuickAdd={enableQuickAdd}
                            currencySymbol={currencySymbol}
                            getDeliverableTemplates={getDeliverableTemplates}
                            deliverableTemplates={deliverableTemplates}
                            allowFreeText={allowFreeText}
                            appendOnly={appendOnly}
                            readonly={readonly}
                            selectedDeliverable={selectedDeliverable}
                            onCancel={onCancel}
                            onEdit={onEditDeliverable}
                            onChange={onChangeDeliverable}
                            onRemove={onRemoveDeliverable} />)
                }
                {
                    (!readonly && !selectedDeliverable) &&
                    <Flex>
                        <Button flex="1" variant="secondary" onClick={() => onCreateDeliverable(milestoneId)} leftIcon="plus-circle">Add a Deliverable</Button>
                    </Flex>
                }            
            </Stack>
        </Field>    
    )
}

export default DeliverableList