import React from 'react'
import { getMonth, formatMonth } from 'helpers/date-helper'
import {
    BarChart,
    Bar,
    Legend,
    Tooltip,
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
} from "recharts";
import {
    PseudoElement,
    useTheme,
    Button,
    EmptyState
} from 'paid-ui-lib'
import { useHistory } from 'react-router-dom'

const DashboardGraph = ({ graph, currencySymbol = "£", ...rest }) => {
    const theme = useTheme()
    const history = useHistory();
    const axis = [
        {
            yAxisId: "l",
            accessor: "createdProjects",
            name: "Created Work",
            color: theme.colors.blue[400]
        },
        {
            yAxisId: "r",
            accessor: "openWorkValue",
            name: "Value of Open Work",
            color: theme.colors.blue[200]
        },
        {
            yAxisId: "l",
            accessor: "completedProjects",
            name: "Completed Work",
            color: theme.colors.green[400]
        },
        {
            yAxisId: "r",
            accessor: "invoicedWorkValue",
            name: "Value of Invoiced Work",
            color: theme.colors.green[200]
        }
    ]

    if (!Array.isArray(graph.points) || !graph.points.length) return (
        <EmptyState
            illy="illy-chart-bar"
            illyRound={true}
            mainText="A running history of work value and volumes will be displayed here once work is created.">
        </EmptyState>
    )

    return (
        <PseudoElement {...rest}>
            <ResponsiveContainer>
                <BarChart data={graph.points} isAnimationActive={false}>
                    <Legend
                        verticalAlign="top"
                        align="left"
                        height={50}
                        iconType="circle"
                        iconSize={10} />
                    <CartesianGrid
                        vertical={false}
                        strokeWidth={1}
                        stroke={theme.colors.grey[300]}
                        shapeRendering="crispEdges" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={getMonth}
                        axisLine={false}
                        tickMargin={20}
                        tickSize={0} />
                    <YAxis
                        orientation="left"
                        yAxisId="l"
                        axisLine={false}
                        tickMargin={10}
                        tickSize={0} />
                    <YAxis
                        orientation="right"
                        yAxisId="r"
                        tickFormatter={(d) => `${currencySymbol}${d}`}
                        width={80}
                        axisLine={false}
                        tickMargin={10}
                        tickSize={0} />
                    <Tooltip
                        labelFormatter={formatMonth}
                        cursor={false} />
                    {axis.map(a => <Bar key={a.accessor} dataKey={a.accessor} name={a.name} fill={a.color} yAxisId={a.yAxisId} barSize={16} />)}
                </BarChart>
            </ResponsiveContainer>
        </PseudoElement>
    )
}


export default DashboardGraph