import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Stack,
    Field,
    formatDateTime,
    H3,
    HR,
    Badge,
    P,
} from 'paid-ui-lib'

const ComplianceTaskDrawer = ({task, onClose, isOpen}) => {
    const getBadge = (status) => {
        if (status == "Pending" || status == "Warning") {
            return "info";
        }
        else if (status == "Success") {
            return "success";
        }
        else if (status == "Failed") {
            return "error";
        }
        else  {
            return "info";
        }
    }

    return (
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Task Details</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <Stack>
                        <Stack>
                            <H3>Task</H3>
                            <Field label="Id" value={task.id}></Field>
                            <Field label="Type" value={task.type}></Field>
                            <Field label="Result" value={<Badge ml="auto" status={getBadge(task.result)}>{task.result}</Badge>}></Field>
                            <Field label="Created On" value={formatDateTime(task.createdOn)}></Field>
                        </Stack>
                        <HR />

                        <Stack>
                            <H3>Completed Checks</H3>
                                { 
                                    task.checks.map(check => {
                                            return (
                                                <Stack>
                                                    <Field label={check.type} value={<Badge ml="auto" status={getBadge(task.result)}>{task.result}</Badge>} />
                                                    <Field label="Providers" value={check.providers.join(", ")} />
                                                </Stack>
                                            )
                                            
                                            
                                    })
                                }
                        </Stack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ComplianceTaskDrawer