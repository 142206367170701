import React, { useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    PseudoElement,
    Box,
    HeaderSection,
    Stack,
    Input,
    Select,
    ButtonStack,
    Button,
    useToast,
    Field,
    HR,
    Badge
} from 'paid-ui-lib'
import useFormal from "@kevinwolf/formal-web";
import * as yup from "yup";
import { formatDateTime } from 'paid-ui-lib/dist/lib/utilities/Date';

const UserDetailsTab = ({user, roles, onUpdate, ...rest}) => {
    const toast = useToast()
    const { putRequest } = useRequest()

    const formal = useFormal({
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        roleId: "",
    }, {
        schema: yup.object().shape({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup.string().required(),
            jobTitle: yup.string().required(),
            roleId: yup.string().required(),
        }),
        onSubmit: values => {
            return putRequest(`administrators/${user.id}`, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                jobTitle: values.jobTitle,
                roleId: values.roleId,
            })
            .then(() => {
                toast({
                    slim: true,
                    position: "top-right",
                    title: `User Details Updated`,
                    description: `User details successfully updated`,
                    status: "success",
                    isClosable: true
                  })                  
                onUpdate(true)
            })
            .catch(() => {})
        }
    });

    const GetAuthenticatorStatus = (status) => {
        if (status) {
            return <Badge status="success">Active</Badge>
        }
        else {
            return <Badge status="danger">Disabled</Badge>
        }
    }

    useEffect(() => {
        if(!!user){
            Object.keys(user).forEach(key => {
                if(key === "role"){
                    formal.change("roleId", (user[key] || {}).id)
                } else {
                    formal.change(key, user[key] || "")
                }
            })
        }
    }, [user])

    return (
        <PseudoElement as="form" {...formal.getFormProps()} {...rest}>
            <Stack flex="1">
                <HeaderSection title="User Details">
                    <Box
                        isOpen={true}
                        footer={
                            <ButtonStack ml="auto">
                                <Button type="submit" {...formal.getSubmitButtonProps()}>Update</Button>
                            </ButtonStack>
                        }
                    >
                        <Stack>
                            <Stack row responsive>
                                <Stack flex="1">
                                    <Field label="First Name" isRequired errorMessage={formal.errors.firstName}>
                                        <Input {...formal.getFieldProps("firstName")} />
                                    </Field>
                                    <Field label="Last Name" isRequired errorMessage={formal.errors.lastName}>
                                        <Input {...formal.getFieldProps("lastName")} />
                                    </Field>
                                    <Field label="Email" isRequired errorMessage={formal.errors.email}>
                                        <Input {...formal.getFieldProps("email")} disabled textTransform="lowercase"/>
                                    </Field>
                                </Stack>
                                <Stack flex="1">
                                    <Field label="Job Title" isRequired errorMessage={formal.errors.jobTitle}>
                                        <Input {...formal.getFieldProps("jobTitle")} />
                                    </Field>
                                    <Field label="Role" isRequired errorMessage={formal.errors.roleId} isLoading={!roles}>
                                        <Select {...formal.getFieldProps("roleId")}>
                                            <option>Select Role...</option>
                                            {(roles || []).map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                        </Select>
                                    </Field>
                                </Stack>
                            </Stack>
                            <HR/>
                            <Stack>
                                <Field label="Authenticator Enabled" value={GetAuthenticatorStatus(user.authenticatorEnabled)} />
                                {user.suspended && <Field label="Suspension Ends" value={formatDateTime(user.suspensionEnds)} /> }
                            </Stack>
                        </Stack>
                    </Box>
                </HeaderSection>                
            </Stack>
        </PseudoElement>
    )
}

export default UserDetailsTab