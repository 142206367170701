import React, { useEffect } from 'react'
import {
    PseudoElement,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Sub,
    P,
    H4,
    Avatar,
    StateIndicator,
    Flex,
    Stack,
    Field,
    Badge,
    IconButton,
    formatDateTime,
    DrawerFooter
} from 'paid-ui-lib'

const AgreementInfoDrawer = ({selectedAgreement, onClose, isOpen}) => {
    useEffect(() => {
    }, [])


    const getSignerStatusIcon = (s, index) => {
        let state = 0;
        if(s.signedOn) state = 1;
        else if(s.declinedOn) state = 2

        return (
            <StateIndicator key={index} state={state} size={32}>
                <Avatar name={s.name}/>
            </StateIndicator>
        )
    }

    const getSignerStatus = (s) => {
        if(s.signedOn) return `Signed Off`
        else if(s.declinedOn) return `Declined`
        else return "Awaiting"
    }

    const getSignerStatusDate = (s) => {
        if(s.signedOn) return <Sub lightText>{formatDateTime(s.signedOn)}</Sub>
        else if(s.declinedOn) return <Sub lightText>{formatDateTime(s.declinedOn)}</Sub>
        else return null
    }

    return (
        !!selectedAgreement && 
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Agreement Information</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <Stack spacing={3}>
                        <Field label="Contract Name" value={selectedAgreement.name}></Field>
                        <Field label="Created On" value={formatDateTime(selectedAgreement.created)}></Field>
                        <Field label="Last Actioned" value={formatDateTime(selectedAgreement.updated)}></Field>
                        <Field label="Status">
                            <PseudoElement>
                                {selectedAgreement.status === 0 && <Badge status="info">Unsigned</Badge>}
                                {selectedAgreement.status === 1 && <Badge status="success">Signed</Badge>}
                                {selectedAgreement.status === 2 && <Badge status="error">Declined</Badge>}
                            </PseudoElement>
                        </Field>
                        {
                            selectedAgreement.status === 2 &&
                            <Field label="Decline Reason">
                                <Stack>
                                    {selectedAgreement.signers.filter(s => !!s.declineReason).map((s, index) =>
                                        <P key={index}>"{s.declineReason}" - {s.name}</P>
                                    )}                                    
                                </Stack>                                
                            </Field>
                        }
                        <Field label="Signers">
                            <Stack spacing={2}>
                                {selectedAgreement.signers.map((s, index) =>
                                    <Flex alignItems="center" key={index}>
                                        {getSignerStatusIcon(s, index)}
                                        <Flex flexDirection="column" ml={16}>
                                            <H4>{s.name}</H4>
                                            <Sub lightText>{getSignerStatus(s)}</Sub>
                                            {getSignerStatusDate(s)}
                                        </Flex>
                                        {
                                            s.signingLink &&
                                            <IconButton ml="auto" as="a" href={s.signingLink} title="View as signer" target="_blank" variant="secondary" icon="edit-alt-2" isSmall/>
                                        }
                                    </Flex>                                        
                                )}
                            </Stack>
                        </Field>
                    </Stack>
                </DrawerBody>
                <DrawerFooter>
                    <Button as="a" href={selectedAgreement.signingLink} target="_blank" variantColor="white">
                        View Agreement
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default AgreementInfoDrawer