import React from 'react';
import {
    AuthProvider,
    useToast
} from 'paid-ui-lib'
import SplashScreen from '../SplashScreen'
import { useRequest } from '../use-request'

const AuthWrapper = ({children}) => {
    const toast = useToast()
    const { postRequest } = useRequest()

    const refreshAccessToken = (refreshToken) => {
        return postRequest('authentication/refresh', {
            refreshToken: refreshToken
        })
    }
    
    const logout = (refreshToken) => {
        postRequest("authentication/logout", { refreshToken })
    }

    return (
        <AuthProvider
            onRefresh={refreshAccessToken}
            onRefreshFailed={() => {
                toast({
                    slim: true,
                    position: "top-right",
                    description: `There was an issue refreshing your session. Please try logging back in.`,
                    status: "error",
                    isClosable: true
                })
            }}
            loading={<SplashScreen />}
            onLogout={logout}>
            {children}
        </AuthProvider>
    );
}

export default AuthWrapper