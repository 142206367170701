import React, { Fragment } from 'react'
import {
    PseudoElement,
    useMedia,
} from 'paid-ui-lib'

import { isDesktop as isDesk } from 'react-device-detect';

export const isDesktop = () => {
    return !!isDesk;
    // const media = useMedia()
    // return media === "desk" || media === "lap";
}

const DesktopView = ({ children }) => {
    //const media = useMedia()
    //return <PseudoElement width="100%" display={["none", "flex", "flex"]}>{children}</PseudoElement>;
    //return (media === "desk" || media === "lap") && !!children ? <Fragment>{children}</Fragment> : <Fragment></Fragment>;
    return !!isDesktop() && !!children ? <Fragment>{children}</Fragment> : <Fragment></Fragment>;
}

export default DesktopView