import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    Stack,
    H4,
    Box,
    H3,
    Flex,
    PseudoElement,
    Img,
    LayoutStack,
    Strong,
    SemiStrong,
    Button,
} from 'paid-ui-lib'
import DocumentsTab from '../DocumentsList'

const OrganisationCards = ({ organisationId }) => {
    const [overview, setOverview] = useState(null)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const { getRequest } = useRequest()
    
    useEffect(() => {
        getRequest(`organisations/${organisationId}/documents`)
            .then(res => {
                setOverview(res)
            })
            .catch(() => {
                //setDataError(true)
            })
    }, [])

    return (
        <LoadingScreen
            hasData={overview}
            hasError={false}
            render={() => (
                !!selectedOrg ? 
                <DocumentsTab 
                    enterpriseId={selectedOrg.id} 
                    organisationId={organisationId} 
                    returnClick={() => setSelectedOrg(null)}
                    organisation={selectedOrg}/> :
                <Box>
                    <H3>Clients</H3>
                    <Stack row={true}>
                    {
                        overview.length > 1 && overview.map((x, i) => 
                        <LayoutStack key={i} mt={32} spacing={0} responsive>
                            <Box>
                                <Flex>
                                    <Flex flexDirection="column">
                                        <Flex alignItems="center">
                                            <Flex mb={4} alignItems="center" flexDirection="row" flexWrap="wrap">
                                                <H4 accessibilityHeading="h3" flexShrink="0" mr={8}>{x.name}</H4>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <PseudoElement ml="auto">
                                        <Box padding={8}>
                                            <Img src={x.logo} alt="Logo" height="2rem" />
                                        </Box>
                                    </PseudoElement>
                                </Flex>                        
                            </Box>
                            <Box>
                                <Stack row={true}>
                                    <Stack>
                                        <Strong lightText>Agreements</Strong>
                                        <SemiStrong>{x.agreements}</SemiStrong>
                                        <Strong lightText>Pricing Schedules</Strong>
                                        <SemiStrong>{x.pricingSchedules}</SemiStrong>
                                    </Stack>
                                    <Stack>
                                        <Strong lightText>Surveys</Strong>
                                        <SemiStrong>{x.surveys}</SemiStrong>
                                        <Strong lightText>Attachments</Strong>
                                        <SemiStrong>{x.attachments}</SemiStrong>
                                    </Stack>                        
                                </Stack>
                                <Button mt={12} variant="secondary" onClick={() => setSelectedOrg(x)}>View Documents</Button>
                            </Box>
                        </LayoutStack>
                    )}
                    </Stack>
                </Box>
            )}/>

        
    )
}

export default OrganisationCards