import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import {
    Stack,
    Span,
    SemiStrong,
    Flex
} from "paid-ui-lib";

import "./styling.css"

const LoginScene = ({ onLogin, onError, error }) => {
    return <Stack>
        <SemiStrong>Please sign in with Google to continue...</SemiStrong>
        <Flex justifyContent="center">
            <GoogleLogin
                onSuccess={response => onLogin(response.credential)}
                onFailure={onError}
                useOneTap
                auto_select
                hosted_domain="paid.co.uk"
            />
        </Flex>
        {error && <Span>{error}</Span>}
    </Stack>
};

export default LoginScene;
