import { useRef, useEffect } from 'react'
import {
    useAuthContext
} from 'paid-ui-lib'

const InactivityTimeout = ({ timeoutSeconds, children }) => {          
    const { logout } = useAuthContext()
    const inactivityTimer = useRef()
    const activityFlag = useRef(false)

    const registerActivity = () => {
        activityFlag.current = true
        return true
    }

    const getLastActive = () => {
        const value = localStorage.getItem("ui_activity")
        if (!value) return Date.now()
        return parseInt(value)
    }
    
    const startInterval = () => setInterval(() => {
        const lastActive = getLastActive()

        if (!!activityFlag.current) {
            activityFlag.current = false
            localStorage.setItem("ui_activity", Date.now().toString())
            return
        }

        if (Date.now() - lastActive >= (timeoutSeconds * 1000)) {
            logout("inactive")
            clearInterval(inactivityTimer.current)
        }
    }, timeoutSeconds * 1000)

    useEffect(() => {
        window.addEventListener("load", registerActivity)
        document.addEventListener("scroll", registerActivity, true)

        const events = ["load", "mousemove", "mousedown", "touchstart", "click", "keydown"]
        for (const event of events) {
            document.addEventListener(event, registerActivity)
        }

        inactivityTimer.current = startInterval()    

        return () => {
            window.removeEventListener("load", registerActivity)
            document.removeEventListener("scroll", registerActivity, true)

            for (const event of events) {
                document.removeEventListener(event, registerActivity)
            }

            clearInterval(inactivityTimer.current)
        }
    }, [])

    return children
}

export default InactivityTimeout