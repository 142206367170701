import React, { useState, useEffect } from 'react'
import {
    Button,
    ButtonStack,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Field,
    formatDateTime,
    Stack,
    useToast,
} from 'paid-ui-lib'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'

const BankVerificationBlade = ({ organisationId, bank, onClose, isOpen }) => {
    const { toast } = useToast()
    const [loading, setLoading] = useState(false)
    const { postRequest, getRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [bankDetails, setBankDetails] = useState(undefined)

    const requestNewBankVerificationCode = () => {
        if (!organisationId || !bank || !bank.bankAccountId) return;
        setLoading(true);
        return postRequest(`organisations/${organisationId}/bank/${bank.bankAccountId}/verification`)
            .then(() => {
                setLoading(false);

                toast({
                    slim: true,
                    position: "top-right",
                    title: "Success",
                    description: `Bank verification code requested`,
                    status: "success",
                })
            })
            .catch(() =>
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Failed",
                    description: `Failed to send new bank verification code. Please contact support`,
                    status: "error",
                })
            )
            .finally(() => onClose(true));
    }

    useEffect(() => {
        getRequest(`organisations/${organisationId}/bank/${bank.id}`)
            .then((res) => setBankDetails(res))
            .catch(() => setDataError(true))
    }, [])

    return (
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Bank Verification</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <LoadingScreen
                        hasData={!!bankDetails}
                        hasError={dataError}
                        render={() => (
                            <Stack>
                                <Field label="Account Name" value={bank.accountName} />
                                <Field label="Account Number" value={bankDetails.accountNumber} />
                                <Field label="Sort Code" value={bankDetails.sortCode} />
                                <Field label="Reference" value={bank.reference} />
                                <Field label="Payment" value={bank.paymentId || "-"} />
                                <Field label="Created On" value={!!bank.createdOn ? formatDateTime(bank.createdOn) : "-"} />
                                <Field label="Processed On" value={!!bank.processedOn ? formatDateTime(bank.processedOn) : "-"} />
                                <Field label="Verified On" value={!!bank.verifiedOn ? formatDateTime(bank.verifiedOn) : "-"} />
                            </Stack>
                        )}
                    />
                </DrawerBody>
                <DrawerFooter>
                    <ButtonStack flex="1" responsive>
                        {!!bank && !bank.verifiedOn && <Button onClick={requestNewBankVerificationCode} isLoading={loading} disabled={!!loading}>Send New Verification Code</Button>}
                    </ButtonStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default BankVerificationBlade