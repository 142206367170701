import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    Panel,
    Table,
    formatDateTime,
    P
} from 'paid-ui-lib'
import NoDataScreen from 'components/NoDataScreen'
import ChargesDrawer from './components/ChargesDrawer'

const ChargesPanel = ({ organisationId }) => {
    const { getRequest } = useRequest()
    const [ charges, setCharges ] = useState([])
    const [ dataError, setDataError ] = useState(false)
    const [ searchId, setSearchId ] = useState(undefined)

    useEffect(() => {
        getRequest(`organisations/${organisationId}/charges`)
        .then((res) => {
            setCharges(res)
        })
        .catch(() => {
            setDataError(true)
        })
    }, [])
    
    const headings = [
        { "header": "Search Id", "accessor": "searchId" },
        { "header": "Created On", "accessor": "createdOn"},
        { "header": "Number of Charges", "accessor": "numberOfCharges"}
    ]

    const transform = (data) => {
        return {
            searchId: <P color="blue.500" onClick={() => setSearchId(data.searchId)}>{data.searchId}</P>,
            createdOn: formatDateTime(data.createdOn),
            numberOfCharges: data.numberOfCharges
        }
    }
    
    return (
        <LoadingScreen
            hasData={!!charges}
            hasError={dataError}
            render={() => (
                <Panel>
                    <Table columns={headings} data={charges} transform={transform} noData={<NoDataScreen />}/>
                    { !!searchId && <ChargesDrawer organisationId={organisationId} searchId={searchId} isOpen={!!searchId} onClose={() => setSearchId(undefined)}></ChargesDrawer>}
                </Panel>
            )} 
        />
    )
}

export default ChargesPanel