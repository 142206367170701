import React from 'react'
import {
    Box,
    Button,
    ButtonStack,
    Field,
    HeaderSection,
    Icon,
    LayoutStack,
    Span,
    Stack,
    Tooltip,
} from 'paid-ui-lib'

const Overview = ({ enterpriseId, paymentDetails, onChangePaymentMethod, onCancelPaymentMethod, ...rest }) => {

    const getPaymentMethod = (method) => {
        switch (method) {
            case "BankAccount": return "Bank Account";
            case "VirtualCard": return "Virtual Card";
            default: return "Unknown";
        }
    }

    return (
        <HeaderSection
            title="Current Payment Details"
            actions={<ButtonStack ml="auto">
                {!!onChangePaymentMethod && <Button variant="secondary" onClick={onChangePaymentMethod}>Change Payment Method</Button>}
                {!!onCancelPaymentMethod && <Button variant="secondary" onClick={onCancelPaymentMethod}>Cancel Change</Button>}
            </ButtonStack>}
            {...rest}
        >
            <LayoutStack>
                <Box>
                    <Stack>
                        <Stack row responsive>
                            <Field flex="1" label="Payment Method Type" value={<Stack flex="1" row spacing={4} alignItems="center">
                                <Tooltip
                                    cursor="pointer"
                                    direction="right"
                                    variant="small"
                                    text={<Stack spacing={8} responsive>
                                        <Field row flex="1" label="Id" value={paymentDetails.paymentMethodId} />
                                    </Stack>}>
                                    <Stack row spacing={8} responsive alignItems="center">
                                        <Span>{getPaymentMethod(paymentDetails.paymentMethod)}</Span>
                                        <Icon name="info" />
                                    </Stack>
                                </Tooltip>
                            </Stack>} />
                            <Field flex="1" label="Transfer Available" value={!!paymentDetails.allowTransfer ? "Yes" : "No"} />
                        </Stack>
                        <Stack row responsive>
                            <Field flex="1" label="Express Payment" value={!!paymentDetails.expressPayment ? "Yes" : "No"} />
                            {!!paymentDetails.financeOption && <Field flex="1" label="Express Payment Fee Funded" value={!!paymentDetails.financeOption.expressFunded ? "Yes" : "No"} />}
                        </Stack>
                        {
                            !!paymentDetails.financeOption &&
                            <Stack row responsive>
                                <Field flex="1" label="Express Payment Provider" value={<Stack flex="1" row spacing={4} alignItems="center">
                                    <Tooltip
                                        cursor="pointer"
                                        direction="right"
                                        variant="small"
                                        text={<Stack spacing={8} responsive>
                                            <Field row flex="1" label="Id" value={paymentDetails.financeOption.providerId} />
                                        </Stack>}>
                                        <Stack row spacing={8} responsive alignItems="center">
                                            <Span>{paymentDetails.financeOption.providerName}</Span>
                                            <Icon name="info" />
                                        </Stack>
                                    </Tooltip>
                                </Stack>} />
                                {!!paymentDetails.financeOption.rate && <Field flex="1" label="Express Payment Rate" value={`${paymentDetails.financeOption.rate}%`} />}
                            </Stack>
                        }
                    </Stack>
                </Box>
            </LayoutStack>
        </HeaderSection>
    )
}

export default Overview