import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import ListOrganisationsView from './routes/list'
import DetailsView from './routes/view'

const OrganisationRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/organisations" component={ListOrganisationsView} /> }
            { <Route exact path="/organisations/:organisationId" component={DetailsView} /> }
            { <Route exact path="/organisations/:enterpriseId/:organisationId" component={DetailsView} /> }

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default OrganisationRoutes