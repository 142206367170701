import React, { useState, useEffect } from 'react'
import {
    Box,
    Stack,
    Field,
    HeaderSection,
    Panel,
    Link,
    formatDateTime
} from 'paid-ui-lib'

import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'

import LoadingScreen from 'components/LoadingScreen'

const UserDetailsTab = ({ user, props }) => {
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [dataError, setDataError] = useState(false)

    const orgColumns = [
        { "header": "Name", "accessor": "name" },
        { "header": "Company Number", "accessor": "companyNumber" },
        { "header": "VAT Number", "accessor": "vatNumber" },
        { "header": "Compliance Id", "accessor": "complianceId" }
    ]

    const transform = (organisation) => {
        return {
            "name": <Link action={`/organisations/${organisation.id}`}>{organisation.name}</Link>,
            "companyNumber": !!organisation.companyNumber ? organisation.companyNumber : "-",
            "vatNumber": !!organisation.vatNumber ? organisation.vatNumber : "-",
            "complianceId": "-"
        }
    }

    useEffect(() => {
        initFilteredDataTable({
            filterPath: "users/organisations/filters",
            dataPath: `users/${user.id}/organisations/search`,
            location: props.location,
            pageSize: 5,
            setFilterData,
            setDataError
        }).then(showFilter => setShowFilter(showFilter))
    }, [])

    return (
        <Stack row responsive>
            <HeaderSection flex="1" title="Details">
                <Box flex="1">
                    <Stack>
                        <Field label="Name" value={`${user.firstName} ${user.lastName}`} />
                        <Field label="Email" value={`${user.email} (${user.emailVerified ? "Verified" : "Unverified"})`} />
                        <Field label="Mobile" value={user.mobile} />
                        <Field label="Telephone" value={user.telephone} />
                        <Field label="Completed Signup" value={!!user.completedSignup ? "Yes" : "No"} />
                        <Field label="Suspended" value={!!user.suspendedOn ? formatDateTime(user.suspendedOn) : "-"} />
                        <Field label="Deleted" value={!!user.deletedOn ? formatDateTime(user.deletedOn) : "-"} />
                    </Stack>
                </Box>
            </HeaderSection>
            <Stack flex="1">
                <HeaderSection flex="1" title="Organisations">
                    <Box>
                        {
                            <LoadingScreen
                                hasData={filterData.data}
                                hasError={dataError}
                                render={() => (
                                    <Panel>
                                        <FilteredDataTable
                                            initData={filterData}
                                            showFilter={showFilter}
                                            setDataError={setDataError}
                                            transform={transform}
                                            columns={orgColumns}
                                            sortable={false}
                                            showResults={false} />
                                    </Panel>
                                )}
                            />
                        }
                    </Box>
                </HeaderSection>
            </Stack>
        </Stack>
    )
}

export default UserDetailsTab