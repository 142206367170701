import React, { Fragment, useEffect, useState } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import { formatDateTime } from 'helpers/date-helper'
import {
    ButtonStack,
    FilterButton,
    Box,
    HeaderSection,
    A
} from 'paid-ui-lib'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'

const UserEmailHistoryTab = ({props, userId}) => {
    const [filterData, setFilterData] = useState({})
    const [dataError, setDataError] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    useEffect(() => {
        initFilteredDataTable({
            filterPath: "users/email/filters",
            dataPath: `users/email/search/${userId}`,
            location: props.location,
            setFilterData,
            setDataError,
        }).then(showFilter => setShowFilter(showFilter))
    }, [])

    const transform = (data) => {
        return {
            id: data.id,
            subject: <A href={`/emails/${userId}/${data.id}`} target="_blank">{data.subject || "-"}</A>,
            sentOn: formatDateTime(data.timestamp)
        }
    }

    const columns = [
        { "header": "Subject", "accessor": "subject", "sortId": "1" },
        { "header": "Sent On", "accessor": "sentOn", "sortId": "2" },
    ].filter(i => !!i)

    return (
        <LoadingScreen
            hasData={filterData.data}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <Box>
                        <HeaderSection title="" actions={
                            <ButtonStack row ml="auto"> 
                                <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)}/>
                            </ButtonStack>}>
                            <FilteredDataTable
                                initData={filterData}
                                showFilter={showFilter}
                                setDataError={setDataError} 
                                transform={transform} 
                                columns={columns} />
                        </HeaderSection>
                    </Box>
                </Fragment>
            )}
        />
    )
}

export default UserEmailHistoryTab