import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import { formatDateTime } from 'helpers/date-helper'
import {
    PageTitle,
    PageContent,
    Stack,
    Field,
    HR,
    A,
    TabPanel,
    Tab,
    Code,
    Box
} from 'paid-ui-lib'

const EmailView = (props) => {
    const iFrameEle = useRef()
    const [ email, setEmail ] = useState(null)
    const { getRequest } = useRequest()
    const [ dataError, setDataError ] = useState(false)
    
    useEffect(() => {
        getRequest(`users/email/${props.match.params.userId}/${props.match.params.emailId}`)
        .then((res) => {
            setEmail(res)
        })
        .catch(() => {
            setDataError(true)
        })
    }, [])
    
    const iFrameLoad = () => {
        iFrameEle.current.height = window.frames.iFrame.document.body.scrollHeight + 20

        var iframe = document.getElementById("emailFrame")
        var style = document.createElement('style')

        style.textContent = 'body { overflow-x:hidden; }'
        iframe.contentDocument.head.append(style)
    }

    const formatEmailContact = (name, address) => {
        if (!!name) {
            return `${name} (${address})`
        }

        return address
    }

    const formatModelJson = (modelJson) => {
        if (!modelJson) {
            return null
        }

        const obj = JSON.parse(modelJson)
        const formattedJson = JSON.stringify(obj, null, 2)

        return formattedJson
    }

    return (
        <LoadingScreen
            hasData={!!email}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="View Email" description="" slim></PageTitle>
                    <PageContent slim>
                        <Stack>
                            <Stack row flex="2">
                                <Field flex="1" label="To" value={formatEmailContact(email.toName, email.to)} />
                                <Field flex="1" label="From" value={formatEmailContact(email.fromName, email.from)} />
                            </Stack>
                            <Stack row flex="2">
                                <Field flex="1" label="Subject" value={email.subject} />
                                <Field flex="1" label="Date" value={formatDateTime(email.timestamp)} />
                            </Stack>
                            <Stack row flex="2">
                                <Field flex="1" label="Template" value={!!email.templateName && <><A href={`/settings/email-templates/${email.templateId}`} target="_blank">{email.templateName}</A> (revision {email.templateRevision})</>} />
                            </Stack>

                            <TabPanel flex="1" height="100%" minWidth="30rem">
                                <Tab containerProps={{height: "100%"}} component={(
                                    <iframe
                                        id="emailFrame"
                                        name="iFrame"
                                        frameBorder={0}
                                        ref={iFrameEle}
                                        onLoad={iFrameLoad}
                                        srcDoc={email.body}
                                        className="iFrameClass"
                                        style={{ display: 'fixed', width: '70%'}} />
                                )}>Rendered</Tab>
                                <Tab component={(
                                    <Box style={{overflow: "hidden", overflowX: "auto", height: "30rem"}}>
                                        <Code>
                                            <pre>{formatModelJson(email.modelJson)}</pre>
                                        </Code>
                                    </Box>
                                )}>Model</Tab>
                            </TabPanel>
                        </Stack>
                    </PageContent>
                </Fragment>
            )} />
    )
}

export default EmailView