import React, { Fragment} from 'react'
import {
    P,
    Badge,
    Table,
    HeaderSection,
    formatDateTime,
    hasPermission,
    useAuthContext,
    Link
} from 'paid-ui-lib'
import NoDataScreen from "components/NoDataScreen"

const UsersTab = ({organisation}) => {
    const { permissions } = useAuthContext()
    const userHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Email", "accessor": "email" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Status", "accessor": "status" },
        { "header": "", "accessor": "actions"}
    ]

    const userTransform = (data) => {
        return {
            id: data.id,
            name: hasPermission(permissions, 700, true) ? <Link openInNewTab={true} href={`users/${data.id}`}>{data.name}</Link> : data.name,
            email: data.email,
            status: <Badge status={data.isSuspended ? 3 : 1}>{data.isSuspended ? "Suspended": "Active"}</Badge>,
            createdOn: formatDateTime(data.createdOn)
        }
    }

    return (
        <Fragment>
            <HeaderSection 
                title="Registered Users"
                headingMb={!!organisation.users.length ? 1 : null}>
                {
                    organisation.users.length === 0
                        ? <P>No Users Found</P>
                        : <Table columns={userHeadings} data={organisation.users} transform={userTransform} noData={<NoDataScreen />}/>
                }
            </HeaderSection>
        </Fragment>
    )
}

export default UsersTab