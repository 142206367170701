import React, { useState } from 'react'
import { useRequest } from 'components/use-request'
import useFormal from "@kevinwolf/formal-web";
import * as yup from "yup";
import {
    A,
    Box,
    Button,
    ButtonStack,
    Flex,
    Field,
    Icon,
    Input,
    PseudoElement,
    Span,
    Spinner,
    Stack,
    useToast,
    Alert,
    getUserData,
} from 'paid-ui-lib'
import QRCode from 'qrcode.react'

const AddAuthenticator = ({ data, onComplete }) => {
    const toast = useToast()
    const { postRequest } = useRequest()
    const [isVerifying, setIsVerifying] = useState(false)

    const submitVerificationCode = (code) => {
        setIsVerifying(true)
        postRequest(`auth/authenticator/link`, {
            code: code,
        })
            .then((res) => {
                setIsVerifying(false)

                const userData = getUserData()
                localStorage.setItem("userData", JSON.stringify({...userData, tfaEnabled: true}))

                toast({
                    slim: true,
                    position: "top-right",
                    title: "Success",
                    description: `Two Factor Authentication enabled`,
                    status: "success",
                })
                onComplete()
            })
            .catch(() => {
                setIsVerifying(false)
                toast({
                    slim: true,
                    position: "top-right",
                    title: "Failed",
                    description: `Two Factor Authentication failed`,
                    status: "error",
                })
            })
    }

    const formal = useFormal({
        code: "",
    }, {
        schema: yup.object().shape({
            code: yup
                .string()
                .required("Please provide an authenticator code"),
        }),
        onSubmit: values => {
            !!values && !!values.code && submitVerificationCode(values.code)
        }
    });

    return (
        <Stack>
            <Alert state="warning" text="You must set up Two Factor Authentication to use this platform."></Alert>
            <PseudoElement as="form" {...formal.getFormProps()}>
                <Box
                    isOpen
                    title="Authenticator"
                    footer={
                        <ButtonStack flex="1" row ml="auto">
                            <Button variant="secondary" onClick={onComplete} disabled={!!isVerifying}>Cancel</Button>
                            <Button type="submit" {...formal.getSubmitButtonProps()} isLoading={!!isVerifying} disabled={!!isVerifying}>Link</Button>
                        </ButtonStack>
                    }
                >
                    <Stack>
                        <Stack row>
                            <PseudoElement className="auth-item-number">1</PseudoElement>
                            <Stack>
                                <Span fontSize={18} fontWeight="bold">Download an authenticator app</Span>
                                <Stack row spacing={8} alignItems="center">
                                    <Span>
                                        <Stack>
                                            <A target="_blank" href="https://authy.com/download/"><Icon name="external-link" /> Authy</A>
                                            <A target="_blank" href="https://support.google.com/accounts/answer/1066447"><Icon name="external-link" /> Google Authenticator</A>
                                            <A target="_blank" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"><Icon name="external-link" /> Microsoft Authenticator</A>
                                        </Stack>
                                    </Span>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack row>
                            <PseudoElement className="auth-item-number">2</PseudoElement>
                            <Stack>
                                <Span fontSize={18} fontWeight="bold">Scan barcode or enter shared key</Span>
                                <Stack row>
                                    {
                                        !data
                                            ? (
                                                <Flex height="128px" width="128px">
                                                    <Spinner color="grey.500" m="auto" />
                                                </Flex>
                                            )
                                            : <QRCode value={data.authenticatorUri} />
                                    }
                                    <Field label="Shared Key" >
                                        {!!data && <Span letterSpacing="0.1rem">{data.formattedSharedKey}</Span>}
                                    </Field>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack row>
                            <PseudoElement className="auth-item-number">3</PseudoElement>
                            <Field errorMessage={formal.errors.code}>
                                <Stack>
                                    <Span fontSize={18} fontWeight="bold">Enter the 6 digit code generated</Span>
                                    <Input autoFocus {...formal.getFieldProps("code")} placeholder="Enter your code" />
                                </Stack>
                            </Field>
                        </Stack>
                    </Stack>
                </Box>
            </PseudoElement>
        </Stack>
    )
}

export default AddAuthenticator