import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import RolesRoutes from './routes/roles'
import EmailTemplatesRoutes from './routes/email-templates/routes'
import EmailSequencesRoutes from './routes/email-sequences/routes'

const SettingsRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/settings/roles" component={RolesRoutes} /> }
            <Route path="/settings/email-templates" component={EmailTemplatesRoutes} />
            <Route path="/settings/email-sequences" component={EmailSequencesRoutes} />

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default SettingsRoutes