import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    LayoutStack,
    Panel,
    Button,
    ButtonStack,
    Span,
    Stack,
    Field,
    useToast,
    DatePicker
} from 'paid-ui-lib'

const SuspendAdminDrawer = ({isOpen, userId, onClose, ...rest}) => {
    const toast = useToast()
    const { postRequest } = useRequest()
    const [loading, setLoading] = useState(false)
    const [canSubmit, setCanSubmit] = useState(true)
    const [suspensionDate, setSuspensionDate] = useState();

    useEffect(() => {    
        
    }, [])

    const suspendUser = () => {
        setLoading(true)
        let payload = {
            lockoutEndDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)).toJSON()
        }

        if (suspensionDate !== undefined) {
            payload.lockoutEndDate = new Date(suspensionDate).toJSON()
        }
        
        postRequest(`administrators/${userId}/suspend`, payload)
        .then(() => {
            toast({
                slim: true,
                position: "top-right",
                title: `Admin Suspended`,
                description: `Admin has been successfully suspended`,
                status: "success",
                isClosable: true
                })
            setLoading(false)
            onClose(true)
        })
        .catch(() => {
            setLoading(false)
        })
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={() => onClose(false)}
            size="lg"
            {...rest}>
            <DrawerOverlay />
            <DrawerContent>
                    <DrawerHeader>Suspend Administrator</DrawerHeader>
                    <DrawerBody>
                        <LayoutStack>
                            <Panel bAll round p={24}>
                                <Stack>
                                    <Field label="Please provide a Date when the suspension will end (empty is indefinitely)">
                                        <DatePicker    
                                            id="suspensionDatePicker"                        
                                            onChange={e => setSuspensionDate(e.date)}
                                            inline
                                        />
                                    </Field>
                                </Stack>
                            </Panel>
                        </LayoutStack>
                        <Stack row alignItems="center" justifyContent="flex-end" mt={16}>
                            <Span>Are you sure?</Span>
                            <ButtonStack row>
                                <Button variant="secondary" onClick={() => onClose(false)}>No, don't suspend user</Button>
                                <Button variant="destructive" onClick={suspendUser} disabled={!canSubmit} isLoading={loading}>Yes, suspend user</Button>
                            </ButtonStack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
        </Drawer>
    )
}

export default SuspendAdminDrawer