import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Stack,
    Field,
    formatDateTime,
    H4,
    HR,
} from 'paid-ui-lib'

const ChargesDrawer = ({organisationId, searchId, onClose, isOpen}) => {
    const { getRequest } = useRequest()
    const [ charges, setCharges ] = useState(undefined)
    const [ dataError, setDataError ] = useState(false)
    
    useEffect(() => {
        getRequest(`organisations/${organisationId}/charges/${searchId}`)
        .then((res) => {
                setCharges(res.charges)
        })
        .catch(() => {
            setDataError(true)
        })
    }, [])

    const getBadge = (status) => {
        if (status == "Pending" || status == "Warning") {
            return "info";
        }
        else if (status == "Success") {
            return "success";
        }
        else if (status == "Failed") {
            return "error";
        }
        else  {
            return "info";
        }

    }
    return (
        <LoadingScreen
            hasData={!!charges}
            hasError={dataError}
            render={() => (
                <Drawer
                    size="md"
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent rounded="0">
                        <DrawerHeader>Charges Detail</DrawerHeader>
                        <DrawerBody overflowY="scroll">
                            <Stack>
                            {
                                charges.length > 0 ? charges.map((charge, i) => { return (
                                    <Stack>
                                        <H4>Charge {i+1}</H4>
                                        <Field label="Classification" value={charge.classification} />
                                        <Field label="Description" value={charge.description} />
                                        <Field label="Persons Entitled" value={charge.personsEntitled.join(", ")} />
                                        <Field label="Created On" value={formatDateTime(charge.createdOn)} />
                                        <Field label="Delivered On" value={formatDateTime(charge.deliveredOn)} />
                                        <Field label="Status" value={charge.status} />
                                        <HR/>
                                    </Stack>
                                )}) :
                                <H4>No charges found during search.</H4>
                            }
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )} />

        
    )
}

export default ChargesDrawer