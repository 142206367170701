import React from 'react'
import { Redirect, Route as DomRoute } from 'react-router-dom'
import {
    Auth,
    getUserData
} from 'paid-ui-lib'
import LayoutWrapper from './components/LayoutWrapper'

const Route = ({ component, privateRoute, loggedInRedirect, layout, permission, ...rest }) => {
    const calculateView = (props) => {
        const tfaPath = "/account/tfa-setup/authenticator"
        const userData = getUserData()
        const tfaEnabled = !!userData ? userData.tfaEnabled : null
        
        if (privateRoute) {
            if (tfaEnabled === false && props.location.pathname !== tfaPath) {
                return (
                    <Auth allowSuperAdmin={true} permission={permission} unauthorised={<LayoutWrapper component={component} layout={layout} {...props}/>}>
                        <Redirect to={{ pathname: tfaPath, state: { from: props.location } }} />
                    </Auth>
                ) 
            }
            
            return (
                <Auth allowSuperAdmin={true} permission={permission} unauthorised={<Redirect to={{ pathname: "/login", state: { from: props.location } }} />}>
                    <LayoutWrapper component={component} layout={layout} {...props}/>
                </Auth>
            )
        }
        
        if (loggedInRedirect)
        {
            return (
                <Auth allowSuperAdmin={true} permission={permission} unauthorised={<LayoutWrapper component={component} layout={layout} {...props}/>}>
                    <Redirect to={{ pathname: tfaEnabled ? tfaPath : "/", state: { from: props.location } }} />
                </Auth>
            )
        }

        return <LayoutWrapper component={component} layout={layout} {...props}/>
    }

    return <DomRoute render={props => calculateView(props)} {...rest} />;
}

export default Route