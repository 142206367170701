import React, { useState } from 'react'
import {
    Button,
    Sub,
    ButtonStack,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalOverlay
} from 'paid-ui-lib'

const ConfirmModal = ({ isOpen, title = "Are you sure?", message, closeButtonText = "Close", buttonText, onClose, onConfirm, buttonColor, buttonVariant, children, ...rest }) => {
    const [submitting, setSubmitting] = useState()

    const onConfirmWrapper = () => {
        setSubmitting(true)

        if (onConfirm) {
            onConfirm()
                .then(() => {
                    setSubmitting(false)
                    onClose(true)
                })
                .catch(() => { setSubmitting(false) })
        } else {
            setSubmitting(false)
            onClose(true)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => onClose(false)} {...rest}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {
                        typeof (message) === "string"
                            ? <Sub>{message}</Sub>
                            : message
                    }
                    {!!children && children}
                </ModalBody>
                <ModalFooter>
                    <ButtonStack row>
                        {!!onClose && <Button variant="secondary" isDisabled={submitting} onClick={() => onClose(false)}>{closeButtonText}</Button>}
                        {!!onConfirm && <Button
                            {...(buttonVariant ? { variant: buttonVariant } : {})}
                            variantColor={buttonColor}
                            onClick={onConfirmWrapper}
                            isLoading={submitting}>
                            {buttonText}
                        </Button>}
                    </ButtonStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ConfirmModal