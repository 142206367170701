import React, { useEffect } from 'react'
import {
    Field,
    PseudoElement,
    Stack,
} from 'paid-ui-lib'

import PaymentOption from './components/PaymentOption'

const PaymentOptions = ({ enterpriseName, work, selectedId, errorMessage, isRequired, label, onSelect, taxLabel, paymentOptions }) => {

    const selectPaymentPlan = (paymentTerms) => {
        if (!!onSelect && !!paymentTerms && !!paymentTerms.available) {
            onSelect(paymentTerms);
        }
    }

    const chunk = (array, size) => {
        if (array.length <= size) {
            return [array]
        }
        return [array.slice(0, size), ...chunk(array.slice(size), size)]
    };

    const getProps = () => {
        let props = {};
        if (!!isRequired) { props.isRequired = true }
        if (!!label) { props.label = label; }
        return props;
    }

    return (
        <Field {...getProps()} errorMessage={errorMessage}>
            <Stack spacing={28}>
                <Stack responsive spacing={24}>
                    {
                        chunk(paymentOptions || [], 2).map((c, m) => {
                            return <Stack key={`row-${m}`} row responsive spacing={24}>
                                {
                                    c.map((o, i) => (<PaymentOption
                                        errorMessage={errorMessage}
                                        key={i}
                                        flex="1"
                                        paymentTerms={o}
                                        enterpriseName={enterpriseName}
                                        selected={selectedId === o.id}
                                        vatRegistered={!!work.vatRegistered}
                                        onClick={() => selectPaymentPlan(o)}
                                        cursor={!!onSelect && !o.disabled ? "pointer" : "arrow"}
                                        taxLabel={taxLabel}
                                    />))
                                }
                                {
                                    c.length < 2 && <PseudoElement key={`row-pe-${m}`} flex="1" />
                                }
                            </Stack>
                        })
                    }
                </Stack>
            </Stack>
        </Field>
    )
}

export default PaymentOptions