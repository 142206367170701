
import React, { useState, useEffect, useReducer } from 'react'
import {
    Stack
} from 'paid-ui-lib'
import DeliverableList from './components/DeliverableList'
import { generateUUID } from 'helpers/utilities-helper'

const trackedDeliverableReducer = (state, action) => {    
    switch(action.type){   
        case 'addDeliverable':
            return {
                ...state,
                addedDeliverables: [...state.addedDeliverables, action.value]
            }
        case 'editDeliverable':
            const addedDeliverableInd = state.addedDeliverables.findIndex(d => d.id === action.value.id)
            const editedDeliverableInd = state.editedDeliverables.findIndex(d => d.id === action.value.id)

            let added = state.addedDeliverables
            let edited = state.editedDeliverables

            if(addedDeliverableInd > -1) {
                const temp = [...state.addedDeliverables]
                temp.splice(addedDeliverableInd, 1, action.value)
                added = temp
            } else if (editedDeliverableInd > -1) {
                const temp = [...state.editedDeliverables]
                temp.splice(editedDeliverableInd, 1, action.value)
                edited = temp
            } else {
                edited = [...state.editedDeliverables, action.value]
            }

            return {
                ...state,
                addedDeliverables: added,
                editedDeliverables: edited
            }
        case 'removeDeliverable': 
            const addedDeliverables = state.addedDeliverables.filter(i => i.id !== action.value)
            const editedDeliverables = state.editedDeliverables.filter(i => i.id !== action.value)

            return {
                ...state,
                addedDeliverables: addedDeliverables,
                editedDeliverables: editedDeliverables,
                removedDeliverables: addedDeliverables.length !== state.addedDeliverables.length ? state.removedDeliverables : [...state.removedDeliverables, action.value]
            }
        default: 
            return state;
    }
}

const DeliverableEditor = ({
    deliverables,
    milestones,
    currencySymbol,
    allowFreeText,
    enableQuickAdd,
    onChange,
    appendOnly,
    readonly,
    onDeliverableSelected,
    trackedDeliverableChanges,
    onTrackedDeliverableChangesChanged,
    onCancelEditing,
    ...rest
}) => {
    const [selectedDeliverable, setSelectedDeliverable] = useState(null)
    const [deliverableTemplates, setDeliverableTemplates] = useState([])

    const [trackedDeliverables, setTrackedDeliverables] = useReducer(trackedDeliverableReducer, trackedDeliverableChanges || {
        addedDeliverables : [],
        editedDeliverables : [],
        removedDeliverables : []
    })

    useEffect(() => {
        if (onCancelEditing && !!selectedDeliverable) {
            selectDeliverable(null)
        }
    }, [onCancelEditing])

    useEffect(() => {
        onDeliverableSelected && onDeliverableSelected(!!selectedDeliverable)
    }, [selectedDeliverable])

    useEffect(() => {
        const changed =
            (trackedDeliverables.addedDeliverables && trackedDeliverables.addedDeliverables.length > 0) ||
            (trackedDeliverables.editedDeliverables && trackedDeliverables.editedDeliverables.length > 0) ||
            (trackedDeliverables.removedDeliverables && trackedDeliverables.removedDeliverables.length) > 0

        if(changed){
            onTrackedDeliverableChangesChanged && onTrackedDeliverableChangesChanged(trackedDeliverables)
        }
    }, [trackedDeliverables])
    
    const onCreateDeliverable = (milestoneId) => {
        const newDeliverable = {
            id: generateUUID(),
            isNew: true,
            milestoneId: milestoneId,
            deleteDisabled: true,
            description: "",
            additionalDescription: "",
            workItemId: "",
            workItemValue: "1",
            amount: 0,
            additionalFields: []
        }
        onChange && onChange([...deliverables, newDeliverable], false)
        setSelectedDeliverable(newDeliverable.id)

        setTrackedDeliverables({type:'addDeliverable', value: newDeliverable})
    }

    const onChangeDeliverable = (id, value) => {
        const temp = [...deliverables]
        const ind = temp.findIndex(t => t.id === id)
        
        if(ind > -1) {
            if(!value.workItemId && !allowFreeText){
                temp.splice(ind, 1)
                onChange && onChange(temp, true)
                setSelectedDeliverable(null)
                setTrackedDeliverables({type:'removeDeliverable', value: id})
            } else {
                temp.splice(ind, 1, value)
                onChange && onChange(temp, true)
                setSelectedDeliverable(null)
                setTrackedDeliverables({type:'editDeliverable', value: value})
            }
        }        
    }

    const onRemoveDeliverable = (id) => {
        const temp = [...deliverables]
        const ind = temp.findIndex(t => t.id === id)

        if(ind > -1) {
            temp.splice(ind, 1)
            onChange && onChange(temp, true)
            setSelectedDeliverable(null)
        }
        setTrackedDeliverables({type:'removeDeliverable', value: id})
    }

    const onCancel = (id, value) => {
        const temp = [...deliverables]
        const ind = temp.findIndex(t => t.id === id)
        
        if(ind > -1) {
            if(!value.workItemId && !allowFreeText){
                temp.splice(ind, 1)
                onChange && onChange(temp, false)
                setSelectedDeliverable(null)
                setTrackedDeliverables({type:'removeDeliverable', value: id})
            } else {
                onChange && onChange(temp, false)
                setSelectedDeliverable(null)
            }
        }
    }

    const selectDeliverable = (deliverable) => {
        if(selectedDeliverable) {
            const tempDeliverable = deliverables.find(t => t.id === selectedDeliverable)
            onCancel(tempDeliverable.id, tempDeliverable)
        }

        setSelectedDeliverable(deliverable)
    }

    return (
        <Stack {...rest}>                     
            {
                !!milestones.length
                ? milestones.map((m, i) => 
                    <DeliverableList 
                        key={m.id}
                        enableQuickAdd={enableQuickAdd}
                        label={m.name}
                        milestoneId={m.id}
                        showBreak={i > 0}
                        deliverableTemplates={deliverableTemplates}
                        currencySymbol={currencySymbol}
                        getDeliverableTemplates={() => {}}
                        deliverables={deliverables.filter(r => r.milestoneId === m.id)}
                        allowFreeText={allowFreeText}
                        appendOnly={appendOnly}
                        readonly={readonly}
                        onCancel={onCancel}
                        onEditDeliverable={selectDeliverable}
                        selectedDeliverable={selectedDeliverable}
                        onCreateDeliverable={onCreateDeliverable}
                        onChangeDeliverable={onChangeDeliverable}
                        onRemoveDeliverable={onRemoveDeliverable} />)
                : <DeliverableList 
                    label=""
                    milestoneId={null}
                    enableQuickAdd={enableQuickAdd}
                    currencySymbol={currencySymbol}
                    deliverableTemplates={deliverableTemplates}
                    getDeliverableTemplates={() =>{}}
                    deliverables={deliverables.filter(r => r.milestoneId === null)}
                    allowFreeText={allowFreeText}
                    appendOnly={appendOnly}
                    readonly={readonly}
                    onCancel={onCancel}
                    onEditDeliverable={selectDeliverable}
                    selectedDeliverable={selectedDeliverable}
                    onCreateDeliverable={onCreateDeliverable}
                    onChangeDeliverable={onChangeDeliverable}
                    onRemoveDeliverable={onRemoveDeliverable} />
            }
        </Stack>
    )
}

export default DeliverableEditor