import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import OutgoingPaymentsView from './routes/outgoing'
import HeldOutgoingPaymentsList from './routes/held-outgoing'

const SupplierRoutes = () => {
    return (
        <Switch>
            <Route exact path="/payments/outgoing-held" component={HeldOutgoingPaymentsList} />
            <Route exact path="/payments/outgoing" component={OutgoingPaymentsView} />
            <Redirect from="/payments/outgoing/:reference" to="/payments/outgoing" />

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default SupplierRoutes