import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Button,
    useToast,
    Stack,
    ButtonStack,
    Field,
    Input,
    Checkbox,
    addOrRemoveArray,
    Panel,
    PseudoElement,
    H2
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import useFormal from "@kevinwolf/formal-web";
import * as yup from "yup";

const RoleDrawer = ({isOpen, selectedRoleId, onClose}) => {
    const toast = useToast()

    const { getRequest, putRequest, postRequest } = useRequest()
    const [permissions, setPermissions] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [adminRole, setAdminRole] = useState(false);
    
    useEffect(() => {
        getRequest('roles/permissions')
        .then((res) => {
            setPermissions(res.items)
        })
        
        if(!!selectedRoleId) {
            getRequest(`roles/${selectedRoleId}`)
                .then((roleResult) => {
                    Object.keys(roleResult).forEach(key => formal.change(key, roleResult[key] || ""))
                    setAdminRole(roleResult.name == "Admin")
                })
                .catch(() => { })
        } else {
            formal.change("name", "")
            formal.change("permissions", [])
            setLoaded(true)          
        }
    }, [selectedRoleId])

    useEffect(() => {
        setLoaded(true)
    }, [adminRole])

    const formal = useFormal({
        name: "",
        permissions: []
    }, {
        schema: yup.object().shape({
            name: yup.string().required("Name is a required field")
        }),
        onSubmit: values => {
            if (!!selectedRoleId) {
                return putRequest(`roles/${selectedRoleId}`, values)
                .then(() => {
                    toast({
                        slim: true,
                        position: "top-right",
                        title: "Role Updated",
                        description: "Role successfully updated",
                        status: "success",
                        isClosable: true
                    })
                    onClose(true)
                })
                .catch(() => {})
            } else {
                return postRequest(`roles`, values)
                .then(() => {
                    toast({
                        slim: true,
                        position: "top-right",
                        title: "Role created",
                        description: "Role successfully created",
                        status: "success",
                        isClosable: true
                    })
                    onClose(true)
                })
                .catch(() => {})
            }
        }
    });
    
    return (
        <Drawer size="lg" isOpen={isOpen} placement="right" onClose={() => {setAdminRole(undefined); onClose()}}>
            <DrawerOverlay />
            <LoadingScreen
                hasData={loaded}
                hasError={false}
                render={() => (
                <DrawerContent>
                    <PseudoElement as="form" {...formal.getFormProps()}>
                    <DrawerHeader>
                        {!!selectedRoleId ? "Edit Role" : "Create Role"}
                    </DrawerHeader>
                    <DrawerBody>
                    <Panel bAll round p={24}>
                            <Stack>
                                {
                                    adminRole ? <Field label="Name" value="Admin" /> : 
                                    <Field label="Name" isRequired errorMessage={formal.errors.name}>
                                        <Input {...formal.getFieldProps("name")} />
                                    </Field>
                                }
                                <Field label="Permissions" errorMessage={formal.errors.name}>
                                    {
                                        loaded &&
                                        <Stack spacing={4}>
                                            {
                                                permissions.map(p => (
                                                    <Checkbox
                                                        key={p.id}
                                                        id={p.id}
                                                        aria-describedby={null}
                                                        value={p.id}
                                                        name={p.name}
                                                        onChange={() => {}}
                                                        disabled={adminRole}
                                                        checked={adminRole || formal.getFieldProps("permissions").value.indexOf(p.id) !== -1}
                                                        onClick={e => formal.change("permissions", addOrRemoveArray(formal.getFieldProps("permissions").value, p.id))}>
                                                        {p.name}
                                                    </Checkbox>))
                                            }
                                        </Stack>
                                    }
                                </Field>
                            </Stack>
                            </Panel>
                        <DrawerFooter>
                            <ButtonStack row>
                                <Button variant="secondary" onClick={() => {setAdminRole(undefined); onClose(false)}}>
                                    Cancel
                                </Button>
                                <Button type="submit" {...formal.getSubmitButtonProps()} isLoading={formal.isSubmitting} disabled={adminRole}>
                                    {!!selectedRoleId ? "Update" : "Save"}
                                </Button>
                            </ButtonStack>
                        </DrawerFooter>
                    </DrawerBody>
                    </PseudoElement>
                </DrawerContent>
            )}
        />
    </Drawer>
    )
}

export default RoleDrawer