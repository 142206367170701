import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import ListEnterpriseView from './routes/list'
import DetailsView from './routes/view'

const EnterpriseRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/enterprise" component={ListEnterpriseView} /> }
            { <Route exact path="/enterprise/:enterpriseId" component={DetailsView} /> }

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default EnterpriseRoutes