import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    A,
    Badge,
    Box,
    ButtonStack,
    Flex,
    HeaderSection,
    Icon,
    IconButton,
    Span,
    Stack,
    Strong,
} from 'paid-ui-lib'

import DesktopView from 'components/DesktopView'
import MobileView from 'components/MobileView'

const TFAOptions = ({ onClose, user }) => {
    const history = useHistory()

    return (
        <Box>
            <HeaderSection
                title="Two factor authentication">
                <Stack>
                    {
                        <Stack spacing={12} flex="1" className="tfa-options">
                            <Box onClick={() => history.push("/account/tfa-setup/authenticator")} cursor="pointer">
                                <DesktopView>
                                    <Stack row flex="1">
                                        <Stack row flex="1">
                                            <Strong>Authenticator app</Strong>
                                            {user.authenticatorEnabled && <Badge mr={8} status="success">Enabled</Badge>}
                                        </Stack>
                                        <Stack row ml="auto">
                                            <Span lightText>{user.authenticatorEnabled ? "Edit" : "Set up"} authenticator</Span>
                                            <Icon name="chevron-right" color="grey.500" />
                                        </Stack>
                                    </Stack>
                                </DesktopView>
                                <MobileView>
                                    <Stack row flex="1" alignItems="center">
                                        <Stack flex="1" spacing={8}>
                                            <Strong>Authenticator app</Strong>
                                            {user.authenticatorEnabled && <Badge status="success">Enabled</Badge>}
                                            <Span lightText>{user.authenticatorEnabled ? "Edit" : "Set up"} authenticator</Span>
                                        </Stack>
                                        <Stack>
                                            <Icon name="chevron-right" color="grey.500" />
                                        </Stack>
                                    </Stack>
                                </MobileView>
                            </Box>
                        </Stack>
                    }
                    <Span lightText>By enabling Two Factor Authentication you will be required to enter a code every time you log in. An Authenticator App will generate the login code on your own device.</Span>
                </Stack>
            </HeaderSection>
        </Box>
    )
}

export default TFAOptions