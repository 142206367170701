import React, { useState, useEffect, Fragment } from 'react'
import { useRequest } from 'components/use-request'
import { formatDateTime } from 'helpers/date-helper'
import {
    Flex,
    IconButton,
    Button,
    useToast,
    ButtonStack,
    PageContent,
    PageTitle,
    FilterButton,
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'
import RoleDrawer from './components/RoleDrawer'

const ListRolesView = (props) => {
    const toast = useToast()
    const { deleteRequest, getRequest } = useRequest()
    const [ showFilter, setShowFilter ] = useState(false)
    const [ filterData, setFilterData ] = useState({})
    const [ dataError, setDataError ] = useState(false)
    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [ activeFilters, setActiveFilters ] = useState([])
    const [ selectedRoleId, setSelectedRoleId ] = useState(undefined)

    const columns = [
        { "header": "Name", "accessor": "name" },
        { "header": "Created", "accessor": "createdOn" },
        { "header": "", "accessor": "actions" }
    ]

    const initTable = () => {
        initFilteredDataTable({
            filterPath: "roles/search", 
            dataPath: "roles/search", 
            location: props.location, 
            setFilterData, 
            setDataError
        }).then(showFilter => setShowFilter(showFilter))
    }

    useEffect(() => {
        initTable()
    }, [])

    const getData = () => {
        setFilterData({})
        initTable()
    }
    
    const onDelete = (itemId) => {
        return deleteRequest(`roles/${itemId}`)
        .then(() => {
            toast({
                slim: true,
                position: "top-right",
                title: "Role Deleted",
                description: "Role succesfully deleted",
                status: "success",
                isClosable: true
            })

            getData()
        })
    }
    
    const transform = (data) => {
        return {
            id: data.id,
            name: data.name,
            createdOn: formatDateTime(data.createdOn),
            actions: (
                <Flex width="100%">
                    <ButtonStack row compact ml="auto">
                        <IconButton variant="secondary" isSmall onClick={() => { setSelectedRoleId(data.id); setDrawerOpen(true)}} icon="edit" />
                        {data.name != "Admin" && <IconButton variant="secondary" isSmall onClick={() => onDelete(data.id)} icon="trash-b" />}
                    </ButtonStack>
                </Flex>
            )
        }
    }

    return (
        <LoadingScreen
            hasData={filterData.data}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <RoleDrawer 
                        onClose={() => {setDrawerOpen(false); setSelectedRoleId(undefined)}} 
                        isOpen={drawerOpen}
                        selectedRoleId={selectedRoleId}/>
                    <PageTitle 
                        slim
                        title="Roles"
                        description="Manage user roles">                            
                        <ButtonStack row spacing="2">
                        <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                            <Button as="a" onClick={() => setDrawerOpen(true)}>Create New Role</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <FilteredDataTable 
                            initData={filterData}
                            showFilter={showFilter}
                            setDataError={setDataError} 
                            transform={transform} 
                            columns={columns}
                            setParentActiveFilters={setActiveFilters}
                        />
                    </PageContent>
                </Fragment>
            )}
            />
    )
}

export default ListRolesView