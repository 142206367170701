import React from 'react'
import { Switch, Route } from 'react-router-dom'

import EditView from './routes/edit'
import ListView from './routes/list'
import StagingView from './routes/staging'

import NotFoundView from '../../../404'

const EmailTemplateRoutes = () => {
    return (
        <Switch>
            <Route exact path="/settings/email-templates" component={ListView} />
            <Route exact path="/settings/email-templates/staging" component={StagingView} />
            <Route exact path="/settings/email-templates/:templateId" component={EditView} />

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default EmailTemplateRoutes