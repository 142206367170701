import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import IncompatableBrowserScreen from 'components/IncompatableBrowserScreen'
import SplashScreen from 'components/SplashScreen'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Fragment>
        <IncompatableBrowserScreen render={() => (
            <Suspense fallback={<SplashScreen />}>
                <App />
            </Suspense>
        )}/>        
    </Fragment>, rootElement);

registerServiceWorker();

