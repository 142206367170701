import React, { useState, useEffect, Fragment } from 'react'
import DashboardGraph from './components/dashboard-graph'
import DashboardStat from './components/dashboard-stat'
import LoadingScreen from 'components/LoadingScreen'
import {
    PageTitle,
    PageContent,
    LayoutStack,
    Box,
    H2,
    getUserData,
    ButtonStack,
    PseudoElement,
    Select,
    Flex,
    Icon,
    useAuthContext,
    Stack,
    P,
    Link
} from 'paid-ui-lib'
import {
    useRequest
} from 'components/use-request'

const DashboardView = (props) => {
    const { getRequest } = useRequest()
    const [loading, setLoading ] = useState(true)
    const [userObject, setUserObject] = useState()
    const { permissions } = useAuthContext()
    const [dataError, setDataError] = useState(false)
    const [dashboardData, setDashboardData] = useState(undefined)
    const [selectedOrganisation, setSelectedOrganisation] = useState("")
    const moneyStringLocale = {minimumFractionDigits: 2, maximumFractionDigits: 2}

    useEffect(() => {
        setUserObject(getUserData())

        getRequest(`dashboard`)
            .then(res => {
                setDashboardData(res)
                setLoading(false)
            })
            .catch(() => {
                setDataError(true)
            })
    }, [])

    useEffect(() => {
        getRequest(`dashboard?organisationId=${selectedOrganisation}`)
            .then(res => {
                setDashboardData(res)
                setLoading(false)
            })
            .catch(() => {
                setDataError(true)
            })
    }, [selectedOrganisation])

    
    const getGreeting = () => {
        var currentTimeHours = new Date().getHours();

        const msg = currentTimeHours < 12
            ? "morning"
            : currentTimeHours < 17
                ? "afternoon"
                : "evening"

        return !!userObject ? `Good ${msg}, ${userObject.fullname}` : `Good ${msg}`
    }

    const goToInvoices = () => {
        props.history.push({
            pathname: "/invoices/supplier-invoices", 
            state: {
                filters: [
                    {
                        "id": "8ee4a442-aeb5-47ca-83fc-b64cb5005939",
                        "filter": {
                            "id": "6",
                            "values": {
                                "0": "1",
                                "1": "30",
                                "2": "-1",
                                "4": "-1"
                            }
                        },
                        "text": [
                            {
                                "value": "Due Date",
                                "isSubject": true                                
                            },
                            {
                                "value": "In the next"
                            },
                            {
                                "value": "30 Days"
                            }
                        ]
                    }
                ]
            }
        })
    }

    return (        
        <Fragment>
            { permissions.length == 0 ?
                <Flex height={["50vh","80%","80%"]}>
                    <Stack m="auto" spacing={8} alignItems="center">
                        <Icon name="alert-triangle" size={96} color="blue.600"/>
                        <H2>Account needs setting up</H2>
                        <P textAlign="center">Please wait for a member of the team to configure your account.</P>                
                        <P textAlign="center">In the mean time, please set up Two-Factor Authentication <Link action={`/account`}>here</Link>.</P>                
                    </Stack>
                </Flex> :  
                <Fragment>
                    <PageTitle slim title={getGreeting()} description="Here's an overview of Paid" backgroundColor="transparent" bb={false} disableBottomMargin>
                        <ButtonStack row>
                            <PseudoElement>
                                <Select value={selectedOrganisation} onChange={(e) => {
                                    setSelectedOrganisation(e.target.value)
                                    setLoading(true)
                                }}>
                                    <option value="">All Organisations</option>
                                    {
                                        !!dashboardData && dashboardData.organisations.map(org => (
                                            <option key={org.id} value={org.id}>{org.name}</option>))
                                    }
                                </Select>
                            </PseudoElement>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <LoadingScreen
                            hasData={!loading}
                            hasError={dataError}
                            spinnerSize={64}
                            render={() => (
                                <LayoutStack spacing={24}>
                                    <LayoutStack row responsive>
                                        <LayoutStack row flex="2">
                                            <DashboardStat icon="dollar-sign" title="Platform Fees (this month)" value={`£${dashboardData.paidProfit.toLocaleString(undefined, moneyStringLocale)}`} primaryStat />
                                            <DashboardStat icon="new" title="Invoices due in 30 days" value={`£${dashboardData.invoiceDueValue.toLocaleString(undefined, moneyStringLocale)}`} onClick={() => goToInvoices()} />
                                            <DashboardStat icon="dollar-sign" title="Express Payments" value={`${dashboardData.expressRate.toFixed(0)}%`}/>
                                            <DashboardStat icon="alert-triangle" title="Work to complete" value={dashboardData.workToComplete || 0}  />
                                            <DashboardStat icon="dollar-sign" title="Open Work Value" value={`£${dashboardData.openWorkValue.toLocaleString(undefined, moneyStringLocale)}`} />
                                        </LayoutStack>
                                    </LayoutStack>
                                    <Box title="Work history" smallTitle>
                                        <DashboardGraph graph={dashboardData.graph} height="19rem" />
                                    </Box>
                                </LayoutStack>
                            )}
                            />
                    </PageContent>
                </Fragment>
            }
        </Fragment>
    )
}

export default DashboardView