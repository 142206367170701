import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import {
    useRequest,
    Table,
    PageTitle,
    PageContent,
    ButtonStack,
    Button,
    Link
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'

const columns = [
    { "header": "Name", "accessor": "name" },
]

const transform = (data) => {
    return {
        name: <Link action={`email-sequences/${data.id}`}>{data.name}</Link>,        
    }
}

const EmailSequenceListView = () => {
    const { getRequest, postRequest } = useRequest()
    const history = useHistory()

    const [sequences, setSequences] = useState(null)

    useEffect(() => {
        getRequest("email-sequences")
        .then(res => {
            setSequences(res.items)
        })
    }, [])

    const onCreateClicked = () => {
        postRequest("email-sequences")
        .then(res => {
            if (!res || !res.id) {
                return
            }

            history.push(`email-sequences/${res.id}`)
        })
    }

    return (
        <LoadingScreen 
            hasData={!!sequences}
            render={() => (
                <Fragment>
                    <PageTitle 
                        title="Email Sequences" 
                        description="" 
                        slim
                    >
                        <ButtonStack row>
                            <Button onClick={onCreateClicked}>Create Sequence</Button>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <Table 
                            columns={columns}
                            transform={transform}
                            data={sequences}
                        />
                    </PageContent>
                </Fragment>
            )}
        />
        
    )
}

export default EmailSequenceListView