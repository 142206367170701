import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import SupplierInvoicePeriods from './routes/supplier-invoices'
import TransactionalInvoicePeriods from './routes/transaction-invoices'

const InvoicesRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/invoices/supplier-invoices" component={SupplierInvoicePeriods} /> }
            { <Route exact path="/invoices/transaction-invoices" component={TransactionalInvoicePeriods} /> }

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default InvoicesRoutes