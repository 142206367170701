export const getQueryParameters = (search) => {
    const queryData = [];
    const query = search.startsWith('?') ? search.substring(1) : search;
    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair.length > 1) {
            queryData.push({ name: pair[0], urlProp: pair[0] , value: decodeURIComponent(pair[1]) });
        }
    }

    return queryData;
}

export const createQueryString = (params) => {
    const keys = !!params ? Object.keys(params) : []

    return !!keys.length 
        ? "?" + keys
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join('&')
        : "";
}

export const getQueryVariableFromParameter = (queryParameters, variable) => {
    var queryParam = queryParameters.find(q => q.name === variable);
    return !!queryParam ? queryParam.value : null;
}

export const getQueryVariable = (search, variable) => {
    var queryParams = getQueryParameters(search);
    var queryParam = queryParams.find(q => q.name === variable);
    return !!queryParam ? queryParam.value : null;
}

export const getQueryPageData = (search) => {
    let queryParams = getQueryParameters(search)

    const pageStr = getQueryVariableFromParameter(queryParams, "page")
    const pageSize = getQueryVariableFromParameter(queryParams, "pageSize")
    return {
        page: pageStr ? parseInt(pageStr, 10) : 1,
        pageSize: pageSize ? parseInt(pageSize, 10) : 20
    }
}

export const flatternQueryParameters = (queryParameters, keyProp = "name") => {
    let queryData = "";
    for (let i = 0; i < (queryParameters || []).length; i++) {
        const queryParam = queryParameters[i];
        if(queryParam.value !== null || queryParam.value !== undefined){
            queryData += `${i === 0 ? "?" : "&"}${queryParam[keyProp]}=${queryParam.value}`;
        }
    }
    return queryData;
}

const paginationParams = ["page", "pageSize", "continuationToken", "currentContinuationToken"]

export const paginationParmsOnly = (queryParameters) => {
    const queryParametersLen = queryParameters.length

    for (let index = 0; index < queryParametersLen; index++) {
        const queryParameter = queryParameters[index];
        if(paginationParams.indexOf(queryParameter.name) === -1){
            return false
        }        
    }

    return true;
}

export const combineParameters = (queryParameters, newQueryParameters, keyProp = "name") => {
    let res = [...queryParameters]
    newQueryParameters.forEach(nqp => {
        const existingInd = res.findIndex(qp => qp[keyProp] === nqp[keyProp]);
        if(existingInd > -1){
            res[existingInd] = nqp
        } else {
            res.push(nqp)
        }
    });
    return res
}

export const createUrl = (path) => {
    return window._paidApiHost + "/" + path
}

export const queryParamGenerator = (queryParameters) => {
    const keys = !!queryParameters ? Object.keys(queryParameters) : []

    return keys.reduce((result, key, index, array) => {
        const objKeys = !!queryParameters[index] ? Object.keys(queryParameters[index]) : []
        
        return result += `${objKeys}=${queryParameters[index][objKeys]}${index!==array.length-1 ? "&" : ""}`;
      }, "?")
}