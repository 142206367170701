import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFoundView from '../404'
import AccountView from './routes/view'
import AuthenticatorSetup from './routes/view/components/tfa-setup'

const BuyerRoutes = () => {
    return (
        <Switch>
            { <Route exact path="/account" component={AccountView} /> }
            <Route path="/account/tfa-setup/authenticator" component={AuthenticatorSetup} privateRoute />

            {/* 404 */}
            <Route component={NotFoundView} />
        </Switch>
    )
}

export default BuyerRoutes