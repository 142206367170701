import React, { Fragment, useEffect, useState } from 'react'

import {
    useRequest,
    PageTitle,
    PageContent,
    ButtonStack,
    Button,
    Stack,
    PseudoElement,
    Box,
    Field,
    Flex,
    Input,
    IconButton,
    Span,
    Icon,
    Checkbox,
    Strong,
    Select,
    Panel,
    useToast
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'
import EmailTemplateSelector from './components/EmailTemplateSelector'

const trySplit = (stringVal, splitChar, index) => {
    if (!stringVal) return ""
    const split = stringVal.split(splitChar)
    if (split.length <= index) return ""
    return split[index]
}

const ReplyChannels = [
    { 
        label: "Support", 
        value: "support", 
        tokenParts: [
            { label: "Supplier ID", key: "supplierId", getter: token => trySplit(token, ":", 0) },
            { label: "User ID", key: "userId", getter: token => trySplit(token, ":", 1) }
        ], 
        tokenConstructor: ({ supplierId, userId }) => `${supplierId}:${userId}` 
    },
    { 
        label: "Work", 
        value: "work",
        tokenParts: [
            { label: "Work ID", key: "workId", getter: token => trySplit(token, ":", 0) },
            { label: "User ID", key: "userId", getter: token => trySplit(token, ":", 1) }
        ], 
        tokenConstructor: ({ workId, userId }) => `${workId}:${userId}` 
    }
]

const EmailSequenceEditView = ({ match }) => {
    const [sequence, setSequence] = useState(null)

    const { getRequest, postRequest } = useRequest()
    const toast = useToast()

    const sequenceId = match.params.sequenceId

    useEffect(() => {
        getRequest(`email-sequences/${sequenceId}`)
        .then(res => {
            setSequence(res)
        })
    }, [match.params.sequenceId])

    const onUpdateClicked = () => {
        postRequest(`email-sequences/${sequenceId}`, {
            ...sequence
        }).then(res => {
            toast({
                slim: true,
                position: "top-right",
                title: "Sequence updated",
                description: "The sequence was successfully updated",
                status: "success",
                isClosable: true
            })            
        }).catch(err => {
            console.error(err)

            toast({
                slim: true,
                position: "top-right",
                title: "Error updating sequence",
                description: "There was an error updating the sequence",
                status: "error",
                isClosable: true
            })
        })
    }

    const onAddStepClicked = () => {
        setSequence({
            ...sequence,
            steps: [...(sequence.steps || []), { templateId: null, waitMinutes: 0 }]
        })
    }

    const onStepChanged = (index, step) => {
        const temp = [...sequence.steps]
        temp.splice(index, 1, step)

        setSequence({
            ...sequence,
            steps: temp
        })
    }

    const onStepDelete = (index) => {
        const temp = [...sequence.steps]
        temp.splice(index, 1)

        setSequence({
            ...sequence,
            steps: temp
        })
    }

    const onStepMove = (index, delta) => {
        const temp = [...sequence.steps]
        const [step] = temp.splice(index, 1)
        temp.splice(index + delta, 0, step)

        setSequence({
            ...sequence,
            steps: temp
        })
    }

    return (
        <LoadingScreen hasData={!!sequence} render={() => (
            <Fragment>
                <PageTitle title={<Input value={sequence.name} onChange={e => setSequence({...sequence, name: e.target.value})} fontSize="1.4rem" fontWeight="bold" />} slim>
                    <ButtonStack>
                        <Button onClick={onUpdateClicked}>Update</Button>
                    </ButtonStack>
                </PageTitle>
                <PageContent slim>
                    <Stack>
                        <Box>
                            <Flex row>                
                                <Field label="Reply To Email" flex={1}>
                                    <Input value={sequence.forwardRepliesTo} onChange={e => setSequence({...sequence, forwardRepliesTo: e.target.value})} />
                                </Field>
                                <Field label="Reply To Name" flex={1} ml={8}>
                                    <Input value={sequence.forwardRepliesToName} onChange={e => setSequence({...sequence, forwardRepliesToName: e.target.value})} />
                                </Field>
                            </Flex>
                        </Box>
                        <PseudoElement>
                            <Button variant="secondary" onClick={onAddStepClicked}>Add Step</Button>
                        </PseudoElement>
                        <Stack>
                            {sequence.steps.map((s, i) => (
                                <SequenceStep 
                                    key={i} 
                                    step={s} 
                                    onChange={step => onStepChanged(i, step)} 
                                    onDelete={() => onStepDelete(i)} 
                                    onMove={delta => onStepMove(i, delta)} 
                                    index={i}
                                    maxIndex={sequence.steps.length - 1}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </PageContent>
            </Fragment>
        )} />        
    )
}

const SequenceStep = ({ step, onChange, onDelete, onMove, index, maxIndex }) => {
    const [collapsed, setCollapsed] = useState(true)
    const [templateName, setTemplateName] = useState(null)

    const { getRequest } = useRequest()

    // todo bad
    useEffect(() => {
        if (!step || !step.templateId || (!!step.template && step.template.id === step.templateId)) {
            return
        }

        getRequest(`email-templates/${step.templateId}`)
        .then(res => {
            setTemplateName(res.info.name)
        })
    }, [step.templateId])

    const onTemplateChanged = (value) => {
        setTemplateName(value.name)

        onChange({...step, templateId: value.id, template: value})
    }

    return (
        <Flex row>
            <PseudoElement width="3rem" pt={32}>
                <Span fontSize="1.4rem" fontWeight="bold">{index + 1}.</Span>
            </PseudoElement>
            <Box flex={1}>
                <Stack>
                    <Flex row alignItems="center">
                        <PseudoElement>
                            <Icon name="chevron-right" fontSize="1.4rem" style={{cursor: "pointer"}} onClick={() => setCollapsed(!collapsed)} />
                        </PseudoElement>
                        <PseudoElement flex={1} ml={16}>
                            <Span><Strong>{templateName}</Strong> after <Strong>{step.waitMinutes/60}</Strong> hours</Span>
                        </PseudoElement>
                        <PseudoElement>
                            <ButtonStack row ml={8}>
                                <IconButton variant="secondary" icon="arrow-up" onClick={() => onMove(-1)} disabled={index === 0} />
                                <IconButton variant="secondary" icon="arrow-down" onClick={() => onMove(1)} disabled={index === maxIndex} />
                                <IconButton variant="destructive" icon="x" onClick={onDelete} />
                            </ButtonStack>
                        </PseudoElement>
                    </Flex>
                    {!collapsed && (
                        <Fragment>
                            <Flex row alignItems="flex-end">
                                <Field label="Template" flex={1}>
                                    <EmailTemplateSelector tags={["Sequencing"]} value={!!step.template ? step.template : step.templateId} onChange={onTemplateChanged} />
                                </Field>
                                <Field label="Delay (hours)" flex={1} ml={8}>
                                    <Input value={step.waitMinutes/60} onChange={e => onChange({...step, waitMinutes: parseInt(e.target.value) * 60})} type="number" />
                                </Field>                    
                            </Flex>
                            <OptionalSection label="Override Sender" enabled={!!step.from} onChange={value => {
                                if (!value) {
                                    onChange({...step, from: null, fromName: null})
                                }
                            }}>
                                <Flex row>                
                                    <Field label="Sender Email (Liquid)" helpMessage="Either the plaintext email for the sender (e.g. 'example@google.com'), or a Liquid template string (e.g. '{{BuyerUser.Email}}')" flex={1}>
                                        <Input value={step.from} onChange={e => onChange({...step, from: e.target.value})} />
                                    </Field>
                                    <Field label="Sender Name (Liquid)" flex={1} ml={8}>
                                        <Input value={step.fromName} onChange={e => onChange({...step, fromName: e.target.value})} />
                                    </Field>
                                </Flex>
                            </OptionalSection>
                            <OptionalSection label="Override Recipient" enabled={!!step.to} onChange={value => {
                                if (!value) {
                                    onChange({...step, to: null, toName: null})
                                }
                            }}>
                                <Flex row>                
                                    <Field label="Recipient Email (Liquid)" helpMessage="Either the plaintext email for the recipient (e.g. 'example@google.com'), or a Liquid template string (e.g. '{{BuyerUser.Email}}')" flex={1}>
                                        <Input value={step.to} onChange={e => onChange({...step, to: e.target.value})} />
                                    </Field>
                                    <Field label="Recipient Name (Liquid)" flex={1} ml={8}>
                                        <Input value={step.toName} onChange={e => onChange({...step, toName: e.target.value})} />
                                    </Field>
                                </Flex>
                                <Flex row>
                                    <Field label="CC original recipient" flex={1}>
                                        <Checkbox checked={step.cc} onChange={e => onChange({...step, cc: e.target.checked})} />
                                    </Field>
                                </Flex>
                            </OptionalSection>
                            <Flex row>
                                <Field label="Allow Replies" flex={1}>
                                    <Checkbox checked={step.allowReplies} onChange={e => onChange({...step, allowReplies: e.target.checked})} />
                                </Field>
                            </Flex>
                            {/* <OptionalSection label="Allow Replies" enabled={step.allowReplies} onChange={value => onChange({...step, allowReplies: value, replyChannel: ReplyChannels[0].value})}>
                                <Flex row>
                                    <Field label="Reply channel" flex={1}>
                                        <Select value={step.replyChannel} onChange={e => onChange({...step, replyChannel: e.target.value})}>
                                            <option key={0} value={null} disabled></option>
                                            {ReplyChannels.map(c => (
                                                <option key={c.value} value={c.value}>{c.label}</option>
                                            ))}
                                        </Select>
                                    </Field>
                                    <PseudoElement flex={1} ml={8} />
                                </Flex>
                                <ReplyTokenEditor replyChannel={step.replyChannel} value={step.replyToken} onChange={value => onChange({...step, replyToken: value})} />
                            </OptionalSection> */}
                        </Fragment>
                    )}
                </Stack>
            </Box>
        </Flex>
    )
}

const optionalSectionEnabledStyle = {
    position: "relative", 
    zIndex: "100", 
    backgroundColor: "#fff", 
    width: "fit-content", 
    paddingRight: "1rem", 
    paddingBottom: "1rem"
}

const OptionalSection = ({ enabled, onChange, label, children }) => {
    const [isEnabled, setIsEnabled] = useState(enabled)

    const onCheckedChanged = (value) => {
        setIsEnabled(value)

        if (!!onChange) {
            onChange(value)
        }
    }

    return (
        <Stack spacing={0}>
            <Checkbox checked={isEnabled} onChange={e => onCheckedChanged(e.target.checked)} style={(isEnabled ? optionalSectionEnabledStyle : {})}>{label}</Checkbox>
            {!!isEnabled && (
                <Panel bAll p={24} pt={32} ml="0.4rem" borderRadius="default" style={{marginTop: "-1.45rem"}}>
                    <Stack>
                        {children}
                    </Stack>
                </Panel>
            )}
        </Stack>
    )
}

const ReplyTokenEditor = ({ replyChannel, value, onChange }) => {
    const replyChannelData = ReplyChannels.find(c => c.value === replyChannel)
    const replyTokenData = {}

    if (!!replyChannelData) {
        for (const tokenPart of replyChannelData.tokenParts) {
            let tokenPartValue = ""

            if (!!value) {
                tokenPartValue = tokenPart.getter(value)
            }

            replyTokenData[tokenPart.key] = tokenPartValue
        }
    }

    const onPartChanged = (key, value) => {
        const token = replyChannelData.tokenConstructor({...replyTokenData, [key]: value})

        onChange(token)
    }

    return (
        <Flex row>       
            {!!replyChannelData && replyChannelData.tokenParts.map((t, i) => (
                <Field key={i} label={`${t.label} (Liquid)`} flex={1} ml={(i % 2 == 1 ? 8 : 0)}>
                    <Input value={replyTokenData[t.key]} onChange={e => onPartChanged(t.key, e.target.value)} />
                </Field>
            ))}
        </Flex>
    )
}

export default EmailSequenceEditView