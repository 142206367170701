import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    A,
    Flex,
    PseudoElement,
    PageContent
} from 'paid-ui-lib';
import logo from '../../images/paid-logo.png';

const UnuthorisedLayoutPartial = ({children, ...rest}) => {
    const history = useHistory();
    
    const goTo = (action) => (e) => { e.preventDefault(); history.push(action) }

    return (
        <Flex
        display="flex"
        flexDirection="column"
        height="100%"
        {...rest}
        >
            <A 
                px={4}
                mt="1rem"
                mb={["2rem", "0", "0"]}
                href="/"
                onClick={goTo("/")}>
                     <PseudoElement
                        
                        as="img"
                        width="4.5rem"
                        src={logo} />
            </A>
            <PageContent>
                {children}
            </PageContent>
        </Flex>
    )
}
    
export default UnuthorisedLayoutPartial