import React, { useEffect, useState } from 'react'
import {
    PseudoElement,
    LayoutStack,
    Stack,
    Field,
    Badge,
    Span,
    formatDateTime,
    formatDate,
    HeaderSection,
    Box,
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import DateTimeEditor from './components/DateTimeEditor'

const DetailsScene = ({ work, ...rest }) => {
    const padTime = (num) => (num < 10) ? `0${num}` : num
    const [workDetails, setWorkDetails] = useState("")
    const [openCalendar, setOpenCalendar] = useState(false)
    const [startedDate, setStartedDate] = useState(new Date())
    const [formattedStartDate, setFormattedStartDate] = useState(new Date(startedDate));
    const [startedTime, setStartedTime] = useState(`${padTime(formattedStartDate.getHours())}:${padTime(formattedStartDate.getMinutes())}`)

    useEffect(() => {
        setFormattedStartDate(new Date(startedDate))
    }, [startedDate])

    useEffect(() => {
        if (!!!workDetails) {
            setWorkDetails(work)
        }
    }, [workDetails])

    return (
        <LoadingScreen
            hasData={workDetails}
            hasError={false}
            render={() => (
                <HeaderSection title="Overview" {...rest}>
                    <LayoutStack>
                        <Box>
                            <Stack>
                                <Stack row responsive>
                                    <Field flex="1" label={ "Title"} value={workDetails.title} />
                                    <Field flex="1" label={"Custom Reference"} value={workDetails.customReference} />
                                </Stack>
                                <Stack row responsive>
                                    <Field flex="1" label="Projected Start Date" value={formatDate(workDetails.projectedStartDate)} />
                                    <Field flex="1" label="Projected End Date" value={formatDate(workDetails.projectedEndDate)} />
                                </Stack>
                                {
                                    workDetails.requireStartConfirmation &&
                                    <Stack row  >
                                        <Stack row flex="1">
                                            <Field
                                                fieldFor="startDateTimePicker"
                                                label="Confirmed Work Start Date and Time "
                                                value={
                                                    workDetails.startedOn
                                                        ? <Span>{formatDateTime(workDetails.startedOn)}<Badge status="success" ml={8}>Confirmed</Badge></Span>
                                                        : !openCalendar
                                                            ? <Badge status="warning">Unconfirmed</Badge>
                                                            : <PseudoElement>
                                                                <DateTimeEditor
                                                                    id="startDateTimePicker"
                                                                    onClose={() => setOpenCalendar(false)}
                                                                    onSave={saveWorkStarted}
                                                                    date={formattedStartDate}
                                                                    setDate={setStartedDate}
                                                                    time={startedTime}
                                                                    setTime={setStartedTime}
                                                                    loading={false}
                                                                />
                                                            </PseudoElement>
                                                }
                                                overwriteAccessibility
                                            />
                                        </Stack>
                                    </Stack>
                                }
                                <Field label="Description" value={workDetails.description || "-"} />
                            </Stack>
                        </Box>
                        <Box>
                            <Stack row responsive>
                                <Stack flex="1">
                                    <Field label="Procurement Type" value={workDetails.procurementType.name} />
                                    <Field label="Procurement Department" value={workDetails.procurementDepartment.name} />
                                </Stack>
                                <Stack flex="1">
                                    <Field label="Legal Entity" value={workDetails.legalEntity.name} />
                                </Stack>
                            </Stack>
                        </Box>
                        <Box>
                            <Stack row responsive>
                                <Field flex="1" label="Work Created" value={formatDateTime(workDetails.createdOn)} />
                                <Field flex="1" label="Work Updated" value={formatDateTime(workDetails.updatedOn)} />
                            </Stack>
                        </Box>
                    </LayoutStack>
                </HeaderSection>
            )} />
    )
}

export default DetailsScene