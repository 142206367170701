import React, { useState, useEffect } from 'react'
import {
    PseudoElement,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Stack,
    Field,
    Span
} from 'paid-ui-lib'
import { formatDateTime } from 'helpers/date-helper'
import { useRequest } from 'components/use-request'
import SurveyInfoModalAnswer from './components/SurveyInfoModalAnswer'

const SurveyInfoModal = ({ selectedSurvey, organisationId, onClose }) => {
    const { getRequest } = useRequest()
    const [survey, setSurvey] = useState(null)
    const [surveyResponse, setSurveyResponse] = useState(null)
    //const [dataError, setDataError] = useState(null)

    useEffect(() => {
        if (!!selectedSurvey) {
            getRequest(`organisations/surveys/${organisationId}/${selectedSurvey.id}`)
                .then(res => {
                    setSurvey(res)
                })
                .catch(() => {
                    //setDataError(true)
                })
            if (selectedSurvey.responseId) {
                getRequest(`organisations/surveys/${organisationId}/${selectedSurvey.id}/responses/${selectedSurvey.responseId}`)
                    .then(res => {
                        setSurveyResponse(res)
                    })
                    .catch(() => {
                        //setDataError(true)
                    })
            }
        } else {
            setSurvey(null)
            setSurveyResponse(null)
        }
    }, [selectedSurvey])

    const getResponseInputValue = (questionResponse, questionInputId) => {
        if (!questionResponse) return null
        const inputAnswer = questionResponse.inputs.find(i => i.inputId === questionInputId)
        return !!inputAnswer ? { value: inputAnswer.value, data: inputAnswer.data } : { value: null, data: null }
    }

    const constructAnswerVM = (answer, constraints) => {
        if (!!constraints && !!answer && !!answer.data && !!answer.value) {
            if (constraints.type === "File") {
                if (Array.isArray(answer.value)) {
                    return Object.entries(answer.data).map(([k, v], i) => {
                        let id = k.split('filename-')[1]
                        let filename = v || "Open Link"
                        let url = answer.value[i]
                        return { id, filename, url };
                    })
                } else {
                    // Support for legacy single file response
                    let filename = answer.data["filename"] || "Open Link"
                    return [{ id: null, filename, url: answer.value }]
                }
            } else if (constraints.type === "Date") {
                answer.value = formatDateTime(answer.value)
            }
        }
            
        return answer
    }

    const getQuestionAnswers = (question) => {
        const questionResponse = surveyResponse && surveyResponse.responses.find(r => r.questionId === question.id)

        const answerParts = question.inputs.reduce((state, current) => {
            return [...state, {
                id: current.id,
                description: current.description,
                constraints: current.constraints || {},
                answer: constructAnswerVM(getResponseInputValue(questionResponse, current.id), current.constraints)
            }]
        }, [])

        return (
            <Stack spacing={8}>
                {
                    answerParts.map(ap => <SurveyInfoModalAnswer key={`${question.id}:${ap.id}`} answerPart={ap} />)
                }
            </Stack>
        )
    }

    return (
        <Modal isOpen={!!selectedSurvey} onClose={onClose} >
            <ModalOverlay />
            <ModalContent maxWidth={1000}>
                <ModalHeader>Survey Information</ModalHeader>
                {
                    selectedSurvey && (
                        <ModalBody>
                            <Stack row>
                                <Stack flex="1">
                                    <Field label="Name" value={!!survey && (survey.name || survey.title)}></Field>
                                    <Field label="Description" value={!!survey && survey.description}></Field>
                                    <Field label="Issued On" value={formatDateTime(selectedSurvey.issued)}></Field>
                                    <Field label="Completed On" value={formatDateTime(selectedSurvey.responded)}></Field>
                                    <Field label="Document Link" overwriteAccessibility>
                                        <PseudoElement>
                                            {
                                                (selectedSurvey && selectedSurvey.documentLink)
                                                    ? <Button title="Open Survey Document" mt={1} variantColor="white" size="sm" target="_blank" href={`#`}>View Document</Button>
                                                    : <Span>-</Span>
                                            }
                                        </PseudoElement>
                                    </Field>
                                </Stack>
                                <Stack
                                    py={16}
                                    px={20}
                                    border="1"
                                    borderRadius="default"
                                    backgroundColor="white.100"
                                    spacing={3}
                                    overflow="auto"
                                    maxHeight="500px"
                                    flex="2">
                                    {
                                        !!survey && survey.questions.map(q => <Field key={q.id} label={q.title} overwriteAccessibility>{getQuestionAnswers(q)}</Field>)
                                    }
                                </Stack>
                            </Stack>
                        </ModalBody>
                    )
                }
                <ModalFooter>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    {(selectedSurvey && selectedSurvey.surveyLink) && <Button title="Open Survey" ml={8} as="a" variant="secondary" target="_blank" href={`${selectedSurvey.surveyLink}`}>View Survey</Button>}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SurveyInfoModal