import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    Badge,
    Auth,
    Box,
    Button,
    ButtonStack,
    Checkbox,
    FilterButton,
    Flex,
    formatDateTime,
    getQueryVariable,
    IconSquare,
    Link,
    PageContent,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    PageTitle,
    PseudoElement,
    useToast,
} from 'paid-ui-lib'
import {
    initFilteredDataTable,
    FilteredDataTable,
} from 'helpers/filter-helper'
import AuthenticatorTfa from 'components/AuthenticatorTfa'
import PaymentDetailsDrawer from '../PaymentDetailsDrawer'

const PaymentsList = ({ held = false, showBatchActions = true, location }) => {
    const [loading, setLoading] = useState(false)
    const [buttonPressed, setButtonPressed] = useState("")
    const [selectedRows, setSelectedRows] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [showTfaBox, setShowTfaBox] = useState(false)
    const [dataError, setDataError] = useState(false)
    const [activeFilters, setActiveFilters] = useState()
    const [filterData, setFilterData] = useState({})
    const { postRequest } = useRequest()
    const [refresh, setRefresh] = useState(false)
    const [paymentId, setPaymentId] = useState(null)
    const { search } = useLocation()
    const toast = useToast()

    useEffect(() => {
        initFilteredDataTable({
            filterPath: "payments/filters",
            dataPath: !!held ? `payments/outgoing/search?held=true` : `payments/outgoing/search`,
            location: location,
            setFilterData,
            setDataError
        }).then(showFilter => {
            setShowFilter(showFilter)
        })
    }, [])

    useEffect(() => {
        if (!!search) {
            const pathParam = getQueryVariable(search, "id");
            !!pathParam && setPaymentId(pathParam);
        }
    }, [search])

    useEffect(() => {
        if (selectedRows.length === 0) {
            setShowTfaBox(false)
        }
    }, [selectedRows])

    const filterOnAwaitingAuthorisation = (values) => {
        return (values || []).filter(x => x.status === 1);
    }

    const filterOnAwaitingPayment = (values) => {
        return (values || []).filter(x => x.status === 2);
    }

    const distictBy = (array, key) => {
        return [...new Map(array.map(item => [item[key], item])).values()];
    }

    const selectAwaitingAuthorisation = () => {
        setSelectedRows(s => distictBy([...exceptBy(s, filterData.data || [], "id"), ...filterOnAwaitingAuthorisation(filterData.data)], "id"))
    }

    const selectAwaitingPayment = () => {
        setSelectedRows(s => distictBy([...exceptBy(s, filterData.data || [], "id"), ...filterOnAwaitingPayment(filterData.data)], "id"))
    }

    const hasSelectedItemsOnCurrentPage = () => {
        const selections = selectedRows || [];
        const pageData = filterData.data || [];
        return selections.filter(s => pageData.findIndex(p => p.id === s.id) > -1).length > 0;
    }

    const exceptBy = (allRows, pageData, prop) => {
        let allPagesData = [...(allRows || [])];
        return allPagesData.filter(r => pageData.findIndex(p => p[prop] === r[prop]) === -1);
    }

    const addOrRemove = (values, value) => {
        let newValues = [...(values || [])]
        const index = newValues.findIndex(v => v.id === value.id);
        if (index !== -1) {
            newValues.splice(index, 1);
        }
        else {
            newValues.push(value);
        }
        return newValues;
    }

    const actionButton =
        <Menu isSmall>
            <MenuButton mt="25%" isSmall variant="secondary" showChevron altToggle>
                <Checkbox checked={hasSelectedItemsOnCurrentPage()} onClick={() => setSelectedRows(s => distictBy([...s, ...filterData.data], "id"))} />
            </MenuButton>
            <MenuList isSmall width="250px">
                <MenuItem key="1" onClick={() => setSelectedRows(s => exceptBy(s, filterData.data || [], "id"))}>None</MenuItem>
                <MenuItem key="2" onClick={() => selectAwaitingAuthorisation()}>Awaiting Authorisation</MenuItem>
                <MenuItem key="3" onClick={() => selectAwaitingPayment()}>Awaiting Payment</MenuItem>
            </MenuList>
        </Menu>

    const columns = [
        { "header": "Reference", "accessor": "reference", "sortId": "1", maxWidth: "12rem" },
        { "header": "Supplier", "accessor": "supplier", "sortId": "2", maxWidth: "12rem" },
        { "header": "Enterprise", "accessor": "enterprise", "sortId": "3" },
        { "header": "Created On", "accessor": "createdOn", "sortId": "4" },
        { "header": "Due On", "accessor": "dueOn", "sortId": "9" },
        { "header": "Amount", "accessor": "amount", "sortId": "5" },
        { "header": "Status", "accessor": "status", "sortId": "6" },
        { "header": "Processed", "accessor": "processedOn", "sortId": "7" },
        { "header": "Terms", "accessor": "expressPayment", "sortId": "8" },
        ...(!!showBatchActions ? [{ "header": actionButton, "accessor": "action" }] : []),
    ].filter(c => !!c)

    const transform = (data) => {
        let badge = {
            status: "success",
            content: "Paid"
        }

        switch (data.status) {
            case 1:
                badge.status = "error"
                badge.content = "Awaiting Auth"
                break;
            case 2:
                badge.status = "warning"
                badge.content = "Awaiting Payment"
                break;
            case 4:
                badge.status = "error"
                badge.content = "Rejected"
                break;
            case 5:
                badge.status = "warning"
                badge.content = "Processing"
                break;
            default:
                break;
        }

        if ((data.status === 1 || data.status === 2) && !!data.holdOn) {
            badge.status = "error"
            badge.content = "Hold"
        }
        else if (!!data.expressPayment && data.status === 2 && data.buyerOrganisationId !== "049fd28f-940d-41bd-8806-7cb87f353345") { // Don't show for RN payments
            badge.status = "info"
            badge.content = "Sent to Provider"
        }

        const isChecked = (selectedRows || []).findIndex(v => v.id === data.id) !== -1;

        return {
            key: data.id,
            id: data.id,
            reference: <Link action={`/work/${data.buyerOrganisationId}/${data.productId}`}>{data.reference}</Link>,
            supplier: <Link action={`/organisations/${data.supplierOrganisationId}`}>{data.supplierOrganisationName}</Link>,
            enterprise: <Link action={`/enterprise/${data.buyerOrganisationId}`}>{data.buyerOrganisationName}</Link>,
            createdOn: formatDateTime(data.createdOn, true),
            dueOn: formatDateTime(data.dueOn, true),
            amount: `${data.currencySymbol}${data.amount.toFixed(2)}`,
            status: <Flex alignItems="center">
                <Badge status={badge.status}>
                    {badge.content}
                </Badge>
            </Flex>,
            processedOn: formatDateTime(data.processedOn),
            expressPayment: <PseudoElement cursor="pointer">
                <IconSquare
                    size={32}
                    onClick={() => setPaymentId(data.id)}
                    title={!!data.virtualCardPayment ? "Virtual Card Payment" : (!!data.expressPayment ? "Express Payment" : "Standard Terms")}
                    name={!!data.virtualCardPayment ? "credit-card" : (!!data.expressPayment ? "express-payment" : "credit-card")}
                    {...(!!data.virtualCardPayment ? { "color": "blue.500" } : (!!data.expressPayment ? { "color": "green.300" } : {}))}
                />
            </PseudoElement>,
            action: <Flex alignItems="center"><Checkbox pl={16} checked={isChecked} onClick={e => setSelectedRows(s => addOrRemove(s, data))}></Checkbox></Flex>
        }
    }

    const requestSuccess = (message) => {
        toast({
            ...({
                slim: true,
                position: "top-right",
                title: "Success",
                description: "",
                status: "success",
            }),
            ...(message || {})
        });
        setSelectedRows([]);
        setPaymentId(null);
        setRefresh(true);
    }

    const onGenerateCard = (c, p) => {
        setLoading(true);
        return postRequest(`payments/${p.id}/virtual-cards/${p.bankAccountId}/generate`, { AuthenticatorCode: c })
            .then(() => requestSuccess({ title: `Successfully generated virtual card payment` }))
            .finally(() => setLoading(false))
    }

    const onCloseCard = (c, p) => {
        setLoading(true);
        return postRequest(`payments/${p.id}/virtual-cards/${p.bankAccountId}/close`, { AuthenticatorCode: c })
            .then(() => requestSuccess({ title: `Successfully closed virtual card payment` }))
            .finally(() => setLoading(false))
    }

    const onAuthorise = (c, p) => {
        setLoading(true);
        return postRequest(`payments/authorise`, { AuthenticatorCode: c, PaymentIds: [p.id] })
            .then(() => requestSuccess({ title: `Successfully authorised payments` }))
            .finally(() => setLoading(false))
    }

    const onProcess = (c, p) => {
        setLoading(true);
        return postRequest(`payments/process`, { AuthenticatorCode: c, PaymentIds: [p.id] })
            .then(() => requestSuccess({ title: `Processing payments`, description: "You will receive an email when the payments have been processed." }))
            .finally(() => setLoading(false))
    }

    const onReleaseHold = (c, p) => {
        setLoading(true);
        return postRequest(`payments/hold`, { AuthenticatorCode: c, PaymentIds: [p.id] })
            .then(() => requestSuccess({ title: `Successfully updated payment hold` }))
            .finally(() => setLoading(false))
    }

    const onMultiAction = (code) => {
        let payload = { AuthenticatorCode: code, PaymentIds: [] }
        switch (buttonPressed) {
            case "authorise":
                payload.PaymentIds = filterOnAwaitingAuthorisation(selectedRows).map(x => x.id);
                break;
            case "process":
                payload.PaymentIds = filterOnAwaitingPayment(selectedRows).map(x => x.id);
                break;
            default:
                break;
        }

        setLoading(true);
        return postRequest(`payments/${buttonPressed}`, payload)
            .then(() => {
                if (buttonPressed == "authorise") {
                    toast({
                        slim: true,
                        position: "top-right",
                        title: `Successfully authorised the payments`,
                        description: "",
                        status: "success",
                    })
                }
                else {
                    toast({
                        slim: true,
                        position: "top-right",
                        title: `Processing payments`,
                        description: "You will receive an email when the payments have been processed.",
                        status: "success",
                    })
                }
                setSelectedRows([])
                setRefresh(true)
            })
            .finally(() => setLoading(false))
    }

    const exportCSV = (route, fileName) => {
        setLoading(true);
        postRequest(`payments/${route}`, (selectedRows || []).map(r => r.id))
            .then(res => {
                res.blob().then(blob => {
                    (res)
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', `${fileName}-${new Date().toLocaleString('en-GB').replaceAll(',', "")}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
            })
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <PageTitle title={!!held ? "Held Payments" : "Payments"} description="The payments awaiting authorisation or action" slim>
                {
                    //!!showBatchActions &&
                    <ButtonStack row flex="1">
                        <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                        <Menu>
                            <MenuButton variant="success" showChevron>Export</MenuButton>
                            <MenuList width="200px">
                                <MenuItem onClick={() => exportCSV("csv", "Payments")}>Export CSV</MenuItem>
                                <MenuItem onClick={() => exportCSV("sage", "Sage")}>Export Sage CSV</MenuItem>
                                <MenuItem onClick={() => exportCSV("xero", "Xero")}>Export Xero CSV</MenuItem>
                            </MenuList>
                        </Menu>
                        <Auth permission="301">
                            {
                                filterOnAwaitingAuthorisation(selectedRows).length > 0 &&
                                <Button disabled={loading} isLoading={loading} variant="destructive" isSmall onClick={() => { setShowTfaBox(true); setButtonPressed("authorise") }}>Authorise {filterOnAwaitingAuthorisation(selectedRows).length} Payments</Button>
                            }
                        </Auth>
                        <Auth permission="302">
                            {
                                filterOnAwaitingPayment(selectedRows).length > 0 &&
                                <Button disabled={loading} isLoading={loading} variant="destructive" isSmall onClick={() => { setShowTfaBox(true, "process"); setButtonPressed("process") }}>Process {filterOnAwaitingPayment(selectedRows).length} Payments</Button>
                            }
                        </Auth>
                    </ButtonStack>
                }
            </PageTitle>
            <PageContent slim>
                <LoadingScreen
                    hasData={filterData.data}
                    hasError={dataError}
                    render={() => (
                        <PseudoElement>
                            {
                                !!showTfaBox &&
                                <Box alignItems="center">
                                    <AuthenticatorTfa onTfaSubmit={onMultiAction} />
                                </Box>
                            }
                            <FilteredDataTable
                                initData={filterData}
                                showFilter={showFilter}
                                setDataError={setDataError}
                                transform={transform}
                                setInitData={setFilterData}
                                columns={columns}
                                sortable={true}
                                refresh={refresh}
                                setRefresh={setRefresh}
                                setParentActiveFilters={setActiveFilters} />
                        </PseudoElement>
                    )}
                />
            </PageContent>
            {!!paymentId && <PaymentDetailsDrawer
                paymentId={paymentId}
                loading={loading}
                onClose={() => setPaymentId(null)}
                onGenerateCard={onGenerateCard}
                onCloseCard={onCloseCard}
                onAuthorise={onAuthorise}
                onProcess={onProcess}
                onReleaseHold={onReleaseHold}
            />}

        </Fragment>
    )
}

export default PaymentsList