import React, { useEffect } from "react"
import DeliverableEdit from './components/DeliverableEdit'
import DeliverableView from './components/DeliverableView'

const Deliverable = ({
    value,
    allowFreeText,
    enableQuickAdd,
    getDeliverableTemplates,
    selectedDeliverable,
    appendOnly,
    onEdit,
    onCancel,
    ...rest
}) => {

    useEffect(() => {
        if(value.id === selectedDeliverable){
            getDeliverableTemplates()
        }
    }, [selectedDeliverable, value])

    return value.id === selectedDeliverable
        ? <DeliverableEdit allowFreeText={allowFreeText} value={value} onCancel={onCancel} enableQuickAdd={enableQuickAdd} {...rest} />
        : <DeliverableView value={value} onEdit={onEdit} appendOnly={appendOnly} {...rest} />
}

export default Deliverable