import React, { useState } from 'react'
import { useRequest } from 'components/use-request'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Button,
    ButtonStack,
    Span,
    Stack,
    useToast
} from 'paid-ui-lib'
import { toTitleCase } from 'helpers/string-helper'

const SuspendUserDrawer = ({ isOpen, userId, onClose, suspended }) => {
    const { putRequest } = useRequest()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const action = suspended ? "suspend" : "unsuspend"

    const sendRequest = () => {
        setLoading(true)

        return putRequest(`users/${userId}/${action}`, {})
            .then(() => {
                toast({
                    slim: true,
                    position: "top-right",
                    title: `User ${action}ed`,
                    description: `User has been successfully ${action}ed`,
                    status: "success",
                    isClosable: true
                })

                onClose(true)
            })
            .catch(() =>
                toast({
                    slim: true,
                    position: "top-right",
                    title: `User ${action}ed`,
                    description: `Failed to ${action} user. Please contact support`,
                    status: "error",
                    isClosable: true
                }))
            .finally(() => setLoading(false))
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={() => onClose(false)}
            size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{toTitleCase(action)} User</DrawerHeader>
                <DrawerBody>
                    <Stack row alignItems="center" justifyContent="flex-end" mt={16}>
                        <Span>Are you sure?</Span>
                        <ButtonStack row>
                            <Button variant="secondary" onClick={() => onClose(false)}>{`No, don't ${action} user`}</Button>
                            <Button variant="destructive" onClick={sendRequest} isLoading={loading}>{`Yes, ${action} user`}</Button>
                        </ButtonStack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default SuspendUserDrawer