import React, { Fragment, useState, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import {
    PageTitle,
    formatDateTime,
    Badge,
    ButtonStack,
    Flex,
    PageContent,
    FilterButton,
    Link
} from 'paid-ui-lib'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'

const ListAdministratorsView = (props) => {
    const [ showFilter, setShowFilter ] = useState(false) 
    const [ filterData, setFilterData ] = useState({})
    const [ dataError, setDataError ] = useState(false)

    useEffect(() => {
        initFilteredDataTable({
            filterPath: "administrators/filters", 
            dataPath: "administrators/search", 
            location: props.location, 
            setFilterData, 
            setDataError
        }).then(showFilter => setShowFilter(showFilter))
    }, [])

    const columns = [
        { "header": "Name", "accessor": "name", "sortId": "1", maxWidth: "16rem" },
        { "header": "Email", "accessor": "email", "sortId": "2", maxWidth: "16rem" },
        { "header": "Role", "accessor": "role", "sortId": "3" },
        { "header": "Status", "accessor": "status", "sortId": "4" }
    ].filter(c => !!c)

    const transform = (data) => {
        return {
            id: data.id,
            email: data.email,
            role: data.role,
            name: <Link action={`/administrators/${data.id}`}>{data.name}</Link>,
            status: <Flex alignItems="center">
                            <Badge status={data.active ? "success" : "error"}>
                                {data.active ? "Active" : "Suspended"}
                            </Badge>
                        </Flex>
        }
    }
    
    return (
        <LoadingScreen
            hasData={filterData.data}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Administrators" description="The admin base" slim>
                        <ButtonStack row>
                            <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)}/>
                        </ButtonStack>
                    </PageTitle>
                    <PageContent slim>
                        <FilteredDataTable 
                            initData={filterData}
                            showFilter={showFilter}
                            setDataError={setDataError} 
                            transform={transform} 
                            columns={columns}
                            sortable={true} />
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ListAdministratorsView