import React from "react"
import {
    Box,
    Span,
    P,
    Stack,
    IconButton,
    ButtonStack,
    Flex,
    Badge
} from 'paid-ui-lib'

const DeliverableView = ({
    value,
    currencySymbol,
    getDeliverableTemplates,
    deliverableTemplates,
    appendOnly,
    readonly,
    onEdit,
    onChange,
    onRemove,
    ...rest
}) => {
    const getBorderColor = () => {
        if(value.added) return "green.300"
        if(value.edited) return "orange.300"
        if(value.removed) return "red.300"
        return "grey.300"
    }

    const getBackgroundColor = () => {
        // if(value.added) return "green.100"
        // if(value.edited) return "orange.100"
        // if(value.removed) return "red.100"
        return "white.100"
    }

    const getBadge = () => {
        if(value.added) return <Flex mb={12} alignItems="center"><Badge status="success" minWidth="70px" mr={4}>Added</Badge></Flex>
        if(value.edited) return <Flex mb={12} alignItems="center"><Badge status="warning" minWidth="70px" mr={4}>Edited</Badge></Flex>
        if(value.removed) return <Flex mb={12} alignItems="center"><Badge status="error" minWidth="70px" mr={4}>Removed</Badge></Flex>
        return null
    }

    const getAdditionalFieldDescription = () => {
        return value.additionalFields.map(af => `${af.name}: ${af.value || "N/A"}`).join(", ")
    }

    return (
        <Box 
        row
        py={12}
        px={16}
        borderColor={getBorderColor()}
        backgroundColor={getBackgroundColor()}
        {...rest}>
            { getBadge() }
            <Flex alignItems="center" flex="1">
                <Stack spacing={4} mr={4}>
                    <Span>{value.description}</Span>
                    {(value.additionalFields && !!value.additionalFields.length) && <P>{getAdditionalFieldDescription()}</P>}
                    {value.additionalDescription && <P>{value.additionalDescription}</P>}
                </Stack>
                <Span ml="auto">{currencySymbol}{value.amount.toFixed(2)}</Span>
                {
                    (!readonly && (!appendOnly || value.isNew)) &&
                    <ButtonStack row compact ml={20}>
                        <IconButton onClick={() => { onEdit && onEdit(value.id) }} isSmall icon="edit" />
                        {/* <Button as="a" onClick={() => { onRemove && onRemove(value.id) }} size="md" variantColor="white"><Icon name="trash" /></Button> */}
                    </ButtonStack>
                }
            </Flex>
        </Box>
    )
}

export default DeliverableView