import React, { useState } from "react";
import LoginScene from "./components/login-scene";
import AuthenticatorTfa from "components/AuthenticatorTfa";
import { useRequest } from "../../components/use-request";
import { 
  Box, 
  useAuthContext,
  Flex,
  Spinner
} from "paid-ui-lib";

const LoginView = () => {
  const authContext = useAuthContext();
  const { postRequest } = useRequest();
  const [scene, setScene] = useState(0);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [ remember, setRemember] = useState(false)

  const onLogin = (credential) => {
    setScene(2);

    postRequest('authentication/login', {
      "token": credential
    })
      .then(({ login, authenticator }) => {
        if (!!login) {
          authContext.updateAccessToken(login);
        }

        if (!!authenticator) {
          setToken(authenticator.token);
          setScene(1);
        }
      }).catch((err) => {
        setError(err.message);
      });
    };

    const onError = ({error, details}) => {
      setError(details);
    }

  const authenticatorLogin = (code) => {
    return postRequest("authentication/authenticator", { token: token, code: code, remember: remember })
      .then(({ login }) => {
        authContext.updateAccessToken(login);
      })
      .catch(() => {
        setToken(null);
        setScene(0);
      });
  };

  return scene === 2 ? (
    <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
      <Spinner m="auto" size={32} />
    </Flex>
  ) : (
    <Box flex="0 1 auto" m="auto" width={"22rem"}>
      {scene === 0 && (<LoginScene onLogin={onLogin} onError={onError} error={error} />)}
      {scene === 1 && (<AuthenticatorTfa allowRemember onTfaSubmit={authenticatorLogin} Remember={remember} SetRemember={setRemember} />)}
    </Box>
  );
};

export default LoginView;
